import {
  DeleteOutline,
  EditOutlined,
  OpenInNew,
  Tune,
} from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import ReportDialog from "../../../Components/Organisms/ReportDialog";
import { addSearchValue } from "../../../features/search/searchSlice";
import {
  useDeleteEbooksReportMutation,
  useGetEbooksReportsQuery,
  useLazyGetEbooksReportsQuery,
} from "../../../services/ebooksAndRecipes/ebooksAndRecipes";

import AddEbook from "./AddEbook";
import ViewEbook from "./ViewEbook";
import { handleSort } from "../../../Utils/Utils";
import EbookSearch, { ERSearchSchema } from "../EbookSearch";

const Ebooks = () => {
  const dispatch = useDispatch();
  const [addReportPopup, setAddReportPopup] = useState({
    mount: false,
    data: null,
    type: "new",
  });

  // Reports list with filters
  const [getEbooks, { data: list, isError, isLoading, isFetching }] =
    useLazyGetEbooksReportsQuery();
  const [filterModal, setFilterModal] = useState(false);

  const { ebooks_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
        category: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
    },
    validationSchema: ERSearchSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "ebooks_search", values }));

      const notify = toast.loading("Getting ebooks..");

      try {
        const query = await getEbooks({ ...values })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getEbooks(previousFilter);
      } else {
        await getEbooks({ ...searchFormik.values });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1 && count++;
    searchFormik.values.filter.date2 && count++;
    searchFormik.values.filter.category && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const [deleteEbook, { isLoading: deleting }] =
    useDeleteEbooksReportMutation();

  const handleDeleteReport = (data) => {
    if (deleting) return;
    return confirmDialog(
      `Are you sure delete ${data.ebook_name} e-book permanently?`,
      async () => {
        const notify = toast.loading("Deleting e-book");
        try {
          return await deleteEbook(data.id)
            .unwrap()
            .then(() => {
              toast.success("Ebook deleted successfully", { id: notify });
            });
        } catch (error) {
          console.error(error);
          toast.error("Failed to delete Ebook!", { id: notify });
        }
      }
    );
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Name", key: "ebook_name", sortable: true },
    { name: "Date", key: "date", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div>
        <div className="tpHdrVw d-flex flex-wrap align-items-center justify-content-end mb-4 gap-2">
          <CustomSearch
            inputName="search"
            query={searchFormik.values.search}
            handleChange={searchFormik.handleChange}
            handleSubmit={(e) => {
              e.preventDefault();
              searchFormik.handleSubmit();
              searchFormik.setFieldValue("page", 1);
            }}
            containerClasses="ms-md-auto small"
            disabled={isFetching || isLoading}
          />

          <Badge badgeContent={handelBageContent} color="secondary">
            <CustomButton
              size="small"
              onClick={() => setFilterModal(true)}
              label={
                <>
                  <Tune className="me-2" />
                  Filter
                </>
              }
            />
          </Badge>
          <CustomButton
            size="small"
            onClick={() =>
              setAddReportPopup({
                mount: true,
                data: null,
                type: "new",
              })
            }
            label={
              <>
                <span className="material-symbols-rounded me-2">add</span>
                Add New
              </>
            }
          />
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {tableHeads.map(({ sortable, key, name }, idx) => (
                  <th key={idx}>
                    <div
                      className={`d-flex align-items-center ${
                        sortable ? "sortable" : ""
                      } ${
                        searchFormik.values.sort.includes(key) ? "active" : ""
                      }`}
                      onClick={() => {
                        if (sortable && !isLoading && !isFetching) {
                          return handleSort(searchFormik, key || "");
                        }
                      }}
                    >
                      {name}
                      {sortable && (
                        <span className="material-symbols-rounded">
                          {searchFormik.values.sort.includes(key)
                            ? searchFormik.values.sort.includes(`-${key}`)
                              ? "expand_more"
                              : "expand_less"
                            : "unfold_more"}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!!list?.report?.length &&
                list.report.map((data, index) => (
                  <tr key={data.id} style={{ verticalAlign: "middle" }}>
                    <td>{index + 1}</td>
                    <td>{data.ebook_name}</td>
                    <td>{data.date}</td>
                    <td>
                      <CustomDropdown
                        menuItems={[
                          {
                            label: (
                              <>
                                <OpenInNew />
                                View
                              </>
                            ),
                            handleMenuClick: () => {
                              return setAddReportPopup({
                                mount: true,
                                data: data,
                                type: "view",
                              });
                            },
                          },
                          {
                            label: (
                              <>
                                <EditOutlined />
                                Edit
                              </>
                            ),

                            handleMenuClick: () => {
                              return setAddReportPopup({
                                mount: true,
                                data: data,
                                type: "edit",
                              });
                            },
                          },
                          {
                            label: (
                              <>
                                <DeleteOutline />
                                Delete
                              </>
                            ),
                            handleMenuClick: () => {
                              return handleDeleteReport(data);
                            },
                            disabled: deleting,
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="tblFtrActs">
          <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
            {isLoading
              ? "Fetching records.."
              : isError && !list?.report?.length
              ? "Failed to load records!"
              : !list?.report?.length && "No Records Found!"}
          </p>
          <div>
            <TablePagination
              component="div"
              count={list?.count || 0}
              page={searchFormik.values.page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={searchFormik.values.list_per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

        <ReportDialog
          title={
            addReportPopup.type !== "new"
              ? addReportPopup.data?.ebook_name
              : "Add New E-Book"
          }
          open={addReportPopup.mount}
          handleClose={() =>
            setAddReportPopup({
              mount: false,
              data: null,
              type: null,
            })
          }
        >
          {addReportPopup.type === "new" || addReportPopup.type === "edit" ? (
            <AddEbook
              {...addReportPopup}
              handleClose={() =>
                setAddReportPopup({
                  mount: false,
                  data: null,
                  type: null,
                })
              }
            />
          ) : (
            <ViewEbook {...addReportPopup} />
          )}
        </ReportDialog>
        {filterModal && (
          <EbookSearch
            open={filterModal}
            formik={searchFormik}
            handleClose={() => setFilterModal(false)}
          />
        )}
        <ConfirmDialogPopper />
      </div>
    </>
  );
};

export default Ebooks;
