import React, { useState } from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import { baseUrl } from "../../services/api/api";
import {
  useGetDeleteLogsQuery,
  useGetRejectRequestMutation,
  useGetReportDeleteRequestQuery,
  useGetApproveRequestMutation,
} from "../../services/reports/reportsApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../../Components/Atoms/CustomInput";
import { DatasetLinked } from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DeleteReports = () => {
  const [approveDialog, setApproveDialog] = useState({
    id: null,
    show: false,
  });
  const [rejectPopup, setRejectPopup] = useState({
    id: null,
    show: false,
  });
  const { data, isLoading } = useGetReportDeleteRequestQuery(undefined, {
    refetchOnFocus: true,
  });

  const { data: deleteLogs, isLoading: deleteLoading } = useGetDeleteLogsQuery(
    undefined,
    {
      refetchOnFocus: true,
    }
  );

  const [getReportRequest, { isloading: reportLoading }] =
    useGetApproveRequestMutation();

  const [getRejectRequest, { isloading: rejectLoading }] =
    useGetRejectRequestMutation();

  const [value, setValue] = React.useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const validationArray = Yup.object().shape({
    reject_reason: Yup.string()
      .min(5, "Minimum 5 characters required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .required("Required"),
  });

  const rejectFormik = useFormik({
    initialValues: {
      reject_reason: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        await getRejectRequest({ id: rejectPopup.id, values })
          .unwrap()
          .then((res) => {
            if (res.success == true) {
              toast.success("Request rejected successfully");
              handleRejectPopupClose();
            } else if (res.success == false) {
              toast.error(res.message);
              handleRejectPopupClose();
            }
          });
      } catch (err) {
        toast.error(err);
      }
    },
  });

  const handleDeleteRequest = async (id) => {
    try {
      await getReportRequest(id)
        .unwrap()
        .then((res) => {
          if (res.success == true) {
            toast.success(res.message);
            setApproveDialog(false);
          } else {
            toast.error(
              "Report cannot be deleted at this time, please try after some time"
            );
            setApproveDialog(false);
          }
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleRejectPopupClose = () => {
    rejectFormik.resetForm();
    setRejectPopup({
      id: null,
      show: false,
    });
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                {deleteLoading || reportLoading ? (
                  <>
                    <LoaderIcon />
                  </>
                ) : (
                  ""
                )}
                <span className="material-symbols-rounded me-2">
                  unknown_document
                </span>
                Delete Report
              </h5>
            </div>
            <hr />
          </div>
          <div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                >
                  <Tab label="Delete Request" {...a11yProps(0)} />
                  <Tab label="Delete Logs" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table flwKitTbl">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Request Id</th>
                          <th>Requested By</th>
                          <th>Report Id</th>
                          <th>Reason</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.length > 0 &&
                          data.map((data, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{data.id}</td>
                              <td>
                                <Link
                                  // target={"_blank"}
                                  to={`${
                                    data.user.account_subtype === "patient"
                                      ? "/users/patients/view/" + data.user.id
                                      : "/users/sport-persons/view/" +
                                        data.user.id
                                  }`}
                                >
                                  {data.user.ref_no}
                                </Link>
                              </td>
                              <td>{data.userreport.id}</td>
                              <td>{data.reason}</td>
                              <td>
                                <Link
                                  target={"_blank"}
                                  to={`${baseUrl}${data.userreport.report}`}
                                >
                                  View Report
                                </Link>
                                <Link
                                  className="ms-3 text-success"
                                  to=""
                                  onClick={() =>
                                    setApproveDialog({
                                      id: data.id,
                                      show: true,
                                    })
                                  }
                                >
                                  Approve
                                </Link>
                                <Link
                                  className="ms-3 text-danger"
                                  to=""
                                  onClick={() =>
                                    setRejectPopup({
                                      id: data.id,
                                      show: true,
                                    })
                                  }
                                >
                                  Reject
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {console.log("deleteLogs", deleteLogs)}
                <div className="row">
                  <div className="table-responsive">
                    <table className="table flwKitTbl">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Report Id</th>
                          <th>Report Name</th>
                          <th>Requested User</th>
                          <th>Requested Reason</th>
                          <th>Status</th>
                          <th>Approved / Rejected By</th>
                          <th>Rejected Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deleteLogs &&
                          deleteLogs.length > 0 &&
                          deleteLogs.map((data, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{data.report_id}</td>
                              <td>{data.report_name?.split("/")[1]}</td>
                              <td>{data.report_user}</td>
                              <td>{data.requested_reason}</td>
                              <td className="text-capitalize">
                                {data.status === "approved" ? (
                                  <span className="text-success">
                                    {data.status}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {data.status}
                                  </span>
                                )}
                              </td>
                              <td className="text-capitalize">
                                {data.approved_by || data.rejected_by}
                              </td>
                              <td>{data.rejected_reason}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <CustomDialog
        open={approveDialog.show}
        handleClose={() =>
          setApproveDialog({
            id: null,
            show: false,
          })
        }
      >
        <div className="dltFlPoup">
          <h5 className="text-center mb-3">
            Are your sure about deleting this file?
          </h5>
          <p className="text-center">
            By clicking yes button, you are approving the user to delete the
            file from the system
          </p>
          <div className="d-flex align-items-center justify-content-center mt-4">
            <CustomButton
              label="No"
              btnType="secondary"
              onClick={() =>
                setApproveDialog({
                  id: null,
                  show: false,
                })
              }
            />
            <CustomButton
              label="Yes"
              btnClass="ms-2"
              onClick={() => handleDeleteRequest(approveDialog.id)}
            />
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        open={rejectPopup.show}
        handleClose={() => handleRejectPopupClose()}
      >
        <div className="vwDlgWrpr">
          <h4>Reject Delete Request</h4>
          <form className="mt-4" onSubmit={rejectFormik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <CustomInput
                  label="Reason to reject"
                  name="reject_reason"
                  onChange={rejectFormik.handleChange}
                  onBlur={rejectFormik.handleBlur}
                  formik={rejectFormik}
                  value={rejectFormik.values.reject_reason}
                  multiline
                />
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  btnClass="me-3"
                  onClick={() => handleRejectPopupClose()}
                />
                <CustomButton label="Reject" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </CustomDialog>
    </>
  );
};

export default DeleteReports;
