import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import {
  useCreateMotivesMutation,
  useGetMotiveByIdQuery,
  useUpdateMotivesMutation,
} from "../../../services/settings/motivesApi";
import CustomDialog from "../../../Components/Molecules/CustomDialog";

const validationSchema = yup.object().shape({
  motive_name: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Motive is required"),
});

const AddMotive = ({ type, data, mount, handleClose }) => {
  const [createMotive, { isLoading }] = useCreateMotivesMutation();
  const [updateMotive, { isLoading: isUpdate }] = useUpdateMotivesMutation();

  const { data: selectedMotive } = useGetMotiveByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      motive_name: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await createMotive(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Motive created successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create motive!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateMotive(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Motive updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update motive!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("motive_name", selectedMotive?.motive_name);
      formik.setFieldValue("id", selectedMotive?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedMotive]);

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <img src="/assets/svg/motive-dialog.svg" className="mb-3" />
        <h4 className="mb-2">
          {type !== "new" ? `Update: ${data?.motive_name}` : "Add New Motive"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Motive"
                name="motive_name"
                value={formik.values.motive_name}
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddMotive;
