import React, { useCallback, useMemo, useState } from "react";

import { FieldArray, FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import { toast } from "react-hot-toast";
import { currencyFomatter } from "../../Utils/Utils";

import { Autocomplete, IconButton, TextField } from "@mui/material";
import MultipleSelect from "../../Components/Atoms/MultipleSelect";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CustomButton from "../../Components/Atoms/CustomButton";
import { ChevronLeft } from "@mui/icons-material";

import {
  useBulkFollowKitRequestMutation,
  useGetFollowKitPricingsQuery,
} from "../../services/followkit/followkitApi";
import { useGetGroupListQuery } from "../../services/auth/authApi";

const BulkFollowkitForm = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [pricingDetails, setPRicingDetails] = useState({
    data: null,
    title: null,
    mount: false,
  });

  const [bulkFollowKitRequest, { isLoading: bulkLoading }] =
    useBulkFollowKitRequestMutation();

  const { data: groupData } = useGetGroupListQuery(undefined, {
    refetchOnFocus: true,
  });
  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
  });

  const bulkOrderFormik = useFormik({
    initialValues: {
      group: null,
      group_members: [],
    },
    validationSchema: Yup.object().shape({
      group_members: Yup.array()
        .of(
          Yup.object().shape({
            kits: Yup.array().min(1, "Please select kit").required("Required"),
            pricing: Yup.array()
              .min(1, "Please select reports")
              .required("Required"),
          })
        )
        .required("Required"),
    }),
    onSubmit: async (values, actions) => {
      const date = new Date().toISOString().substring(0, 10);

      const transformed_data = () => {
        const data = new Array();

        values.group_members?.map((client) => {
          !!client.kits?.length &&
            client.kits?.map((kit) => {
              return data.push({
                kit_name: kit,
                date: date,
                sample_type: kit,
                user: client.user.id,
                pricing: client.pricing,
                group: values.group.id,
              });
            });
        });

        return data;
      };

      try {
        const res = await bulkFollowKitRequest({
          id: user?.id,
          requested_bulk_Kit: transformed_data(),
        })
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Your order has been placed successfully.");
              navigate("/followKits/checkout");
            }
          });
        return res;
      } catch (error) {
        toast.error("Something went wrong.");
        console.log(error);
      }
    },
  });

  const findPriceHandler = (values, reports) => {
    return values?.reduce((acc, curr, index) => {
      acc =
        parseInt(acc) +
        parseInt(reports?.[reports.findIndex((ele) => ele.id == curr)]?.price);
      return acc;
    }, 0);
  };

  const bulkReportPrice = useCallback(
    (key) => findPriceHandler(key, reports),
    [bulkOrderFormik.values.pricing, reports]
  );

  const orderPriceInfo = useMemo(() => {
    return bulkOrderFormik.values.group_members?.reduce(
      (acc, crr, idx) => {
        crr.pricing?.map((el) => {
          acc.total =
            parseInt(acc.total) +
            parseInt(
              reports?.[reports.findIndex((ele) => ele.id == el)]?.price
            );
          el in acc.pricing ? acc.pricing[el]++ : (acc.pricing[el] = 1);
        });

        crr.kits?.map((el) =>
          el in acc.kits ? acc.kits[el]++ : (acc.kits[el] = 1)
        );

        return acc;
      },
      { pricing: {}, kits: {}, total: 0 }
    );
  }, [bulkOrderFormik.values, reports]);

  return (
    <div className="mnDashboardView">
      <div className="dshStCard prflPageWrpr">
        <div className="pt-2 ">
          <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
            <h5 className="d-flex align-items-center">
              <IconButton onClick={() => navigate("/followkits")}>
                <ChevronLeft />
              </IconButton>
              Bulk Follow kits Order
            </h5>
          </div>
          <hr />
        </div>

        <form onSubmit={bulkOrderFormik.handleSubmit}>
          <FormikProvider value={bulkOrderFormik}>
            <div className="row g-5">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="customInputWrpr">
                      <Autocomplete
                        className="flex-fill"
                        disablePortal
                        options={groupData ?? []}
                        getOptionLabel={(option) => option?.group_name ?? ""}
                        value={bulkOrderFormik.values.group}
                        onChange={(_, newValue) => {
                          bulkOrderFormik.setFieldValue(
                            `group`,
                            newValue ?? null
                          );
                          bulkOrderFormik.setFieldValue(
                            `group_members`,
                            newValue?.users.map((user) => {
                              return {
                                user: user,
                                kits: [],
                                pricing:
                                  reports?.map((report) =>
                                    report.id.toString()
                                  ) ?? [],
                              };
                            })
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id || null
                        }
                        renderInput={(params) => (
                          <TextField
                            name="group"
                            label="Enter group"
                            {...params}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    {!!bulkOrderFormik.values.group_members?.length ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th style={{ width: "30%" }}>Client Name</th>
                              <th style={{ width: "60%" }}>Kit Type</th>
                              <th style={{ width: "5%" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <FieldArray
                              name={`group_members`}
                              render={(arrayHeplers) => (
                                <>
                                  {!!bulkOrderFormik.values.group_members &&
                                    bulkOrderFormik.values.group_members.map(
                                      (el, idx) => (
                                        <React.Fragment key={idx}>
                                          <tr valign="middle">
                                            <td>{`0${idx + 1}`}</td>
                                            <td>
                                              {el.user.first_name}{" "}
                                              {el.user.last_name}
                                            </td>
                                            <td>
                                              <MultipleSelect
                                                cntnrClass="my-2"
                                                size="small"
                                                label="Select Kits"
                                                name={`group_members.${idx}.kits`}
                                                onChange={
                                                  bulkOrderFormik.handleChange
                                                }
                                                onBlur={() =>
                                                  bulkOrderFormik.setFieldTouched(
                                                    `group_members.${idx}.kits`,
                                                    true
                                                  )
                                                }
                                                value={
                                                  bulkOrderFormik.values
                                                    .group_members[idx].kits
                                                }
                                                bulkOrderFormik={
                                                  bulkOrderFormik
                                                }
                                                options={[
                                                  {
                                                    name: "Faeces",
                                                    value: "Faeces",
                                                  },
                                                  {
                                                    name: "Urine",
                                                    value: "Urine",
                                                  },
                                                  {
                                                    name: "Saliva",
                                                    value: "Saliva",
                                                  },
                                                  {
                                                    name: "Sweat",
                                                    value: "Sweat",
                                                  },
                                                ]}
                                              />
                                              {bulkOrderFormik.touched
                                                ?.group_members?.[idx]?.kits &&
                                              bulkOrderFormik.errors
                                                ?.group_members?.[idx]?.kits ? (
                                                <small className="mb-0 text-danger">
                                                  {
                                                    bulkOrderFormik.errors
                                                      .group_members[idx].kits
                                                  }
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              <IconButton
                                                onClick={() =>
                                                  arrayHeplers.remove(idx)
                                                }
                                              >
                                                <span className="material-symbols-rounded text-danger">
                                                  delete
                                                </span>
                                              </IconButton>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={4}>
                                              <div className="col-md-12 my-4">
                                                <label>Reports:</label>
                                                <div className="rprtsWrpr">
                                                  {!!reports?.length &&
                                                    reports?.map(
                                                      (data, index) => (
                                                        <div className="d-flex align-items-center">
                                                          <CustomCheckBox
                                                            key={index}
                                                            name={`group_members.${idx}.pricing`}
                                                            label={
                                                              <div className="d-flex justify-content-between w-100">
                                                                <span
                                                                  className="rprtNae"
                                                                  style={{
                                                                    whiteSpace:
                                                                      "pre",
                                                                  }}
                                                                >
                                                                  {
                                                                    data.report_name
                                                                  }
                                                                </span>
                                                              </div>
                                                            }
                                                            value={data.id}
                                                            checked={
                                                              bulkOrderFormik.values.group_members[
                                                                idx
                                                              ].pricing.find(
                                                                (el) =>
                                                                  el == data.id
                                                              )
                                                                ? true
                                                                : false
                                                            }
                                                            onChange={
                                                              bulkOrderFormik.handleChange
                                                            }
                                                          />
                                                          <div className="d-flex align-items-center justify-content-between w-100">
                                                            <span
                                                              className="material-symbols-rounded cursorPointer"
                                                              onClick={() =>
                                                                setPRicingDetails(
                                                                  {
                                                                    data: data.report_details,
                                                                    title:
                                                                      data.report_name,
                                                                    mount: true,
                                                                  }
                                                                )
                                                              }
                                                            >
                                                              info
                                                            </span>
                                                            <span className="rprtPricce">
                                                              {currencyFomatter(
                                                                data.price
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  <div className="ttlPrice d-flex align-items-center justify-content-between">
                                                    <p className="mb-0">
                                                      Subtotal:
                                                    </p>
                                                    <p className="mb-0 ttlPrVl">
                                                      {currencyFomatter(
                                                        bulkReportPrice(
                                                          bulkOrderFormik.values
                                                            .group_members[idx]
                                                            .pricing
                                                        )
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                {bulkOrderFormik.touched
                                                  ?.group_members?.[idx]
                                                  ?.pricing &&
                                                bulkOrderFormik.errors
                                                  ?.group_members?.[idx]
                                                  ?.pricing ? (
                                                  <small className="mb-0 text-danger">
                                                    {
                                                      bulkOrderFormik.errors
                                                        .group_members[idx]
                                                        .pricing
                                                    }
                                                  </small>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    )}
                                </>
                              )}
                            />
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-danger">Please select a group</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 position-relative ">
                <div className="row bg-light py-3 position-sticky sticky-top">
                  <div className="col-md-12">
                    <div className="rprtsWrpr">
                      <label className="mb-3 border-bottom w-100 pb-2">
                        Kits:
                      </label>

                      {orderPriceInfo?.kits &&
                      Object.keys(orderPriceInfo.kits)?.length ? (
                        Object.keys(orderPriceInfo.kits).map((item, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <p>
                              <span className="input-label">{item}</span>
                              <b> x{orderPriceInfo?.kits[item]}</b>
                            </p>

                            <span className="rprtPricce mb-3">
                              {currencyFomatter(0)}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p>No Kits Selected</p>
                      )}

                      <label className="mb-3 border-bottom w-100 pb-2">
                        Reports:
                      </label>
                      {orderPriceInfo?.pricing &&
                      Object.keys(orderPriceInfo.pricing)?.length ? (
                        Object.keys(orderPriceInfo.pricing).map((item, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <p>
                              <span className="input-label">
                                {
                                  reports?.[
                                    reports.findIndex((ele) => ele.id == item)
                                  ]?.report_name
                                }
                              </span>
                              <b> x{orderPriceInfo?.pricing[item]}</b>
                            </p>

                            <span className="rprtPricce mb-3">
                              {currencyFomatter(
                                reports?.[
                                  reports.findIndex((ele) => ele.id == item)
                                ]?.price ?? 0
                              )}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p>No Reports Selected</p>
                      )}

                      <div className="ttlPrice d-flex align-items-center justify-content-between">
                        <p className="mb-0">Total Price:</p>
                        <p className="mb-0 ttlPrVl me-0">
                          {currencyFomatter(orderPriceInfo?.total || 0)}
                        </p>
                      </div>
                    </div>
                    {bulkOrderFormik.touched["pricing"] &&
                    bulkOrderFormik.errors["pricing"] ? (
                      <p className="mb-0 text-danger mt-2">
                        {bulkOrderFormik.errors["pricing"]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 text-end mt-4">
                    <CustomButton
                      label={bulkLoading ? "Placing Order.." : "Place Order"}
                      disabled={bulkLoading}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormikProvider>
        </form>
      </div>
      <Drawer
        anchor={"right"}
        open={pricingDetails.mount}
        onClose={() =>
          setPRicingDetails({
            data: null,
            title: null,
            mount: false,
          })
        }
        className="product_view_modal followReportDetails"
      >
        <div className="p-5 flwRptInrWrprDt">
          <IconButton
            className="clsIcnBtn"
            onClick={() =>
              setPRicingDetails({
                data: null,
                title: null,
                mount: false,
              })
            }
          >
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
          <div className="stacFontContainer">
            <h4>{pricingDetails.title}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: pricingDetails.data }}
            ></div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default BulkFollowkitForm;
