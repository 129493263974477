import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useCreateCouponMutation,
  useGetCouponByIdQuery,
  useUpdateCouponMutation,
} from "../../../services/Users/couponApi";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useGetUsersListQuery } from "../../../services/Users/usersApi";
import { getTransformedRoleLabel } from "../../../Utils/Utils";

const validationSchema = yup.object().shape({
  type: yup.string(),
  amount: yup
    .number()
    .required("Required")
    .when(["type"], {
      is: (type) => type === "percentage",
      then: yup.number().max(100, "Percentage must under between 1 to 100"),
    }),
  user: yup.object().required().typeError("Required"),
});

const AddCoupon = ({ type, data, mount, handleClose }) => {
  const [createCoupon, { isLoading }] = useCreateCouponMutation();
  const [updateCoupon, { isLoading: isUpdate }] = useUpdateCouponMutation();

  const { data: selectedDepartment } = useGetCouponByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      type: "percentage",
      amount: "",
      user: null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const formValues = {
        ...values,
        amount: values.type === "amount" ? values.amount : null,
        percentage: values.type === "percentage" ? values.amount : null,
        user: values.user?.id ?? null,
      };
      if (type === "new") {
        try {
          const create = await createCoupon(formValues)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Coupon created successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create coupon!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateCoupon(formValues)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Coupon updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update coupon!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit" && data) {
      formik.setFieldValue("type", data?.type);
      formik.setFieldValue(
        "amount",
        data?.type === "percentage" ? data?.percentage : data?.amount
      );
      formik.setFieldValue("user", data?.user);
      formik.setFieldValue("id", data?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedDepartment]);

  const { data: sports_persons_list } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
  });

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <div className="circle_back mb-2">
          <span className="material-symbols-rounded">confirmation_number</span>
        </div>
        <h4 className="mb-2">
          {type !== "new" ? `Update: ${data?.coupon_code}` : "Add Coupon"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="customInputWrpr">
                <FormControl className="text-center d-flex flex-row gap-3 align-items-center">
                  <RadioGroup
                    value={formik.values.type}
                    name="type"
                    onChange={formik.handleChange}
                    className="d-flex flex-row gap-3 flex-wrap justify-content-center"
                  >
                    <FormControlLabel
                      value="amount"
                      control={<Radio />}
                      label="Amount"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Percentage"
                    />
                  </RadioGroup>
                </FormControl>
                {formik.touched?.type && formik.errors?.type ? (
                  <p className="errMsg">{formik.errors.type}</p>
                ) : (
                  ""
                )}
              </div> */}
            </div>
            <div className={`col-md-12`}>
              <CustomInput
                label={
                  formik.values.type === "amount" ? "Amount" : "Percentage"
                }
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>

            <div className="col-md-12 mb-4">
              <div className="customInputWrpr">
                <Autocomplete
                  disablePortal
                  options={sports_persons_list ?? []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.first_name} ${
                          option?.last_name
                        } | ${getTransformedRoleLabel(
                          option?.account_type,
                          option?.account_subtype
                        )} | ${option?.ref_no}`
                      : ""
                  }
                  value={formik.values.user}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("user", newValue ?? null);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      name="user"
                      {...params}
                      label="Enter Client Name or ID"
                    />
                  )}
                />
                {formik?.touched?.user && formik?.errors?.user ? (
                  <p className="errMsg">{formik.errors.user}</p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="flscrnBtnWrpr d-flex align-items-center gap-2">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddCoupon;
