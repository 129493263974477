import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import {
  useFKitTransactionMutation,
  useTransactionApprovalMutation,
} from "../../services/Transactions/TransactionsApi";
import moment from "moment";

const TransactionForm = ({ data, handleClose, type, mount }) => {
  const [transactionApproval, { isLoading }] = useTransactionApprovalMutation();
  const [fKitTransaction, { isLoading: floading }] =
    useFKitTransactionMutation();

  const validationArray = Yup.object().shape({
    transaction_id: Yup.string().required("Required"),
    payment_type: Yup.string().required("Required"),
    transaction_date: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      transaction_id: "",
      payment_type: "",
      transaction_date: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      if (isLoading || floading) return;
      console.log("data.type", type, type == "followKit");
      if (type == "followKit") {
        console.log("followkit transaction form");
        try {
          toast.loading("Approving payment...");
          const res = await fKitTransaction({ id: data?.id, ...values })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.dismiss();
                toast.success("Successfully approved the payment.");
                handleClose();
              }
            });
          return res;
        } catch (error) {
          console.error(error);
          toast.dismiss();
          toast.error("Failed to approve the payment!");
        }
      } else {
        try {
          toast.loading("Approving payment...");
          const res = await transactionApproval({ id: data?.id, ...values })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.dismiss();
                toast.success("Successfully approved the payment.");
                handleClose();
              }
            });
          return res;
        } catch (error) {
          console.error(error);
          toast.dismiss();
          toast.error("Failed to approve the payment!");
        }
      }
    },
  });

  return (
    <div>
      <h5 className="mb-2">Provide Transaction Details</h5>
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <div className="row text-start">
          <div className="col-md-12">
            <CustomInput
              label="Transaction ID"
              placeholder="Enter  Transaction ID"
              type="text"
              name="transaction_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transaction_id}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Payment Mode"
              placeholder="Enter Payment Mode"
              type="text"
              name="payment_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.payment_type}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Transaction Date"
              type="date"
              name="transaction_date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transaction_date}
              formik={formik}
              shrink={true}
              maxDate={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
          <div className="col-md-12">
            <CustomButton
              label={isLoading ? "Submitting..." : "Submit"}
              type="submit"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TransactionForm;
