import React, { useState, useEffect, useCallback } from "react";
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";

import { currencyFomatter, followStatus, urlBuilder } from "../../Utils/Utils";
import "./Checkout.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";

import AddAddress from "../ProfileDetails/AddAddress";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import {
  useGetFollowCartListQuery,
  useGetFollowKitPricingsQuery,
  useCreateFollowOrderMutation,
  useDeleteFollowCartListMutation,
  useCreateBulkFollowOrderMutation,
  useGetAddressQuery,
} from "../../services/followkit/followkitApi";

import { Delete, Mail, Person } from "@mui/icons-material";

const FollowCheckout = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const { data: cart, isFetching } = useGetFollowCartListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
  });

  const [createBulkOrder, { isLoading: bulkOrderLoading }] =
    useCreateBulkFollowOrderMutation();

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(user?.id, {
    refetchOnFocus: true,
  });

  const validationArray = Yup.object().shape({
    shipping: Yup.string().when("separate_shipping", {
      is: false,
      then: Yup.string().required("Address is a required field"),
    }),
    payment_method: Yup.string().required("Plese choose the Payment method."),
    separate_shipping: Yup.boolean().required(
      "Plese choose the shipping method"
    ),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      shipping: "",
      payment_method: "offline",
      separate_shipping: true,
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      console.log("values", values);
      if (bulkOrderLoading) return;
      await createBulkOrder({
        ...values,
        separate_shipping: true,
      })
        .unwrap()
        .then((res) => {
          if (res) {
            const order_group_id = res?.order_details[0]?.order_group_id;
            toast.success("Order Placed Successfully!");
            if (values.payment_method === "online") {
              navigate(`/payment/bulk/${order_group_id}`);
            } else {
              navigate("/followKits");
            }
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else if (error?.data?.msg) {
            toast.error(error?.data?.msg);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  useEffect(() => {
    if (formik.values.separate_shipping === true) {
      formik.setFieldValue("shipping", "");
    } else {
      if (address?.length > 0) {
        const defaultAddress = address.findIndex(
          (address) => address.is_default === true
        );
        formik.setFieldValue(
          "shipping",
          address[defaultAddress !== -1 ? defaultAddress : 0]?.id
        );
      }
    }
  }, [address, formik.values.separate_shipping]);

  useEffect(() => {
    if (cart?.length > 0) {
      formik.setFieldValue("id", cart[0]?.id);
    }
  }, [cart]);

  const [deleteFollowCartList, { isLoading: deletingCart }] =
    useDeleteFollowCartListMutation();

  const handelDeleteCarthandler = async ({ id }) => {
    if (deletingCart) return;

    const notify = toast.loading("Deleting cart item...");

    try {
      await deleteFollowCartList(id)
        .unwrap()
        .then((res) => {
          if (res) {
            console.log(res);
            toast.success("Cart removed successfully", { id: notify });
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete cart item", { id: notify });
    }
  };

  const transformdBulkData = useCallback(
    (list) => {
      return list?.reduce((acc, item, index) => {
        const userExists = acc.findIndex(
          (el) => el.user === item.receiver_user
        );
        if (userExists >= 0) {
          acc[userExists].kits.push(item.followkit?.kit_name);
        } else {
          acc.push({
            kits: [item.followkit?.kit_name],
            reports: item.followkit?.pricing,
            user: item.receiver_user,
            user_name: item.user_name,
            user_email: item.user_email,
            user_ref_no: item.followkit?.user_ref_no,
          });
        }
        return acc;
      }, []);
    },
    [cart, reports]
  );

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2 text-muted">
                shopping_bag
              </span>

              <h5 className="mb-0">Checkout</h5>
            </div>
            <hr />
          </div>
          {cart?.length > 1 && (
            <div className="alert_wrapper mb-4">
              <Alert severity="error">
                If more than one cart Please remove unwanted cart before
                checking out to avoid any issues.
              </Alert>
            </div>
          )}

          <div className="checkoutWrapper flwKtChkWrpr justify-content-between flex-column flex-md-row">
            <div className="chckOtPdtDtls">
              {!!cart?.length &&
                cart?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <div className="chckOtCard">
                      <div className="pdtDtlsCnrt pt-3">
                        {transformdBulkData(data?.fkitcartitems)?.map(
                          (client, clientIdx) => (
                            <div key={clientIdx}>
                              <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                                <p className="d-flex align-items-center flex-wrap gap-2 mb-0">
                                  <Person color="primary" />

                                  {user?.department?.department_name ===
                                  "lab" ? (
                                    client.user_ref_no
                                  ) : (
                                    <>
                                      {client.user_name}
                                      <small>({client.user_email})</small>
                                    </>
                                  )}
                                </p>
                                {data?.type === "bulk"
                                  ? cart?.length > 1 &&
                                    clientIdx === 0 && (
                                      <div className="dltCrt">
                                        <IconButton
                                          onClick={() =>
                                            handelDeleteCarthandler(data)
                                          }
                                          color="error"
                                          disabled={deletingCart}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </div>
                                    )
                                  : cart?.length > 1 && (
                                      <div className="dltCrt">
                                        <IconButton
                                          onClick={() =>
                                            handelDeleteCarthandler(data)
                                          }
                                          color="error"
                                          disabled={deletingCart}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </div>
                                    )}
                              </div>
                              <div className="lstCntnr border-bottom my-3 p-2">
                                <label>Kit Types:</label>
                                <ol>
                                  {client.kits.map((kit, kitIdx) => (
                                    <li key={kitIdx}>
                                      <div>
                                        <span className="ktNm">{kit}</span>
                                        <span className="ktPrc">
                                          {currencyFomatter(0)}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              </div>
                              <div className="lstCntnr border-bottom my-3 p-2">
                                <label>Reports:</label>
                                <ol>
                                  {client.reports.map((report, reportIdx) => (
                                    <li key={reportIdx} className="tCap">
                                      <div>
                                        <span className="ktNm">
                                          {report.report_name}
                                        </span>
                                        <span className="ktPrc">
                                          {currencyFomatter(report.price)}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            </div>
                          )
                        )}

                        <p className=" d-flex align-items-center justify-content-between">
                          <span>Total:</span>
                          <b>{currencyFomatter(data.total_price)}</b>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className="pdtActnInfos">
              <form onSubmit={formik.handleSubmit}>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="customInputWrpr">
                      <FormControl>
                        <FormLabel>Payment Method</FormLabel>
                        <RadioGroup
                          defaultValue={formik.values.payment_method}
                          name="payment_method"
                          onChange={formik.handleChange}
                          className="d-flex flex-row gap-3 flex-wrap"
                        >
                          {/* <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="Online"
                    /> */}
                          <FormControlLabel
                            value="offline"
                            control={<Radio />}
                            label="Offline"
                          />
                        </RadioGroup>
                      </FormControl>
                      {formik.touched?.payment_method &&
                      formik.errors?.payment_method ? (
                        <p className="errMsg">{formik.errors.payment_method}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="customInputWrpr">
                      <FormControl>
                        <FormLabel>Shipping Method</FormLabel>
                        <RadioGroup
                          defaultValue={formik.values.separate_shipping}
                          name="separate_shipping"
                          onChange={formik.handleChange}
                          className="d-flex flex-row gap-3 flex-wrap"
                        >
                          {/* <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="My Address"
                    /> */}
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Client's Respective Address"
                          />
                        </RadioGroup>
                      </FormControl>
                      {formik.touched?.separate_shipping &&
                      formik.errors?.separate_shipping ? (
                        <p className="errMsg">
                          {formik.errors.separate_shipping}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr />

                  <div className="col-md-12">
                    <CustomButton
                      disabled={bulkOrderLoading || cart?.length > 1}
                      label={
                        bulkOrderLoading ? "Placing order..." : "Place Order"
                      }
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={addressModal.open}
        handleClose={() => setAddressModal({ open: false, data: null })}
        maxWidth="md"
        className="blogDialog"
      >
        <AddAddress
          data={addressModal.data}
          type={addressModal.type}
          handleClose={() => setAddressModal({ open: false, data: null })}
        />
      </CustomDialog>
    </>
  );
};

export default FollowCheckout;
