import { appApi } from "../api/api";

export const employeesApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeesList: builder.query({
      query: () => `/api/admin/employees`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "Employees",
              ...result.map(({ id }) => ({ type: "Employees", id })),
            ]
          : ["Employees"],
    }),

    getEmployeesBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/employees/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Employees",
              ...result?.report?.map(({ id }) => ({
                type: "Employees",
                id,
              })),
            ]
          : ["Employees"],
    }),

    getEmployeeById: builder.query({
      query: (id) => `/api/admin/employees/${id}`,
      providesTags: (result, error, arg) => [{ type: "Employees", id: arg }],
    }),
    createEmployees: builder.mutation({
      query: (values) => ({
        url: `/api/admin/employees/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Employees"],
    }),
    updateEmployees: builder.mutation({
      query: (values) => ({
        url: `/api/admin/employees/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Employees", id: arg.id },
      ],
    }),
    deleteEmployees: builder.mutation({
      query: (id) => ({
        url: `/api/admin/employees/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employees"],
    }),
  }),
});

export const {
  useGetEmployeesListQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeesMutation,
  useDeleteEmployeesMutation,
  useUpdateEmployeesMutation,
  useLazyGetEmployeesBySearchQuery,
} = employeesApi;
