import { appApi } from "../api/api";

export const reportsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getGutbiomicsReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/gutbiomics_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Gutbiomics"],
    }),

    uploadGutbiomicsReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/${values.get("userId")}/gutbiomics_reports/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Gutbiomics"],
    }),

    updateGutbiomicsReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/gutbiomics_reports/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["Gutbiomics"],
    }),

    deleteGutbiomicsReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/gutbiomics_reports/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gutbiomics"],
    }),
    // Gutbiomics Report Ends

    // Microbiome Report

    getMicrobiomeReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/microbiome_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Microbiome"],
    }),

    uploadMicrobiomeReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/${values.get("userId")}/microbiome_reports/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Microbiome"],
    }),

    updateMicrobiomeReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/microbiome_reports/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["Microbiome"],
    }),

    deleteMicrobiomeReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/microbiome_reports/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Microbiome"],
    }),

    // Microbiome Report Ends

    // Tolerance Report

    getToleranceReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/tolerence_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Tolerance"],
    }),

    uploadToleranceReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/${values.get("userId")}/tolerance_reports/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Tolerance"],
    }),

    updateToleranceReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/tolerance_reports/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["Tolerance"],
    }),

    deleteToleranceReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/tolerance_reports/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tolerance"],
    }),

    // Tolerance Report Ends

    // KPI Report

    getKpiReportReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/kpi_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["KpiReport"],
    }),

    uploadKpiReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/${values.get("userId")}/kpi_reports/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["KpiReport"],
    }),

    updateKpiReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/kpi_reports/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["KpiReport"],
    }),

    deleteKpiReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/kpi_reports/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["KpiReport"],
    }),

    // KPI Report Ends

    getInnerHacksReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/innerhack_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["InnerHacksReport"],
    }),

    uploadInnerHackReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/${values.get("userId")}/innerhack_reports/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["InnerHacksReport"],
    }),
    updateInnerHackReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/innerhack_reports/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["InnerHacksReport"],
    }),
    deleteInnerHackReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/innerhack_reports/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["InnerHacksReport"],
    }),

    // user reports
    getUserUploadedReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/user_report_search/${id}`,
        method: "POST",
        body: values,
      }),
      providesTags: ["UploadedReports"],
    }),

    uploadUserReport: builder.mutation({
      query: (values) => ({
        url: `/api/user_report/${values.get("userId")}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UploadedReports"],
    }),

    //followkit report index
    getFollowkitReports: builder.query({
      query: (id) => `/api/follow_up_kit_report_index/${id}`,
      providesTags: ["FollowKits"],
    }),

    getReportDeleteRequest: builder.query({
      query: () => `/api/admin/report_delete_requests`,
      providesTags: ["DeleteReport"],
    }),

    getApproveRequest: builder.mutation({
      query: (id) => ({
        url: `/api/admin/report_delete_requests/${id}/approve`,
        method: "PATCH",
      }),
      invalidatesTags: ["DeleteReport"],
    }),

    getRejectRequest: builder.mutation({
      query: ({ id, values }) => ({
        url: `/api/admin/report_delete_requests/${id}/reject`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["DeleteReport"],
    }),

    getDeleteLogs: builder.query({
      query: (id) => `/api/admin/report_logs`,
      providesTags: ["DeleteReport"],
    }),

    getReportById: builder.query({
      query: (id) => `/api/admin/gutbiomics_reports/${id}/show`,
      providesTags: (result, error, arg) => [{ type: "Reports", id: arg }],
    }),
  }),
});

export const {
  useGetGutbiomicsReportsQuery,
  useUploadGutbiomicsReportMutation,
  useDeleteGutbiomicsReportMutation,
  useGetMicrobiomeReportsQuery,
  useUploadMicrobiomeReportMutation,
  useDeleteMicrobiomeReportMutation,
  useGetToleranceReportsQuery,
  useUploadToleranceReportMutation,
  useDeleteToleranceReportMutation,
  useGetKpiReportReportsQuery,
  useUploadKpiReportMutation,
  useDeleteKpiReportMutation,
  useGetUserUploadedReportsQuery,
  useUploadUserReportMutation,
  useGetInnerHacksReportsQuery,
  useUploadInnerHackReportMutation,
  useUpdateInnerHackReportMutation,
  useDeleteInnerHackReportMutation,
  useGetFollowkitReportsQuery,
  useUpdateKpiReportMutation,
  useUpdateGutbiomicsReportMutation,
  useUpdateToleranceReportMutation,
  useUpdateMicrobiomeReportMutation,
  useLazyGetKpiReportReportsQuery,
  useLazyGetMicrobiomeReportsQuery,
  useLazyGetToleranceReportsQuery,
  useLazyGetGutbiomicsReportsQuery,
  useLazyGetInnerHacksReportsQuery,
  useLazyGetUserUploadedReportsQuery,
  useGetReportDeleteRequestQuery,
  useGetApproveRequestMutation,
  useGetRejectRequestMutation,
  useGetDeleteLogsQuery,
  useLazyGetReportByIdQuery,
} = reportsApi;
