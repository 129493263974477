import React from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { useForgotPasswordMutation } from "../../services/auth/authApi";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const validationArray = Yup.object().shape({
    email: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      try {
        const response = await forgotPassword(values)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success(res.message);
              actions.resetForm();
            }
          });
        return response;
      } catch (error) {
        console.log(error);
        toast.error("Please check your email!");
      }
    },
  });

  console.log("formik value", formik);

  return (
    <AuthLayout showButton={false}>
      <div className="lgnFormContainer authWrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <h2>Forgot Your Password?</h2>
            <p>
              Get it changed by enter your registered email, we will send you a
              magic link to reset your password.
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                formik={formik}
              />
            </div>
            <div className="col-md-12 text-center">
              <CustomButton
                label={
                  isLoading
                    ? "Sending Password Reset Link..."
                    : "Send Password Reset Link"
                }
                disabled={isLoading}
                type="submit"
              />
              <p className="mt-3">
                <Link to="/login">Back to Login</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
