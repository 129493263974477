import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import { DeleteOutline, EditOutlined, Tune } from "@mui/icons-material";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import {
  useDeleteEmployeesMutation,
  useGetEmployeesListQuery,
  useLazyGetEmployeesBySearchQuery,
} from "../../../services/settings/employeesApi";
import ExportComponent from "../../../Components/Molecules/ExportComponent";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSearchValue } from "../../../features/search/searchSlice";
import { Badge, TablePagination } from "@mui/material";
import EmployeeSearchModal from "./EmployeeSearchModal";
import { handleSort } from "../../../Utils/Utils";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";

const Employees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Reports list with filters

  const [getEmployees, { data: list, isError, isLoading, isFetching }] =
    useLazyGetEmployeesBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { employee_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        state: "",
        country: "",
        zipcode: "",
        gender: "",
        city: "",
      },
      list_per_page: 10,
      page: 1,
      sort: "",
    },
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "employee_search", values }));
      try {
        const query = await getEmployees(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getEmployees(previousFilter);
      } else {
        await getEmployees(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.country && count++;
    searchFormik.values.filter.state && count++;
    searchFormik.values.filter.city && count++;
    searchFormik.values.filter.zipcode && count++;
    searchFormik.values.filter.gender && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    return count;
  }, [searchFormik.values]);

  const [deleteEmployee, { isLoading: deleting }] =
    useDeleteEmployeesMutation();

  const handleDelete = async (data) => {
    return confirmDialog("Are you sure to delete this Employee!", async () => {
      const notify = toast.loading("Deleting Employee..");
      try {
        return await deleteEmployee(data.id)
          .unwrap()
          .then(() => {
            toast.success("Employee deleted successfully", { id: notify });
          });
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete Employee!", { id: notify });
      }
    });
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "First Name", key: "first_name", sortable: true },
    { name: "Last Name", key: "last_name", sortable: true },
    { name: "Email", key: "email", sortable: true },
    { name: "Department", key: "department", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    badge
                  </span>
                  Employees
                </h3>
                <CustomSearch
                  inputName="search"
                  query={searchFormik.values.search}
                  handleChange={searchFormik.handleChange}
                  handleSubmit={(e) => {
                    e.preventDefault();
                    searchFormik.handleSubmit();
                    searchFormik.setFieldValue("page", 1);
                  }}
                  containerClasses="ms-md-auto small"
                  disabled={isFetching || isLoading}
                />

                <Badge badgeContent={handelBageContent} color="secondary">
                  <CustomButton
                    size="small"
                    onClick={() => setFilterModal(true)}
                    label={
                      <>
                        <Tune className="me-2" />
                        Filter
                      </>
                    }
                  />
                </Badge>
                <CustomButton
                  size="small"
                  label={
                    <>
                      <span className="material-symbols-rounded me-2">add</span>
                      New Employees
                    </>
                  }
                  onClick={() => navigate("/users/employees/add")}
                />
                <ExportComponent
                  type="employee"
                  from_date={""}
                  to_date={""}
                  size="small"
                />
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report.length &&
                      list.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>{data.first_name}</td>
                          <td>{data.last_name}</td>
                          <td>{data.email}</td>
                          <td>{data.department?.department_name || "-"}</td>
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <EditOutlined />
                                      Edit
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return navigate(
                                      `/users/employees/edit/${data.id}`
                                    );
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <DeleteOutline />
                                      Delete
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return handleDelete(data);
                                  },
                                  disabled: deleting,
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialogPopper />
      {filterModal && (
        <EmployeeSearchModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default Employees;
