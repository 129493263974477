import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useCreateProductCategoryMutation,
  useGetProductCategoryByIdQuery,
  useUpdateProductCategoryMutation,
} from "../../../services/products/ProductsApi";

const validationSchema = yup.object().shape({
  pc_name: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Category is required"),
});

const AddProductCategory = ({ type, data, mount, handleClose }) => {
  const [createCategory, { isLoading }] = useCreateProductCategoryMutation();
  const [updateCategory, { isLoading: isUpdate }] =
    useUpdateProductCategoryMutation();

  const { data: selectedProduct } = useGetProductCategoryByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      pc_name: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await createCategory(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Product Category added successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to add Product Category!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateCategory(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Product Category updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update Product Category!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("pc_name", selectedProduct?.pc_name);
      formik.setFieldValue("id", selectedProduct?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedProduct]);

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <img src="/assets/svg/product-circle.svg" className="mb-3" />
        <h4 className="mb-2">
          {type !== "new" ? `Update: ${data?.pc_name}` : "Add New Category"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Product Category"
                name="pc_name"
                value={formik.values.pc_name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddProductCategory;
