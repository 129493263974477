import { appApi } from "../api/api";

export const InviteApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query({
      query: (id) => `/api/invite_index/${id}`,
      providesTags: ["Invite"],
    }),

    //Invite people
    invitePeople: builder.mutation({
      query: (values) => ({
        url: "/api/invite/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Invite"],
    }),
  }),
});

export const { useInvitePeopleMutation, useGetInvitationsQuery } = InviteApi;
