import React, { useEffect, useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import {
  useCreateEmployeesMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeesMutation,
} from "../../../services/settings/employeesApi";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { assetUrlBuilder } from "../../../Utils/Utils";
import { useGetDepartmentsListQuery } from "../../../services/settings/departmentsApi";
import { PhotoCamera } from "@mui/icons-material";

const EmployeeForm = () => {
  const [createEmployee, { isLoading }] = useCreateEmployeesMutation();
  const [updateEmployee, { isLoading: isUpdate }] =
    useUpdateEmployeesMutation();

  const { id: empId, type } = useParams();
  const { data: selectedEmployee, isLoading: employeeLoading } =
    useGetEmployeeByIdQuery(empId, {
      skip: !empId,
    });
  const { data: department } = useGetDepartmentsListQuery(undefined, {
    refetchOnFocus: true,
  });

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      address_1: "",
      address_2: "",
      state: "",
      country: "",
      city: "",
      department: null,
      password: "",
      conf_password: "",
      zipcode: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .required("First Name is a required field")
        .min(3, "Minimum 3 characters required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
        .matches(/[^\s*]/g, "This field cannot contain only blankspaces"),
      last_name: Yup.string()
        .required("Last Name is a required field")
        .min(3, "Minimum 3 characters required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
        .matches(/[^\s*]/g, "This field cannot contain only blankspaces"),
      username: Yup.string()
        .required("Username is a required field")
        .matches(
          /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
          "Invalid username"
        ),
      dob: Yup.date()
        .typeError("INVALID_DATE")
        .min(moment().subtract(120, "years"), "Invalid DOB")
        .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
        .required("DOB is a required field"),
      email: Yup.string().email().required("Email is a required field"),
      mobile_no: Yup.number().required("Phone No is a required field"),
      password:
        type === "edit"
          ? Yup.string()
              .min(2, "Too Short!")

              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              )
          : Yup.string()
              .min(2, "Too Short!")
              .required("Password is a required field")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              ),
      conf_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match")
        .when("password", {
          is: (password) => !!password,
          then: Yup.string().required("confirmed Password is a required field"),
        }),
      department: Yup.mixed()
        .typeError("Department is a required field")
        .required("Department is a required field"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log("submit values", values);

      const formValues = new FormData();

      for (const key in values) {
        if (
          key !== "uploads" &&
          key !== "profile_image" &&
          key !== "department"
        ) {
          formValues.append(key, values[key]);
        }
      }
      if (values.department?.id) {
        formValues.append("department", values.department?.id);
        console.log("department name", values.department?.department_name);
        if (values.department?.department_name === "admin") {
          formValues.append("is_superuser", true);
        }
      } else {
        console.log("department else condition");
        formValues.append("department", "");
      }

      if (selectedFile !== null) {
        formValues.append("profile_image", selectedFile);
      }

      if (empId) {
        formValues.append("id", empId);
      }

      if (type == "edit") {
        try {
          const update = await updateEmployee(formValues)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Employee updated successfully");
                navigate("/users/employees");
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Failed to update employee!");
          }
        }
      } else {
        try {
          const create = await createEmployee(formValues)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Employee created successfully");
                navigate("/users/employees");
                actions.resetForm();
              }
            });
          return create;
        } catch (error) {
          console.error(error);

          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Failed to create employee!");
          }
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit" && selectedEmployee) {
      for (const key in formik.values) {
        formik.setFieldValue(key, selectedEmployee[key]);
      }
    }
  }, [type, selectedEmployee]);

  // csc handlers

  const country_list = country_api.getAllCountries();
  const state_list = state_api.getStatesOfCountry(formik.values.country);

  useEffect(() => {
    if (formik.values.country) {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  // profile handler

  const handleProfile = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    setProfileUrl(blob);
    return (e.target.value = "");
  };

  useEffect(() => {
    let index = state_list.findIndex(
      (ele) => ele.isoCode === formik.values.state
    );
    if (index < 0) {
      setState("");
      formik.values.state = "";
    }
  }, [formik.values.state, state_list]);

  if (type === "edit" && employeeLoading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="mnDashboardView">
      <div className="dshStCard prflPageWrpr">
        <div className="pt-2 pb-4">
          <h5 className="d-flex align-items-center text-capitalize">
            <IconButton className="me-2" onClick={() => navigate(-1)}>
              <span className="material-symbols-rounded fs-5">
                arrow_back_ios_new
              </span>
            </IconButton>
            <span className="material-symbols-rounded me-2">
              account_circle
            </span>
            {type} Employee
          </h5>
          <hr />
        </div>
        <div className="row">
          <div className="col-md-8 order-2 order-md-1">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <CustomInput
                    label="First Name"
                    placeholder="Enter first name"
                    type="text"
                    name="first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="Last Name"
                    placeholder="Enter last name"
                    type="text"
                    name="last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                    formik={formik}
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    label="Username"
                    placeholder="Enter Username"
                    type="text"
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <div className="customInputWrpr">
                    <Autocomplete
                      disablePortal
                      options={department ?? []}
                      getOptionLabel={(option) => option.department_name || ""}
                      value={formik.values.department}
                      onChange={(_, newValue) => {
                        formik.setFieldValue("department", newValue ?? null);
                      }}
                      onBlur={() => formik.setFieldTouched("department", true)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Department" />
                      )}
                    />
                    {formik?.touched?.department &&
                    formik?.errors?.department ? (
                      <p className="errMsg">{formik.errors.department}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="Email"
                    placeholder="Enter email"
                    type="text"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    formik={formik}
                    autoComplete="username"
                  />
                </div>
                <div className="col-md-6">
                  <div className="customInputWrpr">
                    <PhoneInput
                      country={"be"}
                      name="mobile_no"
                      value={formik.values.mobile_no}
                      onChange={(e) => {
                        formik.values.mobile_no = e;
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("mobile_no", true);
                      }}
                      containerClass="intl_container"
                      inputClass="intl_input"
                      dropdownClass="intl_dropdown"
                    />
                    {formik.touched.mobile_no && formik.errors.mobile_no ? (
                      <p className="errMsg">{formik.errors.mobile_no}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="Date Of Birth"
                    placeholder="Enter d.o.b"
                    type="date"
                    name="dob"
                    shrink={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <CustomSelect
                    label="Gender"
                    placeholder="Enter email"
                    name="gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                    formik={formik}
                    options={[
                      {
                        value: "male",
                        name: "Male",
                      },
                      {
                        value: "female",
                        name: "Female",
                      },
                      {
                        value: "others",
                        name: "Others",
                      },
                    ]}
                  />
                </div>

                <div className="col-md-12">
                  <CustomInput
                    label="Address"
                    placeholder="Enter Address"
                    name="address_1"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address_1}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <div className="customInputWrpr">
                    <Autocomplete
                      disablePortal
                      options={country_list}
                      getOptionLabel={(option) => option.name || ""}
                      value={county}
                      onChange={(_, newValue) => {
                        setCountry(newValue);
                        formik.setFieldValue(
                          "country",
                          newValue?.isoCode ?? ""
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.isoCode === value.isoCode || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="customInputWrpr">
                    <Autocomplete
                      disablePortal
                      options={state_list}
                      getOptionLabel={(option) => option.name || ""}
                      value={state}
                      onChange={(_, newValue) => {
                        setState(newValue);
                        formik.setFieldValue("state", newValue?.isoCode ?? "");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.isoCode === value.isoCode || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="State" />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="City"
                    placeholder="Enter city"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    formik={formik}
                  />
                  {/* <div className="customInputWrpr">
                    <Autocomplete
                      disablePortal
                      options={city_list}
                      getOptionLabel={(option) => option.name || ""}
                      value={city}
                      onChange={(_, newValue) => {
                        setCity(newValue);
                        formik.setFieldValue("city", newValue?.name ?? "");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value.name || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="City" />
                      )}
                    />
                  </div> */}
                </div>

                <div className="col-md-6">
                  <CustomInput
                    label="Zipcode"
                    placeholder="Enter Zipcode"
                    name="zipcode"
                    // type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipcode}
                    formik={formik}
                  />
                </div>

                {window.location.pathname.includes("/employees/edit/") && (
                  <div className="col-md-12">
                    <h6 className="mb-4 mt-5 d-flex align-items-center">
                      <span className="material-symbols-rounded me-2">
                        lock_reset
                      </span>
                      Change Password
                    </h6>
                  </div>
                )}

                <div className="col-md-6">
                  <CustomInput
                    label="Password"
                    placeholder="Enter password"
                    name="password"
                    type={passVisible ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    formik={formik}
                    autoComplete="new-password"
                    endAdornment={
                      <IconButton
                        className="material-symbols-rounded"
                        onClick={() => setPassVisible(!passVisible)}
                      >
                        {passVisible ? "visibility_off" : "visibility"}
                      </IconButton>
                    }
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="Confirm Password"
                    placeholder="Re-enter your password"
                    name="conf_password"
                    type={confirmPass ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conf_password}
                    formik={formik}
                    endAdornment={
                      <IconButton
                        className="material-symbols-rounded"
                        onClick={() => setConfirmPass(!confirmPass)}
                      >
                        {confirmPass ? "visibility_off" : "visibility"}
                      </IconButton>
                    }
                    autoComplete="new-password"
                  />
                </div>

                <div className="col-md-12 text-center mt-3">
                  <CustomButton
                    label={isLoading || isUpdate ? "Submitting..." : "Submit"}
                    type="submit"
                    disabled={isLoading || isUpdate}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4 order-1 order-md-2">
            <div className="usrImgWrpr">
              <div className="profile_actions">
                <IconButton
                  className="profile_action_btn"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    type="file"
                    onChange={handleProfile}
                    accept="image/*"
                    hidden
                  />
                  <PhotoCamera />
                </IconButton>
              </div>
              <Avatar
                src={
                  profileUrl || assetUrlBuilder(selectedEmployee?.profile_image)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeForm;
