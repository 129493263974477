import * as React from "react";
import "./product.css";
import "swiper/css";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useGetProductByIdQuery } from "../../services/products/ProductsApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
// import { useGetUserByIdQuery } from "../../services/Users/usersApi";
import { Link } from "react-router-dom";

export default function ViewProduct({ mount, data, handleClose }) {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [swiperInstance, setSwiperInstance] = React.useState(null);

  const { data: product, isLoading } = useGetProductByIdQuery(data?.id, {
    skip: !data?.id,
  });

  // const { data: perso_user } = useGetUserByIdQuery(product?.user, {
  //   skip: !product?.user,
  // });

  return isLoading ? (
    <div className="ldngWrpr">
      <CircularProgress />
    </div>
  ) : (
    <div className="product_dtl_wrapper">
      <Swiper
        className="mySwiper"
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
        grabCursor={true}
        // breakpoints={{
        //   640: {
        //     slidesPerView: 2,
        //     spaceBetween: 10,
        //   },
        // }}
      >
        {!!product?.productimages?.length &&
          product.productimages.map((el, index) => (
            <SwiperSlide
              key={index}
              virtualIndex={index}
              className="product_previewer"
            >
              <img
                src={el?.product_image}
                alt=""
                style={{ objectFit: "contain" }}
                className="object-fit-cover object-fit-contain"
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="product_que_imgs">
        {!!product?.productimages?.length &&
          product.productimages.map((el, idx) => (
            <img
              key={idx}
              src={el?.product_image}
              alt={`que_img-${idx}`}
              className={activeSlide == idx ? "active" : ""}
              onClick={() => {
                !!swiperInstance && swiperInstance.slideTo(idx, 150, false);
              }}
            />
          ))}
      </div>
      <Divider className="my-4" />
      <h6>Product Details</h6>
      <div className="prod_info_wpr">
        <div className="prod_info">
          <label>Price</label>
          <p>
            {product?.price !== ""
              ? `${currencyFomatter(product?.price)}`
              : "Not Given"}
          </p>
        </div>
        <div className="prod_info">
          <label>Type</label>
          <p>
            {product?.product_type}{" "}
            {product?.product_type === "personalized" && (
              <span className="mb-0">
                <Link to={`/users/sport-persons/view/${product?.user}`}>
                  ({product?.user_ref_no})
                </Link>
              </span>
            )}
          </p>
        </div>
        <div className="prod_info">
          <label>Available Qty</label>
          <p>{product?.available_qty}</p>
        </div>
        <div className="prod_info">
          <label>Vat</label>
          <p>
            {product?.vat}
            {product?.vat ? "%" : ""}
          </p>
        </div>
        <div className="prod_info">
          <label>Status</label>
          <p
            className={`${
              product?.status === "open" ? "text-success" : "text-danger"
            }`}
          >
            {product?.status}
          </p>
        </div>
      </div>
      <div className="product_description mt-3">
        <h6 className="my-4">Product Description</h6>
        <div
          className="pdtDscVwRpr"
          dangerouslySetInnerHTML={{ __html: product?.description }}
        />
      </div>
      <div className="mt-5">
        <CustomButton
          onClick={handleClose}
          color="secondary"
          label={"Go Back"}
        />
      </div>
    </div>
  );
}
