import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomEditor from "../../../Components/Atoms/CustomEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import {
  useGetRecipeByIdQuery,
  useGetRecipeCategoryQuery,
  useUpdateRecipesReportMutation,
  useUploadRecipesReportMutation,
} from "../../../services/ebooksAndRecipes/ebooksAndRecipes";

import { assetUrlBuilder } from "../../../Utils/Utils";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  recipe_name: yup.string().required("Recipe name is required"),
  recipe_blog: yup.string().required("Recipe description is required"),
  category: yup.string().required("Recipe category is required"),
});

const AddRecipes = ({ type, data, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [uploadRecipe, { isLoading }] = useUploadRecipesReportMutation();
  const [updateRecipe, { isLoading: isUpdate }] =
    useUpdateRecipesReportMutation();

  const { data: category } = useGetRecipeCategoryQuery();
  const { data: selectedRecipe, isLoading: gettingRecipe } =
    useGetRecipeByIdQuery(data?.id, {
      skip: !data?.id,
    });

  const formik = useFormik({
    initialValues: (type === "edit" && selectedRecipe) || {
      recipe_name: "",
      recipe_blog: "",
      category: "",
      date: new Date().toISOString().slice(0, 10),
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const formData = new FormData();
      if (selectedFile) formData.append("recipe_image", selectedFile);
      formData.append("recipe_name", values.recipe_name);
      formData.append("recipe_blog", values.recipe_blog);
      formData.append("category", values.category);
      formData.append("date", values.date);
      if (type === "edit") formData.append("id", values.id);

      if (type === "new") {
        try {
          const upload = await uploadRecipe(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Recipe uploaded successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to upload Recipe!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateRecipe(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Recipe updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update Recipe!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      setImageUrl(assetUrlBuilder(selectedRecipe?.recipe_image));
    } else if (type === "new") {
      formik.resetForm();
      setImageUrl(null);
    }
  }, [type, selectedRecipe]);

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    return setImageUrl(blob);
  };

  if (type === "edit" && gettingRecipe)
    return (
      <div className="d-flex justify-content-center mt-4">
        <CircularProgress />
      </div>
    );

  return (
    <div className="inrHckForm">
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          <div className="col-md-6">
            <CustomInput
              label="Title"
              name="recipe_name"
              value={formik.values.recipe_name}
              onChange={formik.handleChange}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomSelect
              label="Category"
              name="category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              formik={formik}
              options={
                category?.map((el) => {
                  return {
                    value: el.id,
                    name: el.rc_name,
                  };
                }) ?? []
              }
            />
          </div>

          <div className="col-md-12 mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Image</h6>
              <label>
                <div className="upldBtn text-center d-flex ">
                  <span className="material-symbols-rounded">cloud_upload</span>
                  <p>Upload Image</p>
                </div>
                <input
                  onChange={(e) => handleImage(e)}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </label>
            </div>
            <hr />
            {imageUrl && (
              <div className="blgImgPrev">
                <img src={imageUrl} />
              </div>
            )}
          </div>
          <div className="col-md-12 mt-2">
            <h6>Description</h6>
            <hr />
            {type === "edit" ? (
              <>
                <CustomEditor
                  data={formik.values.recipe_blog}
                  onChange={({ editor }) => {
                    formik.setFieldValue("recipe_blog", editor.getData());
                  }}
                  onReady={({ editor }) => {
                    editor.setData(selectedRecipe?.recipe_blog);
                  }}
                  error={formik.errors.recipe_blog && formik.errors.recipe_blog}
                />
              </>
            ) : (
              type === "new" && (
                <>
                  <CustomEditor
                    data={formik.values.recipe_blog}
                    onChange={({ editor }) => {
                      formik.setFieldValue("recipe_blog", editor.getData());
                    }}
                    error={
                      formik.errors.recipe_blog && formik.errors.recipe_blog
                    }
                  />
                </>
              )
            )}
          </div>

          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr gap-2 d-flex align-items-center justify-content-end">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpdate}
                type="submit"
                label={isLoading || isUpdate ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddRecipes;
