import { appApi } from "../api/api";

export const AuthApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // login user
    loginUser: builder.mutation({
      query: (values) => ({
        url: "/api/admin/login",
        method: "POST",
        body: values,
        credentials: "include",
      }),
    }),

    //Two Factor
    twoFactorAuth: builder.mutation({
      query: (values) => ({
        url: "/api/admin/Twofactor",
        method: "POST",
        body: values,
        credentials: "include",
      }),
      invalidatesTags: ["User"],
    }),

    //forgot password
    forgotPassword: builder.mutation({
      query: (values) => ({
        url: "/api/forgot_password/",
        method: "POST",
        body: values,
        credentials: "include",
      }),
    }),

    // signup user
    signupUser: builder.mutation({
      query: (values) => ({
        url: "/api/users/register",
        method: "POST",
        body: values,
      }),
    }),

    //resend otp
    resendOtp: builder.query({
      query: (id) => `/api/resend_otp/${id}`,
      invalidatesTags: ["OtpResend"],
    }),

    // logout user
    logoutUser: builder.mutation({
      query: () => ({
        url: "/api/admin/logout",
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["User"],
    }),

    //Delete User Profile
    deleteProfileImage: builder.mutation({
      query: (values) => ({
        url: `/api/profile_pic_delete/`,
        method: "GET",
        body: values,
      }),
      invalidatesTags: ["User"],
    }),

    //current user
    getCurrentUser: builder.query({
      query: () => "/api/admin/user",
      providesTags: ["User"],
    }),

    //update User
    updateUser: builder.mutation({
      query: (values) => ({
        url: `/api/admin/employees/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
    }),
    getGroupList: builder.query({
      query: () => `/api/admin_groups`,
      providesTags: ["Group"],
    }),
  }),
});

export const {
  useTwoFactorAuthMutation,
  useLoginUserMutation,
  useSignupUserMutation,
  useLazyGetCurrentUserQuery,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useUpdateUserMutation,
  useGetGroupListQuery,
  useLazyResendOtpQuery,
  useDeleteProfileImageMutation,
} = AuthApi;
