import React, { useEffect, useState } from "react";
import "./SideNav.css";
import { NavLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import { SwipeableDrawer, useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sideNavaData = [
  {
    primary: "Dashboard",
    link: "/",
    img: "/assets/svg/dashboard.svg",
  },
  {
    primary: "Users",
    img: "/assets/svg/profile.svg",
    isDropDown: true,
    pathname: "users",
    navlinks: [
      {
        primary: "Register User",
        link: "/users/register",
      },
      {
        primary: "Users Approval",
        link: "/users/approval",
      },
      {
        primary: "Sport Persons",
        link: "/users/sport-persons",
      },
      {
        primary: "Patients",
        link: "/users/patients",
      },

      {
        primary: "Team Managers",
        link: "/users/team-managers",
      },
      {
        primary: "Professionals",
        link: "/users/professionals",
      },
      {
        primary: "Doctors",
        link: "/users/doctors",
      },
      {
        primary: "Employees",
        link: "/users/employees",
      },
      {
        primary: "Deactivated Users",
        link: "/users/deactivated_users",
      },
      {
        primary: "Transactions",
        link: "/users/transactions",
      },
      {
        primary: "Coupons",
        link: "/users/coupons",
      },
    ],
  },
  {
    primary: "E-Books and Recipes",
    link: "/ebooks_recipes",
    img: "/assets/svg/ebook.svg",
  },
  {
    primary: "FollowKits",
    link: "/followkits",
    img: "/assets/svg/followkits.svg",
  },
  {
    primary: "Products",
    img: "/assets/svg/products.svg",
    isDropDown: true,
    pathname: "products",
    navlinks: [
      {
        primary: "Products",
        link: "/products/list",
      },
      {
        primary: "Closed Products",
        link: "/products/closed-produts",
      },
      {
        primary: "New Orders",
        link: "/products/new-orders",
      },
      {
        primary: "Past Orders",
        link: "/products/past-orders",
      },
    ],
  },
  {
    primary: "Settings",
    img: "/assets/svg/settings.svg",
    isDropDown: true,
    pathname: "settings",
    navlinks: [
      {
        primary: "Motives",
        link: "/settings/motives",
      },
      {
        primary: "Email Template",
        link: "/settings/email-templates",
      },
      {
        primary: "Product Category",
        link: "/settings/product-category",
      },
      {
        primary: "E-Book Category",
        link: "/settings/ebook-category",
      },
      {
        primary: "Recipe Category",
        link: "/settings/recipe-category",
      },
      {
        primary: "Static Pages",
        link: "/settings/staticPages",
      },
      {
        primary: "Sport Category",
        link: "/settings/sport-category",
      },
      {
        primary: "Report Pricing",
        link: "/settings/report-pricing",
      },
      {
        primary: "Departments",
        link: "/settings/departments",
      },
      {
        primary: "IBAN Number",
        link: "/settings/ibannumber",
      },
      {
        primary: "Sample Excel",
        link: "/settings/sample-excel",
      },
    ],
  },
  {
    primary: "Delete Report",
    link: "/delete_report",
    img: "/assets/svg/reports.svg",
  },
];

const SideNav = (props) => {
  const [open, setOpen] = React.useState(true);
  const [usersTab, setUsersTab] = React.useState(false);
  const matches = useMediaQuery("(max-width:1200px)");
  const { user } = useSelector((state) => state.auth);
  const handleDrawerOpen = () => {
    setOpen(true);
    props.setDrawerExpand(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setDrawerExpand(false);
  };

  useEffect(() => {
    if (props.drawerExpand == true) {
      setOpen(true);
    } else if (props.drawerExpand == false) {
      setOpen(false);
    }
  }, [props.drawerExpand]);

  const sideNavBasedOnRole = () => {
    if (user?.department?.department_name === "lab") {
      return [
        {
          primary: "Dashboard",
          link: "/",
          img: "/assets/svg/dashboard.svg",
        },
        {
          primary: "Sport Persons",
          link: "/users/sport-persons",
          img: "/assets/svg/sport_persons.svg",
        },
        {
          primary: "Patients",
          link: "/users/patients",
          img: "/assets/svg/patient.svg",
        },
        {
          primary: "FollowKits",
          link: "/followkits",
          img: "/assets/svg/followkits.svg",
        },
      ];
    } else {
      return sideNavaData;
    }
  };

  const DrawerValue = () => {
    return (
      <>
        <div className="sideNavHdr">
          <img
            className={`mnLogo ${open ? "" : "lgoSmll"}`}
            src="/assets/images/logo.png"
          />
          {!matches && (
            <IconButton
              className="toglNvBtn"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
        </div>
        <Divider />
        <List className="pt-4">
          {sideNavBasedOnRole().map((el, idx) => (
            <React.Fragment key={idx}>
              <CustomNav
                {...el}
                open={open}
                setOpen={setOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </React.Fragment>
          ))}
        </List>
      </>
    );
  };

  return (
    <>
      {!matches ? (
        <div className="sideNavComp">
          <Drawer variant="permanent" open={open}>
            <DrawerValue />
          </Drawer>
        </div>
      ) : (
        <SwipeableDrawer
          anchor={"left"}
          open={props.state["left"]}
          onClose={props.toggleDrawer("left", false)}
          onOpen={props.toggleDrawer("left", true)}
        >
          <div className="sideNavComp px-2">
            <DrawerValue />
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default SideNav;

const CustomNav = ({
  open,
  primary,
  isDropDown = false,
  link,
  pathname,
  img,
  setOpen,
  navlinks,
}) => {
  const [tab, setTab] = React.useState(false);

  const handleMenuClick = () => {
    setTab(!tab);
    setOpen(true);
  };

  useEffect(() => {
    if (pathname && window.location.pathname.includes(`/${pathname}/`)) {
      setTab(true);
    }
  }, [window.location.pathname]);

  return (
    <>
      {isDropDown ? (
        <>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={tab ? "activeList" : ""}
              onClick={() => {
                handleMenuClick();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={img} />
              </ListItemIcon>
              <ListItemText primary={open ? `${primary}` : ""} />
              {open ? tab ? <ExpandLess /> : <ExpandMore /> : ""}
            </ListItemButton>
          </ListItem>
          {open && isDropDown && (
            <Collapse in={tab} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {navlinks.map((nav, idx) => (
                  <React.Fragment key={idx}>
                    <ListItem
                      disablePadding
                      sx={{ display: "block" }}
                      className="sub_menu"
                    >
                      <NavLink to={nav.link}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          {nav.img && (
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <img src={nav.img} />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={open ? `${nav.primary}` : ""}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          )}
        </>
      ) : (
        <ListItem disablePadding sx={{ display: "block" }}>
          <NavLink to={link} className="navLink">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              {img && (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={img} />
                </ListItemIcon>
              )}
              <ListItemText
                primary={`${primary}`}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
      )}
    </>
  );
};
