import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomEditor from "../../Components/Atoms/CustomEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import {
  selectEmailTemplateById,
  useGetEmailTemplateByIdQuery,
  useUpdateEmailTemplateMutation,
  useUploadEmailTemplateMutation,
} from "../../services/email/emailApi";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Email title is required"),
  subject: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Email subject is required"),
  content: yup.string().required("Email content is required"),
});

const AddTemplate = ({ type, data, handleClose }) => {
  const [uploadEmail, { isLoading }] = useUploadEmailTemplateMutation();
  const [updateEmail, { isLoading: isUpdate }] =
    useUpdateEmailTemplateMutation();

  const { data: selectedEmailTemplate, isLoading: gettingEmail } =
    useGetEmailTemplateByIdQuery(data?.id, { skip: !data?.id });

  const formik = useFormik({
    initialValues: {
      title: "",
      subject: "",
      content: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // values.title = values.subject;

      if (type === "new") {
        try {
          const upload = await uploadEmail(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Email template created successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create email template!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateEmail(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Email template updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update email template!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("title", selectedEmailTemplate?.title);
      formik.setFieldValue("subject", selectedEmailTemplate?.subject);
      formik.setFieldValue("content", selectedEmailTemplate?.content);
      formik.setFieldValue("id", selectedEmailTemplate?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedEmailTemplate]);

  if (type === "edit" && gettingEmail)
    return (
      <div className="d-flex justify-content-center mt-4">
        <CircularProgress />
      </div>
    );

  return (
    <div className="inrHckForm">
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          {type === "new" && (
            <div className="col-md-12">
              <CustomInput
                label="Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>
          )}
          <div className="col-md-12">
            <CustomInput
              label="Subject"
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>

          <div className="col-md-12 mt-2">
            <h6>Email Content</h6>
            <hr />
            {type === "edit" ? (
              <>
                <CustomEditor
                  data={formik.values.content}
                  onChange={({ editor }) => {
                    formik.setFieldValue("content", editor.getData());
                  }}
                  onReady={({ editor }) => {
                    editor.setData(selectedEmailTemplate?.content);
                  }}
                  error={formik.errors.content && formik.errors.content}
                />
              </>
            ) : (
              type === "new" && (
                <CustomEditor
                  data={formik.values.content}
                  onChange={({ editor }) => {
                    formik.setFieldValue("content", editor.getData());
                  }}
                  error={formik.errors.content && formik.errors.content}
                />
              )
            )}
          </div>

          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr gap-2 d-flex align-items-center justify-content-end">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpdate}
                type="submit"
                label={isLoading || isUpdate ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTemplate;
