import React from "react";
import { useParams } from "react-router-dom";
import { useGetUserTransactionsQuery } from "../../../services/Transactions/TransactionsApi";
import { currencyFomatter } from "../../../Utils/Utils";

const SpTransactions = () => {
  const { id } = useParams();
  const { data: list } = useGetUserTransactionsQuery(id, {
    refetchOnFocus: true,
  });

  return (
    <>
      <div>
        {/* <div className="tpHdrVw d-flex align-items-center justify-content-end mb-2">
          <CustomButton
            onClick={() => setAddReportPopup(true)}
            label={
              <>
                <span className="material-symbols-rounded me-2">add</span>
                Add New Report
              </>
            }
          />
        </div> */}

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Order Type</th>
                <th>
                  <div className="d-flex align-items-center">
                    Transaction ID{" "}
                    <span className="material-symbols-rounded">
                      unfold_more
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Transaction Amount{" "}
                    <span className="material-symbols-rounded">
                      unfold_more
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Transaction Date{" "}
                    <span className="material-symbols-rounded">
                      unfold_more
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Payment Mode{" "}
                    <span className="material-symbols-rounded">
                      unfold_more
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!!list?.length ? (
                list?.map((data, idx) => (
                  <tr key={idx} style={{ verticalAlign: "middle" }}>
                    <td>{idx + 1}</td>
                    <td>{data["fkitorder"] ?? data["order"]}</td>
                    <td>
                      {data["fkitorder"] != undefined ? "Followkit" : "Product"}
                    </td>
                    <td>{data.transaction_id}</td>
                    <td>{currencyFomatter(data.amount)}</td>
                    <td>{data.transaction_date}</td>
                    <td>
                      <span className="status pending">
                        {data.payment_type}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ verticalAlign: "middle" }}>
                  <td colSpan={6} className="text-center">
                    No Records Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SpTransactions;
