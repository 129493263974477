import React, { useEffect, useState } from "react";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { Cached, Tune } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import CustomSelect from "../../../Components/Atoms/CustomSelect";

function ProfessionalSearchModal({ formik, open, handleClose }) {
  const handelReset = async () => {
    formik.resetForm();
    await formik.handleSubmit();
    handleClose(false);
    formik.setFieldValue("page", 1);
  };

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);

  const country_list = country_api.getAllCountries();
  const state_list = state_api.getStatesOfCountry(formik.values.filter.country);

  useEffect(() => {
    if (formik.values.filter.country) {
      country_api
        .getAllCountries()
        .filter(
          (el) => el.isoCode === formik.values.filter.country && setCountry(el)
        );
      if (formik.values.filter.state) {
        state_api
          .getStatesOfCountry(formik.values.filter.country)
          .filter(
            (el) => el.isoCode === formik.values.filter.state && setState(el)
          );
      }
    }
  }, [formik.values.filter]);

  return (
    <CustomDialog avoidBG open={open} handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <h6 className="mb-4">
          <Tune className="text-muted" /> Filter Professionals
        </h6>
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="What you are looking for?"
              formik={formik}
              name="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="col-md-6">
            <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={country_list}
                getOptionLabel={(option) => option.name || ""}
                value={county}
                onChange={(_, newValue) => {
                  setCountry(newValue);
                  formik.setFieldValue(
                    "filter.country",
                    newValue?.isoCode ?? ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.isoCode === value.isoCode || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Country" />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={state_list}
                getOptionLabel={(option) => option.name || ""}
                value={state}
                onChange={(_, newValue) => {
                  setState(newValue);
                  formik.setFieldValue("filter.state", newValue?.isoCode ?? "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.isoCode === value.isoCode || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="State" />
                )}
              />
            </div>
          </div>
          <div className="col-md-12">
            <CustomInput
              label="City"
              formik={formik}
              name="filter.city"
              value={formik.values.filter.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Zipcode"
              placeholder="Enter zipcode"
              name="filter.zipcode"
              // type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.filter.zipcode}
              formik={formik}
            />
          </div>

          <div className="col-md-6">
            <CustomSelect
              label="Gender"
              name="filter.gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.filter.gender}
              formik={formik}
              options={[
                {
                  value: "male",
                  name: "Male",
                },
                {
                  value: "female",
                  name: "Female",
                },
                {
                  value: "others",
                  name: "Others",
                },
              ]}
            />
          </div>

          <div className="col-md-12">
            <div className="customInputWrpr">
              <FormControl>
                <InputLabel>Sort</InputLabel>
                <Select
                  name="sort"
                  onChange={formik.handleChange}
                  value={formik.values.sort}
                  defaultValue=""
                  label="Sort"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>Ascending</ListSubheader>
                  <MenuItem value={"first_name"}>First Name</MenuItem>
                  <MenuItem value={"last_name"}>Last Name</MenuItem>
                  <MenuItem value={"sport_categeory"}>Sport</MenuItem>
                  <MenuItem value={"email"}>Email</MenuItem>

                  <ListSubheader>Descending</ListSubheader>
                  <MenuItem value={"-first_name"}>First Name</MenuItem>
                  <MenuItem value={"-last_name"}>Last Name</MenuItem>
                  <MenuItem value={"-sport_categeory"}>Sport</MenuItem>
                  <MenuItem value={"-email"}>Email</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <CustomButton
              label={
                <>
                  <Cached className="me-2" /> Reset
                </>
              }
              btnType="secondary"
              btnClass="primButton"
              onClick={handelReset}
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <CustomButton type="submit" label="Submit" />
          </div>
        </div>
      </form>
    </CustomDialog>
  );
}

export default ProfessionalSearchModal;
