import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import DoctorForm from "../../Signup/forms/DoctorForm";
import TeamManagerForm from "../../Signup/forms/TeamManagerForm";
import ProfessionalForm from "../../Signup/forms/ProfessionalForm";
import SportPersonForm from "../../Signup/forms/SportPersonForm";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import {
  useApproveUserMutation,
  useGetProfessionalsListQuery,
  useGetTeamManagersListQuery,
} from "../../../services/Users/usersApi";
import { useGetMotivesListQuery } from "../../../services/settings/motivesApi";
import { toast } from "react-hot-toast";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { CircularProgress } from "@mui/material";
import { useGetSportCategoryListQuery } from "../../../services/settings/sportCategoryApi";
import CustomRadio from "../../../Components/Atoms/CustomRadio";

function ViewUserApproval({ data, handleClose }) {
  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const { data: motive_list } = useGetMotivesListQuery();
  const { data: professional_list } = useGetProfessionalsListQuery();
  const { data: manager_list } = useGetTeamManagersListQuery();
  const { data: sport_categeory } = useGetSportCategoryListQuery();

  const formik = useFormik({
    initialValues: { ...data } || {
      account_type: "",
      account_subtype: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      height: "",
      weight: "",
      sport_categeory: "",
      professional: "",
      team_manager: "",
      motive: "",
      team_name: "",
      council_name: "",
      no_of_persons: "",
      specialization: "",
      hospital_name: "",
      referer_code: "",
      conf_password: "",
      password: "",
      btw_no: "",
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.country || "") {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      city_api
        .getCitiesOfState(formik.values.country, formik.values.state)
        .filter((el) => el.name === formik.values.city && setCity(el));
    }
  }, [formik.values.country, formik.values.state, formik.values.city]);

  const [approveUser, { isLoading: approvingUser }] = useApproveUserMutation();

  const handleApproveUser = async ({ id, first_name, last_name }) => {
    if (approvingUser) return;
    try {
      toast.loading(`Approving ${first_name} ${last_name}!`);
      await approveUser({ id, type: "SportsPersons" })
        .unwrap()
        .then((res) => {
          toast.dismiss();
          handleClose();
          toast.success(`Successfuly Approved ${first_name} ${last_name}!`);
        });
    } catch (error) {
      toast.dismiss();
      handleClose();
      toast.error(`Failed to Approve ${first_name} ${last_name}`);
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      formik.setFieldValue("state", data?.state);
    }
  }, [data]);

  const getFormByType = () => {
    switch (formik.values.account_type) {
      case "sport_person":
        return (
          <>
            <SportPersonForm
              formik={formik}
              country_list={country_api.getAllCountries()}
              state_list={state_api.getStatesOfCountry(formik.values.country)}
              city_list={city_api.getCitiesOfState(
                formik.values.country,
                formik.values.state
              )}
              professional_list={professional_list}
              manager_list={manager_list}
              motive_list={motive_list}
              county={county}
              state={state}
              city={city}
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              avoidPassword
              readOnly={true}
              sport_categeory={sport_categeory}
            />
          </>
        );
      case "professional":
        return (
          <ProfessionalForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword
            readOnly={true}
            sport_categeory={sport_categeory}
          />
        );
      case "team_manager":
        return (
          <TeamManagerForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword
            readOnly={true}
            sport_categeory={sport_categeory}
          />
        );
      case "doctor":
        return (
          <DoctorForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword
            readOnly={true}
          />
        );
      default:
        return (
          <div className="d-flex justify-content-center mt-4 mb-5">
            <CircularProgress />
          </div>
        );
    }
  };

  return (
    <>
      <h5 className="mb-4">User Information</h5>
      <div className="row">{getFormByType()}</div>
      {data?.status === "pending" && (
        <CustomButton
          disabled={approvingUser}
          onClick={() => handleApproveUser(data)}
          label={approvingUser ? "Apporving.." : "Approve"}
        />
      )}
    </>
  );
}

export default ViewUserApproval;
