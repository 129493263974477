import React, { useEffect } from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useShipmentSubmissionMutation } from "../../services/products/ProductsApi";
import { useFollowShipmentMutation } from "../../services/followkit/followkitApi";

const ShipmentForm = ({ data, handleClose, type }) => {
  const [shipmentSubmission, { isLoading }] = useShipmentSubmissionMutation();
  const [followShipment, { isLoading: shipLoading }] =
    useFollowShipmentMutation();

  const validationArray = Yup.object().shape({
    shipment_provider: Yup.string().required("Required"),
    shipment_code: Yup.string().required("Required"),
    trackingurl: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter valid url!"
    ),
  });

  console.log("type traans", type);

  const formik = useFormik({
    initialValues: {
      shipment_provider: "",
      shipment_code: "",
      trackingurl: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      if (data?.payment_status === "unpaid")
        return toast.error("Please Approve payment before!");

      if (type == "followKit") {
        try {
          const res = await followShipment({
            id: data?.id,
            ...values,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                actions.resetForm();
                handleClose();
                toast.success("Shipment details submitted.");
              }
            });
          return res;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Something went wrong.");
          }
          throw new Error(error);
        }
      } else {
        try {
          const res = await shipmentSubmission({
            id: data?.id,
            ...values,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                actions.resetForm();
                handleClose();
                toast.success("Shipment details submitted.");
              }
            });
          return res;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Something went wrong.");
          }
          throw new Error(error);
        }
      }
    },
  });

  return (
    <div>
      <h5 className="mb-2">Provide Shipment Details</h5>
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <div className="row text-start">
          <div className="col-md-12">
            <CustomInput
              label="Shipment provider"
              placeholder="Enter Shipment provider"
              type="text"
              name="shipment_provider"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.shipment_provider}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Shipment code"
              placeholder="Enter Shipment code"
              type="text"
              name="shipment_code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.shipment_code}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Tracking Url"
              placeholder="Enter tracking url"
              type="text"
              name="trackingurl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.trackingurl}
              formik={formik}
            />
          </div>

          <div className="col-md-12">
            <CustomButton
              label={isLoading ? "Submitting..." : "Submit"}
              type="submit"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShipmentForm;
