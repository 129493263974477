import { appApi } from "../api/api";

export const StaticPageApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaticList: builder.query({
      query: () => `/api/staticpages`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "staticPages",
              ...result.map(({ id }) => ({ type: "staticPages", id })),
            ]
          : ["staticPages"],
    }),
    getStaticListBySearch: builder.query({
      query: (values) => ({
        url: `/api/staticpages`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "staticPages",
              ...result?.report?.map(({ id }) => ({
                type: "staticPages",
                id,
              })),
            ]
          : ["staticPages"],
    }),
    getStaticById: builder.query({
      query: (id) => `/api/staticpages/${id}`,
      providesTags: (result, error, arg) => [{ type: "staticPages", id: arg }],
    }),
    uploadStaticPage: builder.mutation({
      query: (values) => ({
        url: `/api/staticpages/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "staticPages" }],
    }),
    updateStaticPage: builder.mutation({
      query: (values) => ({
        url: `/api/staticpages/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "staticPages", id: arg.id },
      ],
    }),
    deleteStaticPage: builder.mutation({
      query: (id) => ({
        url: `/api/staticpages/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "staticPages" }],
    }),
  }),
});

export const {
  useGetStaticListQuery,
  useUploadStaticPageMutation,
  useUpdateStaticPageMutation,
  useDeleteStaticPageMutation,
  useGetStaticByIdQuery,
  useLazyGetStaticListBySearchQuery,
} = StaticPageApi;
