import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomEditor from "../../Components/Atoms/CustomEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import {
  useUploadStaticPageMutation,
  useUpdateStaticPageMutation,
  useGetStaticByIdQuery,
} from "../../services/staticPages/StaticPageApi";

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Static Page Title is required"),
  content: yup.string().required("Static Page content is required"),
});

const AddStaticPages = ({ type, data, handleClose }) => {
  const [uploadStaticPage, { isLoading }] = useUploadStaticPageMutation();
  const [updateStaticPage, { isLoading: isUpdate }] =
    useUpdateStaticPageMutation();

  const { data: selectedStaticPage, isLoading: gettingStaticPage } =
    useGetStaticByIdQuery(data?.id, {
      skip: !data?.id,
    });

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const upload = await uploadStaticPage(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Static Page created successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create static page!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateStaticPage(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Static page updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update static page!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("title", selectedStaticPage?.title);
      formik.setFieldValue("content", selectedStaticPage?.content);
      formik.setFieldValue("id", selectedStaticPage?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedStaticPage]);

  if (type === "edit" && gettingStaticPage)
    return (
      <div className="d-flex justify-content-center mt-4">
        <CircularProgress />
      </div>
    );

  return (
    <div className="inrHckForm">
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          {type === "new" && (
            <div className="col-md-12">
              <CustomInput
                label="Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>
          )}

          <div className="col-md-12 mt-2">
            <h6>Page Content</h6>
            <hr />
            {type === "edit" ? (
              <>
                <CustomEditor
                  data={formik.values.content}
                  onChange={({ editor }) => {
                    formik.setFieldValue("content", editor.getData());
                  }}
                  onReady={({ editor }) => {
                    editor.setData(selectedStaticPage?.content);
                  }}
                  error={formik.errors.content && formik.errors.content}
                />
              </>
            ) : (
              type === "new" && (
                <>
                  <CustomEditor
                    data={formik.values.content}
                    onChange={({ editor }) => {
                      formik.setFieldValue("content", editor.getData());
                    }}
                    error={formik.errors.content && formik.errors.content}
                  />
                </>
              )
            )}
          </div>

          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr d-flex align-items-center justify-content-end gap-2">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpdate}
                type="submit"
                label={isLoading || isUpdate ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddStaticPages;
