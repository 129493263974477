import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import CustomInput from "../../Components/Atoms/CustomInput";
import PhoneInput from "react-phone-input-2";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";

import { useSelector } from "react-redux";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import {
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from "../../services/followkit/followkitApi";

// { ...user, conf_password: "", password: "" } ||

const AddAddress = ({ data, type, handleClose }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const [createAddress, { isLoading }] = useCreateAddressMutation();
  const [updateAddress, { isLoading: updateLoading }] =
    useUpdateAddressMutation();
  const [state_list, setStateList] = useState([]);

  const validationArray = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is a required field")
      .min(3, "Minimum 3 characters required")

      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
    last_name: Yup.string()
      .required("Last Name is a required field")
      .min(3, "Minimum 3 characters required")

      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
    phone: Yup.number().required("Phone No is a required field"),
    email: Yup.string()
      .email("Not a valid email")
      .required("Email is a required field"),
    address: Yup.string()
      .required("Address is a required field")
      .min(3, "Minimum 3 characters required")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
        "Invalid address"
      )
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces"),

    country: Yup.string().required("Country is a required field"),
    state: Yup.string().required("State is a required field"),
    city: Yup.string().required("City Name is a required field"),
    pincode: Yup.string().required("Zipcode is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: user?.email || "",
      phone: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      is_default: false,
    },
    validationSchema: validationArray,

    onSubmit: async (values) => {
      if (type === "edit") {
        let formData = {
          ...values,
          id: data?.id,
          user_id: user?.id,
        };
        await updateAddress(formData)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Address Changed Successfully!");
              handleClose();
              formik.resetForm();
            }
          })
          .catch((error) => {
            if (error?.data?.detail) {
              toast.error(error.data.detail);
            } else {
              toast.error("Something went wrong");
            }
            console.log("rejected", error);
            throw new Error(error);
          });
      } else {
        let formData = {
          ...values,
          user_id: user?.id,
        };
        await createAddress(formData)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Address Added Successfully!");
              handleClose();
              formik.resetForm();
            }
          })
          .catch((error) => {
            if (error?.data?.detail) {
              toast.error(error.data.detail);
            } else {
              toast.error("Something went wrong");
            }
            console.log("rejected", error);
            throw new Error(error);
          });
      }
    },
  });

  useEffect(() => {
    if (type === "edit" && Object.keys(data).length > 0) {
      formik.setFieldValue("first_name", data.first_name);
      formik.setFieldValue("last_name", data.last_name);
      formik.setFieldValue("phone", data.phone);
      formik.setFieldValue("email", data.email);
      formik.setFieldValue("address", data.address);
      formik.setFieldValue("country", data.country);
      formik.setFieldValue("city", data.city);
      formik.setFieldValue("pincode", data.pincode);
      formik.setFieldValue("is_default", data.is_default);
    } else if (type === "new") {
      setCountry(null);
      setState(null);
      setCity(null);
      formik.resetForm();
    }
  }, [type]);

  useEffect(() => {
    if (state_list?.length > 0 && type === "edit") {
      formik.setFieldValue("state", data.state);
    }
  }, [type, state_list, data]);

  useEffect(() => {
    if (formik.values.country || "") {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }

    setStateList(state_api.getStatesOfCountry(formik.values.country));
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      city_api
        .getCitiesOfState(formik.values.country, formik.values.state)
        .filter((el) => el.name === formik.values.city && setCity(el));
    }
  }, [formik.values.country, formik.values.state, formik.values.city]);

  useEffect(() => {
    let index = state_list.findIndex(
      (ele) => ele.isoCode === formik.values.state
    );
    if (index < 0) {
      setState("");
      formik.values.state = "";
    }
  }, [formik.values.state, state_list]);

  return (
    <>
      <div className="adrsPopuHdr">
        <h5 className="d-flex align-items-center">
          <span className="material-symbols-rounded me-2">post_add</span>Add
          Address
        </h5>
      </div>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-4">
          <div className="col-md-6">
            <CustomInput
              label="First Name"
              placeholder="Enter first name"
              type="text"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Last Name"
              placeholder="Enter last name"
              type="text"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Email"
              placeholder="Enter email"
              type="text"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              formik={formik}
              disabled={true}
              autoComplete="username"
            />
          </div>
          <div className="col-md-12">
            <div className="customInputWrpr">
              <PhoneInput
                country={"be"}
                name="phone"
                value={formik.values.phone}
                onChange={(e) => {
                  formik.values.phone = e;
                }}
                onBlur={() => {
                  formik.setFieldTouched("phone", true);
                }}
                containerClass="intl_container"
                inputClass="intl_input"
                dropdownClass="intl_dropdown"
              />
              {formik.touched.phone && formik.errors.phone ? (
                <p className="errMsg">{formik.errors.phone}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-12">
            <CustomInput
              label="Address"
              placeholder="Enter Address"
              name="address"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={country_api.getAllCountries()}
                getOptionLabel={(option) => option.name || ""}
                value={county}
                onChange={(_, newValue) => {
                  setCountry(newValue);
                  formik.setFieldValue("country", newValue?.isoCode ?? "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.isoCode === value.isoCode || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Country" />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={state_list}
                getOptionLabel={(option) => option.name || ""}
                value={state}
                onChange={(_, newValue) => {
                  setState(newValue);
                  formik.setFieldValue("state", newValue?.isoCode ?? "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.isoCode === value.isoCode || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="State" />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <CustomInput
              label="City"
              placeholder="Enter city"
              name="city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              formik={formik}
            />
            {/* <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={city_api.getCitiesOfState(
                  formik.values.country,
                  formik.values.state
                )}
                getOptionLabel={(option) => option.name || ""}
                value={city}
                onChange={(_, newValue) => {
                  setCity(newValue);
                  formik.setFieldValue("city", newValue?.name ?? "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value.name || ""
                }
                renderInput={(params) => <TextField {...params} label="City" />}
              />
            </div> */}
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Zipcode"
              placeholder="Enter zipcode"
              name="pincode"
              // type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pincode}
              formik={formik}
            />
          </div>
          <div className="col-md-6 mb-3">
            <CustomCheckBox
              label="Default Shipping Address"
              name="is_default"
              onChange={formik.handleChange}
              value={formik.values.is_default}
              checked={formik.values.is_default}
            />
          </div>
        </div>
        <div className="col-md-12">
          <CustomButton
            disabled={isLoading || updateLoading}
            label={
              type === "edit"
                ? updateLoading
                  ? "Saving Changes..."
                  : "Edit Address"
                : isLoading
                ? "Adding Address..."
                : "Add Address"
            }
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default AddAddress;
