import { appApi } from "../api/api";

export const ProductsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => `/api/products`,
      providesTags: (result, error, arg) =>
        result
          ? ["Products", ...result.map(({ id }) => ({ type: "Products", id }))]
          : ["Products"],
    }),

    getProductsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/products`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Products",
              ...result?.report?.map(({ id }) => ({ type: "Products", id })),
            ]
          : ["Products"],
    }),

    getClosedProductsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/products/closed_products`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Products",
              ...result?.report?.map(({ id }) => ({ type: "Products", id })),
            ]
          : ["Products"],
    }),

    getProductById: builder.query({
      query: (id) => `/api/admin/products/${id}`,
      providesTags: (result, error, arg) => [{ type: "Products", id: arg }],
    }),

    createProduct: builder.mutation({
      query: (values) => ({
        url: `/api/admin/products/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Products"],
    }),

    updateProduct: builder.mutation({
      query: (values) => ({
        url: `/api/admin/products/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Products", id: arg.id },
      ],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/api/admin/products/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),

    deleteProductImage: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/product_image/${arg.id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Products", id: arg.productId },
      ],
    }),

    getProductCategories: builder.query({
      query: () => `/api/admin/product_categories`,

      providesTags: (result, error, arg) =>
        result
          ? [
              "Products_Category",
              ...result.map(({ id }) => ({ type: "Products_Category", id })),
            ]
          : ["Products_Category"],
    }),

    getProductCategoriesBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/product_categories/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Products_Category",
              ...result?.report?.map(({ id }) => ({
                type: "Products_Category",
                id,
              })),
            ]
          : ["Products_Category"],
    }),
    getProductCategoryById: builder.query({
      query: (id) => `/api/admin/product_categories/${id}`,

      providesTags: (result, error, arg) => [
        { type: "Products_Category", id: arg },
      ],
    }),
    createProductCategory: builder.mutation({
      query: (values) => ({
        url: `/api/admin/product_categories/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Products_Category" }],
    }),

    updateProductCategory: builder.mutation({
      query: (values) => ({
        url: `/api/admin/product_categories/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Products_Category", id: arg.id },
      ],
    }),

    deleteProductCategory: builder.mutation({
      query: (id) => ({
        url: `/api/admin/product_categories/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Products_Category" }],
    }),

    getOrderedProducts: builder.query({
      query: () => `/api/admin/orders`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "OrderedProducts",
              ...result.map(({ id }) => ({ type: "OrderedProducts", id })),
            ]
          : ["OrderedProducts"],
    }),

    getNewOrderedBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/admin_search_neworders`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "OrderedProducts",
              ...result?.report?.map(({ id }) => ({
                type: "OrderedProducts",
                id,
              })),
            ]
          : ["OrderedProducts"],
    }),

    getPastOrderedBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/admin_search_pastorders`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "OrderedProducts",
              ...result?.report?.map(({ id }) => ({
                type: "OrderedProducts",
                id,
              })),
            ]
          : ["OrderedProducts"],
    }),

    getOrderedProductById: builder.query({
      query: (id) => `/api/admin/orders/${id}`,
      providesTags: (result, error, arg) => [
        { type: "OrderedProducts", id: arg },
      ],
    }),

    shipmentSubmission: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/admin/orders/${id}/update`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "OrderedProducts", id: arg.id },
      ],
    }),

    getUsersOrderedProducts: builder.query({
      query: (id) => `/api/users/${id}/order_products`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "UserProducts",

              ...result.map(({ id }) => ({ type: "UserProducts", id })),
            ]
          : ["UserProducts"],
    }),
    getUsersOrders: builder.query({
      query: (id) => `/api/${id}/orders`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "UserOrders",

              ...result.map(({ id }) => ({ type: "UserOrders", id })),
            ]
          : ["UserOrders"],
    }),

    newOrderCount: builder.query({
      query: (id) => `/api/new_orders/count`,
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useLazyGetProductByIdQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useDeleteProductImageMutation,
  useUpdateProductMutation,
  useGetProductCategoriesQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoryByIdQuery,
  useLazyGetProductsQuery,
  useGetOrderedProductsQuery,
  useGetOrderedProductByIdQuery,
  useGetUsersOrderedProductsQuery,
  useShipmentSubmissionMutation,
  useGetUsersOrdersQuery,
  useLazyGetProductsBySearchQuery,
  useNewOrderCountQuery,

  useLazyGetNewOrderedBySearchQuery,
  useLazyGetPastOrderedBySearchQuery,
  useLazyGetClosedProductsBySearchQuery,
  useLazyGetProductCategoriesBySearchQuery,
} = ProductsApi;
