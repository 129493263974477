import { appApi } from "../api/api";

export const TransactionsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionsList: builder.query({
      query: () => `/api/admin/transactions`,

      providesTags: (result, error, arg) =>
        result
          ? [
              "Transactions",
              ...result.map(({ id }) => ({ type: "Transactions", id })),
            ]
          : ["Transactions"],
    }),

    getTransactionsBySearch: builder.query({
      query: (arg) => ({
        url: `/api/admin/search_transaction`,
        method: "POST",
        body: arg,
      }),

      providesTags: (result, error, arg) =>
        result
          ? [
              "Transactions",
              ...result?.report?.map(({ id }) => ({
                type: "Transactions",
                id,
              })),
            ]
          : ["Transactions"],
    }),

    getUserTransactions: builder.query({
      query: (id) => `/api/${id}/transactions`,
      providesTags: (result, error, arg) => [{ type: "Transactions", id: arg }],
    }),

    transactionApproval: builder.mutation({
      query: (values) => ({
        url: `/api/admin/transactions/${values.id}/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "OrderedProducts", id: arg.id },
        { type: "Transactions", id: result?.id },
        { type: "Transactions" },
      ],
    }),

    fKitTransaction: builder.mutation({
      query: (values) => ({
        url: `/api/admin/fkittransactions/${values.id}/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["FollowKit"],
    }),
  }),
});

export const {
  useGetTransactionsListQuery,
  useGetUserTransactionsQuery,
  useTransactionApprovalMutation,
  useFKitTransactionMutation,
  useLazyGetTransactionsBySearchQuery,
} = TransactionsApi;
