import { appApi } from "../api/api";

export const usersApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query({
      query: () => `api/admin/users/index`,
      providesTags: (result, error, arg) =>
        result
          ? ["Users", ...result.map(({ id }) => ({ type: "Users", id }))]
          : ["Users"],
    }),

    getPaymentUsersList: builder.query({
      query: () => `api/admin_ip_approve/`,
      providesTags: ["Users"],
    }),

    getDoctorsList: builder.query({
      query: () => `/api/admin/doctors`,
      providesTags: (result, error, arg) =>
        result
          ? ["Doctors", ...result.map(({ id }) => ({ type: "Doctors", id }))]
          : ["Doctors"],
    }),
    getDoctorsListSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_doctor_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Doctors",
              ...result?.report?.map(({ id }) => ({ type: "Doctors", id })),
            ]
          : ["Doctors"],
    }),

    getProfessionalsList: builder.query({
      query: () => `/api/admin/professionals`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Professionals", id })),
              "Professionals",
            ]
          : ["Professionals"],
    }),

    getProfessionalsListSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_professional_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.report?.map(({ id }) => ({
                type: "Professionals",
                id,
              })),
              "Professionals",
            ]
          : ["Professionals"],
    }),

    getTeamManagersList: builder.query({
      query: () => `/api/admin/team_managers`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "TeamManagers", id })),
              "TeamManagers",
            ]
          : ["TeamManagers"],
    }),

    getTeamManagersListSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_tm_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.report?.map(({ id }) => ({
                type: "TeamManagers",
                id,
              })),
              "TeamManagers",
            ]
          : ["TeamManagers"],
    }),

    getManagerSportsPersons: builder.query({
      query: (arg) => `/api/team_manager_view/${arg.id}/${arg.type}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result.map(({ id }) => ({ type: "SportsPersons", id })),
            ]
          : ["SportsPersons"],
    }),
    getProfessionalSportsPersons: builder.query({
      query: (arg) => `/api/professional_view/${arg.id}/${arg.type}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result.map(({ id }) => ({ type: "SportsPersons", id })),
            ]
          : ["SportsPersons"],
    }),
    getDoctorSportsPersons: builder.query({
      query: (arg) => `/api/doctor_view/${arg.id}/${arg.type}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result.map(({ id }) => ({ type: "SportsPersons", id })),
            ]
          : ["SportsPersons"],
    }),

    getUserById: builder.query({
      query: (id) => `/api/show_user/${id}`,
      providesTags: (result, error, arg) => [{ type: "Users", id: arg }],
    }),

    getSportsPersons: builder.query({
      query: () => `/api/admin/sport_persons`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result.map(({ id }) => ({ type: "SportsPersons", id })),
            ]
          : ["SportsPersons"],
    }),

    getSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_sp_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),

    getTMSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/team_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),
    getProSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/prof_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),
    getDoctorSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/doctor_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),

    getDeactivatedUserSearch: builder.query({
      query: (values) => ({
        url: `/api/admin/deactivate_users`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "DeactivatedUser",
              ...result?.report?.map(({ id }) => ({
                type: "DeactivatedUser",
                id,
              })),
            ]
          : ["DeactivatedUser"],
    }),

    updateUserProfile: builder.mutation({
      query: (values) => ({
        url: `/api/admin/edit_user/${values.get("id")}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SportsPersons", id: arg.id },
      ],
    }),

    ApproveUser: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/${arg.id}/approve`,
        method: "GET",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Users", id: arg.id }],
    }),

    handleUserStatus: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/users/${arg}/active_suspend`,
        method: "GET",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SportsPersons", id: arg },
        { type: "TeamManagers", id: arg },
        { type: "Professionals", id: arg },
        { type: "Doctors", id: arg },
        { type: "Patients", id: arg },
      ],
    }),
    deactivateUser: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/users/${arg}/deactivate`,
        method: "GET",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SportsPersons", id: arg },
        { type: "TeamManagers", id: arg },
        { type: "Professionals", id: arg },
        { type: "Doctors", id: arg },
        { type: "Patients", id: arg },
      ],
    }),

    userApprovalCount: builder.query({
      query: (id) => `/api/user_approval/count`,
    }),

    bulkUserReassign: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/users/${arg.get("id")}/bulk_assign`,
        method: "POST",
        body: arg,
      }),
      invalidatesTags: (result, error, arg) => [
        "SportsPersons",
        "Patients",
        { type: "TeamManagers", id: arg.get("team_manager") },
        { type: "Professionals", id: arg.get("professional") },
        { type: "Doctors", id: arg.get("doctor") },
      ],
    }),

    getPatientSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_patient_index`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Patients",
              ...result?.report?.map(({ id }) => ({
                type: "Patients",
                id,
              })),
            ]
          : ["Patients"],
    }),

    getPatientList: builder.query({
      query: (arg) => `/api/patient_all`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "Patients",
              ...result?.map(({ id }) => ({
                type: "Patients",
                id,
              })),
            ]
          : ["Patients"],
    }),

    PaymentApproval: builder.mutation({
      query: (id) => ({
        url: `/api/admin/${id}/initial_approve`,
        method: "PATCH",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetDoctorsListQuery,
  useGetProfessionalsListQuery,
  useGetSportsPersonsQuery,
  useGetTeamManagersListQuery,
  useGetManagerSportsPersonsQuery,
  useGetProfessionalSportsPersonsQuery,
  useGetDoctorSportsPersonsQuery,
  useUpdateUserProfileMutation,
  useGetUserByIdQuery,
  useApproveUserMutation,
  useGetUsersListQuery,
  useHandleUserStatusMutation,
  useDeactivateUserMutation,
  useLazyGetDoctorsListSearchQuery,
  useLazyGetProfessionalsListSearchQuery,
  useLazyGetTeamManagersListSearchQuery,
  useLazyGetProSportPersonsListSearchQuery,
  useLazyGetDoctorSportPersonsListSearchQuery,
  useLazyGetTMSportPersonsListSearchQuery,
  useLazyGetSportPersonsListSearchQuery,
  useLazyGetPatientSearchQuery,
  useUserApprovalCountQuery,
  useBulkUserReassignMutation,
  useGetPatientListQuery,
  useGetPaymentUsersListQuery,
  usePaymentApprovalMutation,
  useLazyGetDeactivatedUserSearchQuery,
} = usersApi;
