import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../services/Users/usersApi";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserProfile from "../UserProfile";
import SportPersons from "../../SportPersons";
import Patients from "../../Patients";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TeamManagerView = () => {
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();
  const {
    data: user,
    isLoading,
    refetch,
  } = useGetUserByIdQuery(id, {
    refetchOnFocus: true,
  });

  if (isLoading)
    return (
      <div className="ldngWrpr">
        <CircularProgress />
      </div>
    );

  return (
    <div className="mnDashboardView">
      <div className="dshStCard prflPageWrpr">
        <div className="pt-2 ">
          <h5 className="d-flex align-items-center">
            <IconButton className="me-2" onClick={() => navigate(-1)}>
              <span className="material-symbols-rounded fs-5">
                arrow_back_ios_new
              </span>
            </IconButton>
            {user?.first_name} {user?.last_name} Profile
          </h5>
          <hr />
        </div>

        <div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
              >
                <Tab label="Profile Information" {...a11yProps(0)} />
                <Tab label="Sport Persons" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <UserProfile user={user} refetch={refetch} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SportPersons type="team_manager" user_id={id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Patients type="tm_patients" user_id={id} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default TeamManagerView;
