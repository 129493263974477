import { CircularProgress, Divider } from "@mui/material";
import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import { baseUrl } from "../../services/api/api";
import {
  useViewFollowKitByIdQuery,
  useGetFollowKitPricingsQuery,
} from "../../services/followkit/followkitApi";
import { currencyFomatter } from "../../Utils/Utils";
import FollowkitPricing from "./FollowkitPricing";

const addressContructor = (keys) => {
  let validKeys = new Array();
  keys.map((key) => !!key?.length && validKeys.push(key));

  return validKeys.join(", ");
};

const ViewFollowOrder = ({ mount, handleClose, data }) => {
  const { data: order, isLoading } = useViewFollowKitByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const { data: followKitPricings, isLoading: kitLoading } =
    useGetFollowKitPricingsQuery(undefined, {
      skip: !mount,
      refetchOnFocus: true,
    });

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="order_modal">
      {console.log("order", order)}
      <h5>
        Order ID: <span>{order?.id}</span>
      </h5>
      <div className="order_header">
        <div className="order_hdr_info ">
          <p>
            Order Date: <span>{order?.date}</span>
          </p>
          <Divider orientation="vertical" className="" />
          <p>
            Payment:{" "}
            <span
              className={`text-capitalize ${
                order?.payment_status === "unpaid"
                  ? "text-warning"
                  : "text-success"
              }`}
            >
              {order?.payment_status}
            </span>
          </p>
        </div>
        {console.log(order, "hihihih")}
        <CustomButton
          onClick={() =>
            window.open(
              `${baseUrl}/api/fkitorders/invoice/${order?.e_id}`,
              "_blank"
            )
          }
          label={
            <>
              <span className="material-symbols-rounded me-2 fs-5">
                receipt_long
              </span>
              Invoice
            </>
          }
          size={"small ms-auto"}
        />
      </div>

      <Divider />

      <div className="order_items_list">
        <div className="ktDtls">
          <label>Kit Details:</label>
          {data.fkitorderitems.map((fdata, index) => (
            <>
              <div
                className="kitDtlRw d-flex align-items-center justify-content-between"
                key={index}
              >
                <div>
                  <span>{fdata.followkit.req_id}</span>
                  <span className="pe-3">:</span>
                  <span>{fdata.followkit.kit_name}</span>
                </div>
                <span className="">{currencyFomatter(0)}</span>
              </div>
            </>
          ))}
        </div>
        <div className="ktDtls">
          <label>Report Details:</label>
          {followKitPricings?.length > 0 &&
            data.pricing.map((fdata, index) => (
              <>
                <div
                  className="kitDtlRw d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <span>{fdata.report_name}</span>
                  <span>{currencyFomatter(fdata.price ?? 0)}</span>
                </div>
              </>
            ))}
        </div>
        {!!data?.discount_amount && (
          <>
            <div className="order_item py-3 px-0">
              <p className="mb-0 flex-fill">Order Amount</p>{" "}
              <b>{currencyFomatter(data?.original_amount)}</b>
            </div>
            <div className="order_item py-3 px-0">
              <p className="mb-0 flex-fill">Coupon Savings</p>{" "}
              <b>-{currencyFomatter(data?.discount_amount)}</b>
            </div>
          </>
        )}
        <div className="order_item py-3 px-0">
          <p className="mb-0 flex-fill">Order Total</p>{" "}
          <b>{currencyFomatter(data?.total_price)}</b>
        </div>
      </div>
      <h6 className="border-bottom py-2">Delivery Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Name</p>
          <p className="text-capitalize">{`${order?.shipping?.first_name} ${order?.shipping?.last_name}`}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Email</p>
          <p>{order?.shipping?.email}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Mobile</p>
          <p>{order?.shipping?.phone}</p>
        </div>
        <div className="or_info">
          <p className="text-muted ">Shipping Address</p>
          <p>
            {addressContructor([
              order?.shipping?.address,
              order?.shipping?.city,
              order?.shipping?.state,
              order?.shipping?.country,
              order?.shipping?.pincode,
            ])}
          </p>
          {order?.shipping?.landmark && (
            <p>Landmark - {order?.shipping?.landmark}</p>
          )}
        </div>
      </div>
      <h6 className="border-bottom py-2">Transactions Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Payment Mode</p>
          <p>{order?.transaction?.payment_type || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction ID</p>
          <p>{order?.transaction?.transaction_id || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction Amount</p>
          <p>
            {order?.transaction?.amount
              ? currencyFomatter(order?.transaction?.amount)
              : "-"}
          </p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction Date</p>
          <p>{order?.transaction?.transaction_date || "-"}</p>
        </div>
      </div>
      <h6 className="border-bottom py-2">Shipment Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Shipment provider</p>
          <p>{order?.shipment_provider || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Shipment code</p>
          <p>{order?.shipment_code || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Track Your Package</p>
          <a href={order?.trackingurl} target="_blank">
            {order?.trackingurl || "-"}
          </a>
        </div>
      </div>

      <CustomButton
        label={"Go Back!"}
        size={"mt-4"}
        onClick={() => handleClose()}
      />
    </div>
  );
};

export default ViewFollowOrder;
