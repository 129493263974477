import React from "react";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { Cached, Tune } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomSelect from "../../../Components/Atoms/CustomSelect";

function TransactionSearch({ formik, open, handleClose }) {
  const handelReset = async () => {
    formik.resetForm();
    await formik.handleSubmit();
    handleClose(false);
    formik.setFieldValue("page", 1);
  };

  return (
    <CustomDialog avoidBG open={open} handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <h6 className="mb-4">
          <Tune className="text-muted" /> Filter Transactions
        </h6>
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="What you are looking for?"
              formik={formik}
              name="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <TextField
                type="date"
                label="Start date"
                name="filter.date1"
                value={formik.values.filter.date1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
              />

              {formik?.touched.filter?.date1 && formik?.errors.filter?.date1 ? (
                <p className="errMsg">{formik.errors.filter?.date1}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <TextField
                type="date"
                label="End date"
                name="filter.date2"
                value={formik.values.filter.date2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
              />

              {formik?.touched.filter?.date2 && formik?.errors.filter?.date2 ? (
                <p className="errMsg">{formik.errors.filter?.date2}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-6">
            <CustomSelect
              label="status"
              name="filter.status"
              onChange={formik.handleChange}
              value={formik.values.filter.status}
              options={[
                { name: "Online", value: "online" },
                { name: "Offline", value: "offline" },
              ]}
            />
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <FormControl>
                <InputLabel>Sort</InputLabel>
                <Select
                  name="sort"
                  onChange={formik.handleChange}
                  value={formik.values.sort}
                  defaultValue=""
                  label="Sort"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"id"}>Ascending</MenuItem>
                  <MenuItem value={"-id"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <CustomButton
              label={
                <>
                  <Cached className="me-2" /> Reset
                </>
              }
              btnType="secondary"
              btnClass="primButton"
              onClick={handelReset}
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <CustomButton type="submit" label="Submit" />
          </div>
        </div>
      </form>
    </CustomDialog>
  );
}

export default TransactionSearch;
