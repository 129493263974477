import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setUserCredentials } from "../../features/auth/authSlice";

export const baseUrl = process.env.REACT_APP_API;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: "include",
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 403) {
    const refreshResult = await baseQuery("/api/user", api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setUserCredentials(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const appApi = createApi({
  reducerPath: "APP_API",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Users",
    "Invite",
    "Gutbiomics",
    "Microbiome",
    "KpiReport",
    "Products",
    "Product",
    "SportsPersons",
    "UploadedReports",
    "InnerHacksReport",
    "Ebooks",
    "Recipes",
    "Email_Templates",
    "Motives",
    "Recipe-Category",
    "E-Book-Category",
    "staticPages",
    "FollowKit",
    "OrderedProducts",
    "Transactions",
    "Doctors",
    "Professionals",
    "TeamManagers",
    "SportCategory",
    "FollowKitPricings",
    "Departments",
    "Employees",
    "Patients",
    "DeleteReport",
    "UserProducts",
    "UserOrders",
    "UserAddress",
    "Follow_Cart",
    "Coupon",
    "DeactivatedUser",
  ],
  endpoints: (builder) => ({}),
});
