import { baseUrl } from "../services/api/api";

export const getTransformedRoleLabel = (account_type, account_subtype) => {
  if (account_subtype === "patient") {
    return !!account_subtype?.length ? account_subtype.replace("_", " ") : "";
  }
  return !!account_type?.length ? account_type.replace("_", " ") : "";
};

export const assetUrlBuilder = (url) => {
  return !!url?.length ? `${baseUrl}${url}` : null;
};

export const currencyFomatter = (val) => `€${val || 0}`;

export const followStatus = [
  {
    value: 1,
    name: "Requested",
  },
  {
    value: 2,
    name: "Kit Sent",
  },
  {
    value: 3,
    name: "Sample Received",
  },
  {
    value: 4,
    name: "Report Generated",
  },
];

export const handleSort = (state, key) => {
  if (!key.includes("-") && key === state.values.sort) {
    state.setFieldValue("sort", `-${key}`);
  } else if (state.values.sort.includes(`-${key}`)) {
    state.setFieldValue("sort", "");
  } else {
    state.setFieldValue("sort", key);
  }
  return state.handleSubmit();
};
