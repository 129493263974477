import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";

import {
  useGetFollowKitPricingsQuery,
  useGetFollowKitPricingByIdQuery,
  useNewFollowitPriceMutation,
  useUpdateFollowitPriceMutation,
} from "../../../services/followkit/followkitApi";
import ReportDialog from "../../../Components/Organisms/ReportDialog";
import CustomEditor from "../../../Components/Atoms/CustomEditor";

const AddReportPricing = ({ type, data, mount, handleClose }) => {
  const { data: selectedCategory } = useGetFollowKitPricingByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const [newFollowKitPrice, { isLoading }] = useNewFollowitPriceMutation();

  const [updateFollowKitPrice, { isLoading: isUpdate }] =
    useUpdateFollowitPriceMutation();

  const validationSchema = yup.object().shape({
    report_name: yup
      .string()
      .min(3, "Minimum 3 characters required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .required("Category is required"),
    price: yup.number().required("Followup Price is required"),
    vat: yup.number().positive().min(0).max(100).required("Vat is required"),
    initial_price: yup.number().required("Intake Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      report_name: "",
      price: "",
      vat: "",
      initial_price: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await newFollowKitPrice(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report Price added successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to add Report Price!");
        }
      } else if (type === "edit") {
        values.id = selectedCategory?.id;
        try {
          const update = await updateFollowKitPrice(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report Price updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update Report Price!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("report_name", selectedCategory?.report_name);
      formik.setFieldValue("price", selectedCategory?.price);
      formik.setFieldValue("initial_price", selectedCategory?.initial_price);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedCategory]);

  return (
    <>
      <ReportDialog
        title={
          type !== "new" ? `Update: ${data?.report_name}` : "Add New Report"
        }
        open={mount}
        handleClose={() => {
          handleClose();
          formik.resetForm();
        }}
      >
        <div className="inrHckForm">
          <form onSubmit={formik.handleSubmit} className="p-4">
            <div className="row">
              <div className="col-md-12">
                <CustomInput
                  label="Report Name"
                  name="report_name"
                  value={formik.values.report_name}
                  onChange={formik.handleChange}
                  formik={formik}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Intake Price"
                  name="initial_price"
                  value={formik.values.initial_price}
                  onChange={formik.handleChange}
                  formik={formik}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Followup Price"
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  formik={formik}
                />
              </div>

              <div className="col-md-6">
                <CustomInput
                  label="Vat"
                  name="vat"
                  value={formik.values.vat}
                  onChange={formik.handleChange}
                  formik={formik}
                />
              </div>

              <div className="col-md-12 mt-2">
                <h6>Report Description</h6>
                <hr />
                {type === "edit" ? (
                  <>
                    <CustomEditor
                      data={formik.values.report_details}
                      onChange={({ editor }) => {
                        formik.setFieldValue(
                          "report_details",
                          editor.getData()
                        );
                      }}
                      onReady={({ editor }) => {
                        editor.setData(selectedCategory?.report_details);
                      }}
                      error={
                        formik.errors.report_details &&
                        formik.errors.report_details
                      }
                    />
                  </>
                ) : (
                  type === "new" && (
                    <CustomEditor
                      data={formik.values.report_details}
                      onChange={({ editor }) => {
                        formik.setFieldValue(
                          "report_details",
                          editor.getData()
                        );
                      }}
                      error={
                        formik.errors.report_details &&
                        formik.errors.report_details
                      }
                    />
                  )
                )}
              </div>

              <div className="col-md-12 ">
                <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                  <CustomButton
                    label="Cancel"
                    btnType="secondary"
                    onClick={handleClose}
                    btnClass="flex-fill"
                  />
                  <CustomButton
                    disabled={isLoading || isUpdate}
                    type="submit"
                    label={isLoading || isUpdate ? "submitting.." : "submit"}
                    btnClass="flex-fill"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </ReportDialog>
    </>
  );
};

export default AddReportPricing;
