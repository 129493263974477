import React from "react";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { Cached, Tune } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import { useGetProductCategoriesQuery } from "../../../services/products/ProductsApi";

function SearchCoupon({ formik, open, handleClose }) {
  const handelReset = async () => {
    formik.resetForm();
    await formik.handleSubmit();
    handleClose(false);
    formik.setFieldValue("page", 1);
  };

  const { data: categories } = useGetProductCategoriesQuery(undefined, {
    refetchOnFocus: true,
  });

  return (
    <CustomDialog avoidBG open={open} handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <h6 className="mb-4">
          <Tune className="text-muted" /> Filter Coupons
        </h6>
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="What you are looking for?"
              formik={formik}
              name="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="col-md-6">
            <CustomSelect
              label="Status"
              formik={formik}
              name="filter.valid"
              value={formik.values.filter.valid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={[
                { name: "Valid", value: true },
                { name: "Invalid", value: false },
              ]}
            />
          </div>
          <div className="col-md-6">
            <div className="customInputWrpr">
              <FormControl>
                <InputLabel>Sort</InputLabel>
                <Select
                  name="sort"
                  onChange={formik.handleChange}
                  value={formik.values.sort}
                  defaultValue=""
                  label="Sort"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>Ascending</ListSubheader>
                  <MenuItem value={"user"}>Client ID</MenuItem>
                  <MenuItem value={"coupon_code"}>Coupon code</MenuItem>
                  <MenuItem value={"percentage"}>Offer Value</MenuItem>
                  <MenuItem value={"valid"}>Status</MenuItem>

                  <ListSubheader>Descending</ListSubheader>
                  <MenuItem value={"-user"}>Client ID</MenuItem>
                  <MenuItem value={"-coupon_code"}>Coupon code</MenuItem>
                  <MenuItem value={"-percentage"}>Offer Value</MenuItem>
                  <MenuItem value={"-valid"}>Status</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <CustomButton
              label={
                <>
                  <Cached className="me-2" /> Reset
                </>
              }
              btnType="secondary"
              btnClass="primButton"
              onClick={handelReset}
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <CustomButton type="submit" label="Submit" />
          </div>
        </div>
      </form>
    </CustomDialog>
  );
}

export default SearchCoupon;
