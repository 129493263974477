import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import * as yup from "yup";
import {
  useUpdateKpiReportMutation,
  useUploadKpiReportMutation,
} from "../../../services/reports/reportsApi";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const AddKpiReport = ({ data, type, handleClose }) => {
  const [report, setReport] = useState(null);

  const [uploadReport, { isLoading }] = useUploadKpiReportMutation();
  const [updateReport, { isLoading: isUpadating }] =
    useUpdateKpiReportMutation();

  const [fileInputErrors, setFileInputErrors] = useState({});

  const { id } = useParams();

  const formik = useFormik({
    initialValues: data || {
      report_name: "",
      date: new Date().toISOString().substring(0, 10),
    },
    validationSchema: yup.object({
      report_name: yup
        .string()
        .min(3, "Too short!")
        .required("Report name is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (type === "new" && !report)
        return toast.error("Please Select report before submit!");

      if (fileInputErrors?.report) {
        return;
      }

      if (type === "new") {
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }
        formData.append("userId", id);

        try {
          const upload = await uploadReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report uploaded successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to upload report!");
        }
      } else if (type === "edit") {
        const formData = new FormData();
        for (const key in values) {
          if (key === "report") {
            typeof values[key] === "object" &&
              formData.append("report", values[key]);
          } else {
            formData.append(key, values[key]);
          }
        }

        try {
          const update = await updateReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update report!");
        }
      }
    },
  });

  const handleReport = (e) => {
    const selectedReport = e.target.files[0];
    const { name, files } = e.target;
    if (files[0]) {
      if (files[0].type !== "application/pdf") {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid file format. Only PDF files are allowed.",
        }));
      } else if (files[0].size > 5 * 1024 * 1024) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 5MB.",
        }));
      } else {
        setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else {
      setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    formik.setFieldValue("report", selectedReport);
    return setReport(selectedReport);
  };

  return (
    <div>
      <form className="p-4" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="Title"
              value={formik.values.report_name}
              name="report_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="upld_report" className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report ? report.name : "Upload your report"}</p>
              </div>
              <input
                onChange={handleReport}
                type="file"
                id="upld_report"
                accept="application/pdf"
                hidden
                multiple
                name="report"
              />
              {fileInputErrors.report && (
                <div className="errMsgs">{fileInputErrors.report}</div>
              )}
            </label>
          </div>
          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr d-flex align-items-center justify-content-end gap-2">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpadating}
                type="submit"
                label={isLoading || isUpadating ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddKpiReport;
