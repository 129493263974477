import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Login/Login.css";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { toast } from "react-hot-toast";
import InvitePeople from "./InvitePeople";

import TermsAndConditions from "./TermsAndConditions";
import moment from "moment/moment";
import TeamManagerForm from "./forms/TeamManagerForm";
import SportPersonForm from "./forms/SportPersonForm";
import DoctorForm from "./forms/DoctorForm";
import ProfessionalForm from "./forms/ProfessionalForm";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import "react-phone-input-2/lib/style.css";

import CustomRadio from "../../Components/Atoms/CustomRadio";

import {
  useGetDoctorsListQuery,
  useGetProfessionalsListQuery,
  useGetTeamManagersListQuery,
} from "../../services/Users/usersApi";
import { useGetMotivesListQuery } from "../../services/settings/motivesApi";
import { useSignupUserMutation } from "../../services/auth/authApi";
import { useGetSportCategoryListQuery } from "../../services/settings/sportCategoryApi";

const Signup = () => {
  const navigate = useNavigate();
  const [inviteDialog, setInviteDialog] = useState(false);
  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const [signupUser, { isLoading }] = useSignupUserMutation();
  const { data: motive_list } = useGetMotivesListQuery();
  const { data: professional_list } = useGetProfessionalsListQuery();
  const { data: manager_list } = useGetTeamManagersListQuery();
  const { data: doctor_list } = useGetDoctorsListQuery();
  const { data: sport_categeory } = useGetSportCategoryListQuery();

  const [selectedFile, setSelectedFile] = useState(null);
  const [termsModal, setTermsModal] = useState(false);

  const handleFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const validationArray = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is a required field")

      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    last_name: Yup.string()
      .required("Last Name is a required field")

      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .min(3, "Minimum 3 characters required"),
    dob: Yup.date()
      .typeError("INVALID_DATE")
      .min(moment().subtract(120, "years"), "Invalid DOB")
      .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
      .required("DOB is a required field"),
    email: Yup.string().email().required("Email is a required field"),
    mobile_no: Yup.string()
      .min(10, "Too Short")
      .required("Phone No is a required field"),
    password: Yup.string()
      .min(2, "Too Short!")
      .required("Password is a required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),

    height: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(10)
        .max(350)
        .required("Height is a required field"),
    }),
    weight: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(5)
        .max(650)
        .required("Weight is a required field"),
    }),
    sport_categeory: Yup.string().when(["account_type", "account_subtype"], {
      is: (account_type, account_subtype) =>
        (account_type === "sport_person" &&
          account_subtype === "sport_person") ||
        account_type === "team_manager" ||
        account_type === "professional",
      then: Yup.string().required("Sports category is a required field"),
    }),
    conf_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm Password is a required field"),
    team_name: Yup.string().when(["account_type"], {
      is: (account_type) => account_type === "team_manager",
      then: Yup.string().required("Team name is a required field"),
    }),
    hospital_name: Yup.string().when(["account_type"], {
      is: (account_type) => account_type === "doctor",
      then: Yup.string().required("Hospital name is a required field"),
    }),
    address_1: Yup.string()
      .min(3, "Minimum 3 characters required")

      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
        "Invalid address"
      ),
    btw_no: Yup.string()
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[A-Z0-9]+$/, "Invalid Format")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+]+)$/,
        "Numbers only not allowed"
      )
      .min(3, "BTW/VAT number must be at least 3 characters")
      .max(15, "BTW/VAT number must be at most 15 characters"),
    rememberme: 0,
  });

  const formik = useFormik({
    initialValues: {
      account_type: "sport_person",
      account_subtype: "sport_person",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      height: "",
      weight: "",
      sport_categeory: "",
      professional: "",
      team_manager: "",
      doctor: "",
      address_1: "",
      address_2: "",
      motive: "",
      team_name: "",
      council_name: "",
      no_of_persons: "",
      specialization: "",
      hospital_name: "",
      referer_code: "",
      conf_password: "",
      password: "",
      agreeTerms: false,
      btw_no: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      if (!values.agreeTerms)
        return toast.error("Agree to the Terms and Condition.");
      if (values.account_type !== "sport_person") {
        values.account_subtype = "";
      }

      const formValues = new FormData();
      for (const key in values) {
        formValues.append(key, values[key]);
      }

      if (values.account_type === "sport_person" && selectedFile !== null) {
        const report = selectedFile;
        const report_name = report.name.replace(/\.[^/.]+$/, "");
        const report_date = new Date().toISOString().slice(0, 10);

        formValues.append("userreport[0]report", report);
        formValues.append("userreport[0]report_name", report_name);
        formValues.append("userreport[0]date", report_date);
      }

      try {
        const res = await signupUser(formValues)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Account has been successfully created.");
              actions.resetForm();
              navigate("/registerSuccess");
            }
          });
        return res;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else if (error.data.email) {
          toast.error(error.data.email);
        } else {
          toast.error("Something went wrong");
        }
        console.error("rejected", error);
      }
    },
  });

  const getFormByType = () => {
    switch (formik.values.account_type) {
      case "sport_person":
        return (
          <>
            <SportPersonForm
              formik={formik}
              country_list={country_api.getAllCountries()}
              state_list={state_api.getStatesOfCountry(formik.values.country)}
              city_list={city_api.getCitiesOfState(
                formik.values.country,
                formik.values.state
              )}
              professional_list={professional_list}
              manager_list={manager_list}
              motive_list={motive_list}
              doctor_list={doctor_list}
              includesRefferal
              county={county}
              state={state}
              city={city}
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              sport_categeory={sport_categeory}
            />
          </>
        );
      case "professional":
        return (
          <ProfessionalForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            includesRefferal
            sport_categeory={sport_categeory}
          />
        );
      case "team_manager":
        return (
          <TeamManagerForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            includesRefferal
            sport_categeory={sport_categeory}
          />
        );
      case "doctor":
        return (
          <DoctorForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            includesRefferal
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AuthLayout showButton={true}>
        <div className="lgnFormContainer regWrapper">
          <form onSubmit={formik.handleSubmit}>
            <div className="frmHdr">
              <h2>Register</h2>
              <p>Welcome! Please register to continue</p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-2">Account Type</label>
                <div className="cmRdIptWrpr mb-4">
                  <div className="radIpt">
                    <input
                      checked={
                        formik.values.account_type === "sport_person"
                          ? true
                          : false
                      }
                      id="sport_person"
                      type="radio"
                      name="account_type"
                      onChange={formik.handleChange}
                      value="sport_person"
                    />
                    <label htmlFor="sport_person">
                      <img src="/assets/svg/user.svg" alt="user" />
                      User
                    </label>
                  </div>
                  <div className="radIpt">
                    <input
                      checked={
                        formik.values.account_type === "professional"
                          ? true
                          : false
                      }
                      type="radio"
                      id="professional"
                      name="account_type"
                      onChange={formik.handleChange}
                      value="professional"
                    />
                    <label htmlFor="professional">
                      <img
                        src="/assets/svg/professional.svg"
                        alt="professional"
                      />
                      Professional
                    </label>
                  </div>
                  <div className="radIpt">
                    <input
                      checked={
                        formik.values.account_type === "team_manager"
                          ? true
                          : false
                      }
                      id="team_manager"
                      type="radio"
                      name="account_type"
                      onChange={formik.handleChange}
                      value="team_manager"
                    />
                    <label htmlFor="team_manager">
                      <img src="/assets/svg/manager.svg" alt="manager" />
                      Team Manager
                    </label>
                  </div>
                  <div className="radIpt">
                    <input
                      checked={
                        formik.values.account_type === "doctor" ? true : false
                      }
                      id="doctor"
                      type="radio"
                      onChange={formik.handleChange}
                      name="account_type"
                      value="doctor"
                    />
                    <label htmlFor="doctor">
                      <img src="/assets/svg/doctor.svg" alt="doctor" />
                      Doctor
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={
                  formik.values.account_type === "sport_person"
                    ? "col-md-12"
                    : "d-none"
                }
              >
                <CustomRadio
                  label="User Type"
                  name="account_subtype"
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  value={formik.values.account_subtype}
                  options={[
                    { label: "Sport Person", value: "sport_person" },
                    { label: "Patient", value: "patient" },
                  ]}
                />
              </div>
              {getFormByType()}
              {/* <div
                className={
                  formik.values.account_type === "sport_person"
                    ? "col-md-12"
                    : "d-none"
                }
              >
                <label htmlFor="upld_sp_report" className="d-flex flex-column">
                  Uploads
                  <div className="upldWrpr text-center w-100">
                    <span className="material-symbols-rounded">
                      cloud_upload
                    </span>
                    <p>
                      {selectedFile ? selectedFile.name : "Upload your report"}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={handleFile}
                    accept="application/pdf"
                    id="upld_sp_report"
                    hidden
                  />
                </label>
              </div> */}

              <div className="col-md-12 chkFrgSctn d-flex align-items-center justify-content-between mt-3">
                <CustomCheckBox
                  checked={formik.values.agreeTerms}
                  name="agreeTerms"
                  onChange={formik.handleChange}
                  label={
                    <>
                      I agree to the{" "}
                      <Link to="" onClick={() => setTermsModal(true)}>
                        Terms & Condition
                      </Link>
                    </>
                  }
                />
                {/* {formik.values.account_type !== "sport_person" && (
                  <Button
                    className="brdrBtn"
                    onClick={() => setInviteDialog(true)}
                    disabled={isLoading}
                  >
                    <span className="material-symbols-rounded">group_add</span>
                    Invite People
                  </Button>
                )} */}
              </div>
              <div className="col-md-12 text-center">
                <CustomButton
                  label={isLoading ? "Submitting..." : "Get Started"}
                  type="submit"
                  disabled={isLoading}
                />
                <p className="mt-3 rdrTxt">
                  Already have an account? <Link to="/login">Login here</Link>
                </p>
              </div>
            </div>
          </form>
          <p className="text-danger">
            {console.log("formik.errors", formik.errors)}
          </p>
        </div>
      </AuthLayout>

      <InvitePeople
        inviteDialog={inviteDialog}
        setInviteDialog={setInviteDialog}
      />
      <TermsAndConditions showModal={termsModal} setModal={setTermsModal} />
    </>
  );
};

export default Signup;
