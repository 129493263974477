import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, CircularProgress } from "@mui/material";
import { baseUrl } from "../../services/api/api";
import SpKpiReport from "../SportsComponents/SpKpiReport";
import SpGutReport from "../SportsComponents/SpGutReport";
import SpMicroReport from "../SportsComponents/SpMicroReport";
import SpToleranceReport from "../SportsComponents/SpToleranceReport";
import SpInnerHackReport from "../SportsComponents/SpInnerHackReport";
import SpUploadedReport from "../SportsComponents/SpUploadedReport";
import SpProfile from "../SportsComponents/SpProfile";
import SpFollowkitReport from "../SportsComponents/SpFollowkitReport";
import { useGetUserByIdQuery } from "../../services/Users/usersApi";
import SpPurchasedProducts from "../SportsComponents/SpPurchasedProducts";
import SpTransactions from "../SportsComponents/SpTransactions";
import { useGetSportCategoryByIdQuery } from "../../services/settings/sportCategoryApi";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SportPersonView = () => {
  const [value, setValue] = React.useState(0);
  const { user } = useSelector((state) => state.auth);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();

  const { data: sport_person, isLoading } = useGetUserByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const { data: user_sport_category } = useGetSportCategoryByIdQuery(
    sport_person?.sport_categeory,
    { skip: !sport_person?.sport_categeory }
  );

  if (isLoading)
    return (
      <div className="d-flex justify-content-center mt-4">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard">
              <div className="mnDashboardView">
                <div className="spPrsTop d-flex">
                  <div className="usrPrflInfo">
                    {user?.department?.department_name !== "lab" ? (
                      <>
                        <div className="usrImCntnr">
                          <Avatar
                            src={
                              !!sport_person?.profile_image
                                ? baseUrl + sport_person.profile_image
                                : ""
                            }
                          />
                        </div>
                        <div className="usrDtlTxt">
                          <h4>
                            {sport_person?.first_name} {sport_person?.last_name}
                          </h4>
                          {window.location.pathname.includes("/patients/") ? (
                            <p>Client Id: {sport_person?.ref_no || "-"}</p>
                          ) : (
                            <p>
                              {user_sport_category?.sport_name || "-"} | Client
                              Id: {sport_person?.ref_no || "-"}
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="usrDtlTxt">
                        <p>Client Id: {sport_person?.id || "-"}</p>
                      </div>
                    )}
                  </div>
                  {/* <div className="usrStatInfo d-flex flex-column justify-content-between">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="usrTmLnks">
                        <p>
                          Professional: <span>Mark Jerry</span>
                        </p>
                      </div>
                      <div className="usrTmLnks">
                        <p>
                          Team Manager: <span>Robin Hood</span>
                        </p>
                      </div>
                    </div>
                    <div className="statInfoWrp d-flex align-items-center justify-content-between">
                      <div className="stIfCd">
                        <img src="/assets/svg/age.svg" />
                        <div>
                          <label>Age</label>
                          <p>{getSportPersonsAge(sport_person?.dob) || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/height.svg" />
                        <div>
                          <label>Height</label>
                          <p>{sport_person?.height || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/gender.svg" />
                        <div>
                          <label>Gender</label>
                          <p>{sport_person?.gender || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/weight.svg" />
                        <div>
                          <label>Weight</label>
                          <p>{sport_person?.weight || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/urine.svg" />
                        <div className="urnRslt">
                          <label>Urine Test Result</label>
                          <p>Dehydrated</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/stool.svg" />
                        <div className="stlRslt">
                          <label>Stool Test Result</label>
                          <p>Normal</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="mt-4">
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      {user?.department?.department_name === "lab" ? (
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          variant="scrollable"
                        >
                          <Tab label="KPI Report" {...a11yProps(0)} />
                          <Tab label="Gutbiomics Report" {...a11yProps(1)} />
                          <Tab label="Mircobiome Report" {...a11yProps(2)} />
                          <Tab label="Tolerance Report" {...a11yProps(3)} />
                          <Tab label="Innerselfie Hacks" {...a11yProps(4)} />
                          {/* <Tab label="Follow Kit Reports" {...a11yProps(5)} />
                          <Tab label="Uploaded Reports" {...a11yProps(6)} /> */}
                        </Tabs>
                      ) : (
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          variant="scrollable"
                        >
                          <Tab label="KPI Report" {...a11yProps(0)} />
                          <Tab label="Gutbiomics Report" {...a11yProps(1)} />
                          <Tab label="Mircobiome Report" {...a11yProps(2)} />
                          <Tab label="Tolerance Report" {...a11yProps(3)} />
                          <Tab label="Innerselfie Hacks" {...a11yProps(4)} />
                          <Tab label="Follow Kit Reports" {...a11yProps(5)} />
                          <Tab label="Uploaded Reports" {...a11yProps(6)} />
                          <Tab label="Products Purchased" {...a11yProps(7)} />
                          <Tab label="Transactions" {...a11yProps(8)} />
                          <Tab label="User Information" {...a11yProps(9)} />
                        </Tabs>
                      )}
                    </Box>
                    <TabPanel value={value} index={0}>
                      <SpKpiReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <SpGutReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <SpMicroReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <SpToleranceReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <SpInnerHackReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <SpFollowkitReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <SpUploadedReport user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <SpPurchasedProducts user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                      <SpTransactions user={sport_person} />
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                      {value === 9 && <SpProfile />}
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SportPersonView;
