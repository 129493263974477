import { appApi } from "../api/api";

export const SpExcelReport = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersSpExcel: builder.mutation({
      query: (value, id) => ({
        url: `/api/admin/export_sp/${value?.id}`,
        method: "POST",
        body: value,
      }),
    }),
  }),
});

export const { useGetUsersSpExcelMutation } = SpExcelReport;
