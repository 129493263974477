import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const getStoredToken = () => {
  const token = window.localStorage.getItem("x-access-token");
  return token ? token : null;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    TwoFactorCredentials: secureLocalStorage.getItem("2FA"),
    user: null,
    accessToken: getStoredToken(),
  },
  reducers: {
    setUserCredentials: (state, { payload }) => {
      state.user = payload;
    },
    logOut: (state, { payload }) => {
      window.localStorage.removeItem("x-access-token");
      state.user = null;
      state.accessToken = null;
    },
    setAccessToken: (state, { payload }) => {
      window.localStorage.setItem("x-access-token", payload);
      state.accessToken = payload;
    },
    set2FACredentials: (state, { payload }) => {
      state.TwoFactorCredentials = payload;
    },
  },
});

export const { logOut, setAccessToken, setUserCredentials, set2FACredentials } =
  authSlice.actions;

export default authSlice.reducer;
