import { appApi } from "../api/api";

export const couponApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCouponList: builder.query({
      query: () => `/api/admin/coupon`,
      providesTags: (result, error, arg) =>
        result
          ? ["Coupon", ...result.map(({ id }) => ({ type: "Coupon", id }))]
          : ["Coupon"],
    }),

    getCouponsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/coupon`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Coupon",
              ...result?.report?.map(({ id }) => ({
                type: "Coupon",
                id,
              })),
            ]
          : ["Coupon"],
    }),

    getCouponById: builder.query({
      query: (id) => `/api/admin/coupon/${id}`,
      providesTags: (result, error, arg) => [{ type: "Coupon", id: arg }],
    }),
    createCoupon: builder.mutation({
      query: (values) => ({
        url: `/api/admin/coupon/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Coupon" }],
    }),
    updateCoupon: builder.mutation({
      query: (values) => ({
        url: `/api/admin/coupon/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Coupon", id: arg.id }],
    }),
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `/api/admin/coupon/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Coupon" }],
    }),
  }),
});

export const {
  useGetCouponListQuery,
  useGetCouponByIdQuery,
  useCreateCouponMutation,
  useDeleteCouponMutation,
  useUpdateCouponMutation,
  useLazyGetCouponsBySearchQuery,
} = couponApi;
