import { appApi } from "../api/api";

export const ibanNumApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getIbanNumList: builder.query({
      query: () => `/api/admin/Iban`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "IbanNumber",
              ...result.map(({ id }) => ({ type: "IbanNumber", id })),
            ]
          : ["IbanNumber"],
    }),
    getIbanNumsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/Iban/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "IbanNumber",
              ...result?.report?.map(({ id }) => ({
                type: "IbanNumber",
                id,
              })),
            ]
          : ["IbanNumber"],
    }),
    getIbanNumsById: builder.query({
      query: (id) => `/api/admin/Iban/${id}`,
      providesTags: (result, error, arg) => [{ type: "IbanNumber", id: arg }],
    }),
    createIbanNums: builder.mutation({
      query: (values) => ({
        url: `/api/admin/Iban/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["IbanNumber"],
    }),
    updateIbanNums: builder.mutation({
      query: ({ id, ...values }) => ({
        url: `/api/admin/Iban/${id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "IbanNumber", id: arg.id },
      ],
    }),
    deleteIbanNums: builder.mutation({
      query: (id) => ({
        url: `/api/admin/Iban/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IbanNumber"],
    }),
  }),
});

export const {
  useGetIbanNumsByIdQuery,
  useGetIbanNumListQuery,
  useCreateIbanNumsMutation,
  useDeleteIbanNumsMutation,
  useUpdateIbanNumsMutation,
  useLazyGetIbanNumsBySearchQuery,
} = ibanNumApi;
