import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomEditor from "../../Components/Atoms/CustomEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";

import CustomSelect from "../../Components/Atoms/CustomSelect";
import { useGetSportsPersonsQuery } from "../../services/Users/usersApi";
import {
  useCreateProductMutation,
  useDeleteProductImageMutation,
  useGetProductByIdQuery,
  useGetProductCategoriesQuery,
  useUpdateProductMutation,
} from "../../services/products/ProductsApi";
import FileResizer from "react-image-file-resizer";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  product_name: yup
    .string()
    .min(3, "Too short!")
    .required("Product name is required"),
  description: yup.string().required("Product Description is required"),
  price: yup.number().required("Product Price is required"),
  available_qty: yup.number().required("Product Available Qty is required"),
  vat: yup.number().positive().min(0).max(100).required("Vat is required"),
  category: yup.string().required("Product Category is required"),
  product_type: yup.string(),
  user: yup.mixed().when(["product_type"], {
    is: (product_type) => product_type === "personalized",
    then: yup.mixed().required("Personalized user is a required field"),
  }),
});

const AddProduct = ({ type, data, handleClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const [createProduct, { isLoading }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdate }] = useUpdateProductMutation();

  const { data: sport_persons } = useGetSportsPersonsQuery(null, {
    refetchOnFocus: true,
  });

  const { data: category_list } = useGetProductCategoriesQuery();

  const [deleteProductImage, { isLoading: isDeletingImage }] =
    useDeleteProductImageMutation();

  const { data: product, isLoading: productLoading } = useGetProductByIdQuery(
    data?.id,
    {
      skip: !data?.id,
    }
  );

  const formik = useFormik({
    initialValues: {
      product_name: "",
      price: "",
      description: "",
      available_qty: "",
      category: "",
      product_type: "universal",
      user: "",
      vat: "",
      date: new Date().toISOString().slice(0, 10),
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (!selectedFiles.length && type === "new")
        return toast.error("Please upload images");

      const productimages = selectedFiles.map((image, idx) => {
        return { product_image: image };
      });

      if (type === "new") {
        try {
          const upload = await createProduct({
            ...values,
            user: values.user?.id || "",
            productimages,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Product created successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create Product!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateProduct({
            ...values,
            user: values.user?.id || "",
            productimages,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Product updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update product!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit" && product) {
      formik.setFieldValue("id", product?.id);
      formik.setFieldValue("product_name", product?.product_name);
      formik.setFieldValue("price", product?.price);
      formik.setFieldValue("description", product?.description);
      formik.setFieldValue("available_qty", product?.available_qty);
      formik.setFieldValue("category", product?.category);
      formik.setFieldValue("vat", product?.vat);
      formik.setFieldValue("product_type", product?.product_type);
      const user = sport_persons?.find((user) => user.id === product?.user);
      formik.setFieldValue("user", user);
    }
  }, [type, product, sport_persons]);

  const handleImage = (e) => {
    const files = e.target.files;

    const fileResizeHandler = (fileInput) => {
      if (fileInput) {
        try {
          FileResizer.imageFileResizer(
            fileInput,
            500,
            500,
            "JPEG",
            100,
            0,
            (uri) => {
              setSelectedFiles((draft) => [...draft, uri]);
              setImageUrls((draft) => [...draft, uri]);
            },
            "base64",
            200,
            200
          );
        } catch (err) {
          console.log(err);
        }
      }
    };

    for (const key in files) {
      if (files[key].type?.match("image.*")) {
        fileResizeHandler(files[key]);
      }
    }
  };

  const handleDeleteImage = (idx) => {
    return (
      setSelectedFiles(selectedFiles.filter((image, index) => index !== idx)),
      setImageUrls(imageUrls.filter((image, index) => index !== idx))
    );
  };

  const handleDirectDeleteImage = async (id) => {
    if (isDeletingImage) return;
    try {
      toast.loading("Deleteing Image");
      const deletImageFromProduct = await deleteProductImage({
        id,
        productId: product?.id,
      })
        .unwrap()
        .then(() => {
          toast.dismiss();
          toast.success("Image removed from product.");
        });
      return deletImageFromProduct;
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Failed to remove image from product");
    }
  };

  if (type === "edit" && productLoading)
    return (
      <div className="d-flex justify-content-center mt-4">
        <CircularProgress />
      </div>
    );

  return (
    <div className="inrHckForm">
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="Name"
              name="product_name"
              value={formik.values.product_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Price"
              name="price"
              type="number"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Available Qty"
              name="available_qty"
              type="number"
              value={formik.values.available_qty}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <CustomSelect
              label="Category"
              name="category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              formik={formik}
              options={
                category_list?.map((data) => {
                  return { value: data.id, name: data.pc_name };
                }) ?? []
              }
            />
          </div>
          <div className={"col-md-6"}>
            <CustomSelect
              label="Product Type"
              name="product_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.product_type}
              formik={formik}
              options={[
                {
                  value: "universal",
                  name: "universal",
                },
                {
                  value: "personalized",
                  name: "personalized",
                },
              ]}
            />
          </div>
          <div
            className={
              formik.values.product_type === "personalized"
                ? "col-md-12 mb-4"
                : "d-none"
            }
          >
            <div className="customInputWrpr">
              <Autocomplete
                disablePortal
                options={sport_persons ?? []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.first_name} ${option?.last_name} | ${
                        option?.account_subtype === "patient"
                          ? "Patient"
                          : "Sport Person"
                      } | ${option?.ref_no}`
                    : ""
                }
                value={formik.values.user}
                onChange={(_, newValue) => {
                  formik.setFieldValue("user", newValue ?? null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                onBlur={() => formik.setFieldTouched("user", true)}
                renderInput={(params) => (
                  <TextField
                    name="user"
                    {...params}
                    label="Enter Client Name or ID"
                  />
                )}
              />
              {formik.errors?.user ? (
                <p className="errMsg">{formik.errors?.user}</p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-md-6">
            <CustomInput
              label="Vat"
              name="vat"
              type="number"
              value={formik.values.vat}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>

          <div className="col-md-12 mt-2">
            <h6>Description</h6>
            <hr />
            {type === "edit" ? (
              <>
                <CustomEditor
                  data={formik.values.description}
                  onReady={({ editor }) => {
                    editor.setData(product?.description);
                  }}
                  onChange={({ editor }) => {
                    formik.setFieldValue("description", editor.getData());
                  }}
                  error={formik.errors.description && formik.errors.description}
                />
              </>
            ) : (
              type === "new" && (
                <>
                  <CustomEditor
                    data={formik.values.description}
                    onChange={({ editor }) => {
                      formik.setFieldValue("description", editor.getData());
                    }}
                    error={
                      formik.errors.description && formik.errors.description
                    }
                  />
                </>
              )
            )}
          </div>

          {!!product?.productimages?.length && (
            <div className="col-md-12 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">Uploaded Images</h6>
              </div>
              <hr />
              <div className="prodimages">
                {!!product.productimages?.length &&
                  product.productimages.map(({ product_image, id }, idx) => (
                    <div className="prodUpldImg" key={idx}>
                      <img src={product_image} alt="" />
                      <IconButton
                        disabled={isDeletingImage}
                        onClick={() => handleDirectDeleteImage(id)}
                        className="delete_image"
                        style={{ backgroundColor: "rgba(255,255,255,0.2)" }}
                      >
                        <span className="material-symbols-rounded">delete</span>
                      </IconButton>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="col-md-12 mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Images</h6>
              <label>
                <div className="upldBtn text-center d-flex ">
                  <span className="material-symbols-rounded">cloud_upload</span>
                  <p>Upload Image</p>
                </div>
                <input
                  onChange={(e) => handleImage(e)}
                  type="file"
                  accept="image/*"
                  hidden
                  multiple
                />
              </label>
            </div>
            <hr />
            <div className="prodimages">
              {!!imageUrls.length &&
                imageUrls.map((image, idx) => (
                  <div className="prodUpldImg" key={idx}>
                    <img src={image} />
                    <IconButton
                      onClick={() => handleDeleteImage(idx)}
                      className="delete_image"
                    >
                      <span className="material-symbols-rounded">delete</span>
                    </IconButton>
                  </div>
                ))}
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr gap-2 d-flex align-items-center justify-content-end">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpdate}
                type="submit"
                label={isLoading || isUpdate ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
