import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useCreateIbanNumsMutation,
  useGetIbanNumsByIdQuery,
  useUpdateIbanNumsMutation,
} from "../../../services/settings/IbanNumApi";
import CustomCheckBox from "../../../Components/Atoms/CustomCheckBox";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .matches(/^[A-Z0-9]+$/, "Invalid Format")
    .matches(
      /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+]+)$/,
      "Numbers only not allowed"
    )
    .min(5, "Iban number must be at least 5 characters")
    .max(35, "Iban number must be at most 35 characters")
    .required("Iban number is required"),
});

const AddIbanNum = ({ type, data, mount, handleClose }) => {
  const [createIbanNumber, { isLoading }] = useCreateIbanNumsMutation();
  const [updatecreateIbanNumber, { isLoading: isUpdate }] =
    useUpdateIbanNumsMutation();

  const { data: selectedIbanNumber } = useGetIbanNumsByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      default: true,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await createIbanNumber(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("IBAN Number created successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create IBAN Number!");
        }
      } else if (type === "edit") {
        try {
          const update = await updatecreateIbanNumber(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("IBAN number updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update Iban number!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("name", selectedIbanNumber?.name);
      formik.setFieldValue("default", selectedIbanNumber?.default);
      formik.setFieldValue("id", selectedIbanNumber?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedIbanNumber]);

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <div className="circle_back mb-2">
          <span className="material-symbols-rounded">account_balance</span>
        </div>
        <h4 className="mb-2">
          {type !== "new" ? `Update: ${data?.name}` : "Add IBAN Number"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Iban Number"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>
            <div className="col-md-12 mb-3">
              <CustomCheckBox
                name="default"
                label="Default"
                onChange={formik.handleChange}
                checked={formik.values.default === true}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddIbanNum;
