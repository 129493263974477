import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import {
  useGetDoctorsListQuery,
  useGetProfessionalsListQuery,
  useGetTeamManagersListQuery,
  useGetUserByIdQuery,
  useUpdateUserProfileMutation,
} from "../../../services/Users/usersApi";
import { assetUrlBuilder } from "../../../Utils/Utils";
import SportPersonForm from "../../Signup/forms/SportPersonForm";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import moment from "moment";
import { Autocomplete, Avatar, IconButton, TextField } from "@mui/material";
import { useGetMotivesListQuery } from "../../../services/settings/motivesApi";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import { useGetSportCategoryListQuery } from "../../../services/settings/sportCategoryApi";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { setUserCredentials } from "../../../features/auth/authSlice";
import { useDeleteProfileImageMutation } from "../../../services/auth/authApi";
import { useDispatch } from "react-redux";

const UserProfile = () => {
  const navigate = useNavigate();
  const { dispatch } = useDispatch();
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [mobile, setMobile] = useState(null);

  const [city, setCity] = useState(null);
  const [editProfile, setEditProfile] = useState(false);

  const { data: user, refetch } = useGetUserByIdQuery(id, {
    refetchOnFocus: true,
  });

  const { data: motive_list } = useGetMotivesListQuery();
  const { data: professional_list } = useGetProfessionalsListQuery();
  const { data: manager_list } = useGetTeamManagersListQuery();
  const { data: doctor_list } = useGetDoctorsListQuery();
  const { data: sport_categeory } = useGetSportCategoryListQuery();
  // console.log(user, "kigi");
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();

  const validationArray = Yup.object().shape({
    first_name: Yup.string()

      .required("First Name is a required field")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .min(3, "Minimum 3 characters required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    last_name: Yup.string()

      .required("First Name is a required field")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .min(3, "Minimum 3 characters required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    dob: Yup.date()
      .typeError("INVALID_DATE")
      .min(moment().subtract(120, "years"), "Invalid DOB")
      .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
      .required("DOB is a required field"),
    email: Yup.string().email().required("Email is a required field"),
    gender: Yup.string().required("Gender is a required field"),
    address_1: Yup.string()
      .min(3, "Minimum 3 characters required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .trim()
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
        "Invalid address"
      ),

    zipcode: Yup.string()
      .required("Zipcode is a required field")
      .max(6, "Too Long!"),
    city: Yup.string().required("City is a required field"),

    mobile_no: Yup.number().required("Phone No is a required field"),

    height: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(10)
        .max(350)
        .required("Height is a required field"),
    }),
    weight: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(5)
        .max(650)
        .required("Weight is a required field"),
    }),

    btw_no: Yup.string()
      .nullable()
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[A-Z0-9]+$/, "Invalid Format")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+]+)$/,
        "Numbers only not allowed"
      )
      .min(3, "BTW/VAT number must be at least 3 characters")
      .max(15, "BTW/VAT number must be at most 15 characters"),
  });

  const formik = useFormik({
    initialValues: user || {
      account_type: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      height: "",
      weight: "",
      professional: "",
      team_manager: "",
      motive: "",
      team_name: "",
      council_name: "",
      no_of_persons: "",
      specialization: "",
      hospital_name: "",
      referer_code: "",
      btw_no: 0,
    },
    enableReinitialize: true,
    validationSchema: validationArray,

    onSubmit: async (values, actions) => {
      const formValues = new FormData();
      for (const key in values) {
        if (
          key !== "uploads" &&
          key !== "profile_image" &&
          key !== "sport_categeory"
        ) {
          formValues.append(key, values[key]);
        }
      }
      if (selectedFile !== null) {
        formValues.append("profile_image", selectedFile);
      }
      if (values.sport_categeory) {
        formValues.append("sport_categeory", values.sport_categeory);
      }
      await updateUserProfile(formValues)
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Account has been updated successfully.");
            setEditProfile(false);
            refetch();
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  const handleProfile = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    setProfileUrl(blob);
    return (e.target.value = "");
  };

  useEffect(() => {
    if (formik.values.country || "") {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      city_api
        .getCitiesOfState(formik.values.country, formik.values.state)
        .filter((el) => el.name === formik.values.city && setCity(el));
    }
  }, [formik.values.country, formik.values.state, formik.values.city]);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          {/* <div className="pt-2 pb-4">
            <h5 className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2">
                account_circle
              </span>
              Profile
            </h5>
            <hr />
          </div> */}
          <div className="row pt-4">
            <div className="col-md-8 order-2 order-md-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <SportPersonForm
                    formik={formik}
                    country_list={country_api.getAllCountries()}
                    state_list={state_api.getStatesOfCountry(
                      formik.values.country
                    )}
                    city_list={city_api.getCitiesOfState(
                      formik.values.country,
                      formik.values.state
                    )}
                    professional_list={professional_list}
                    manager_list={manager_list}
                    motive_list={motive_list}
                    county={county}
                    state={state}
                    city={city}
                    mobile_no={mobile}
                    setMobile={setMobile}
                    setCountry={setCountry}
                    setState={setState}
                    setCity={setCity}
                    // readOnly={true}
                    avoidPassword
                    sport_categeory={sport_categeory}
                  />
                  <div className="col-md-12 my-3 d-flex">
                    <div className="hdrTagWrpr flex-fill">
                      <h4>Professionals</h4>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <CustomSelect
                      label="Select Your Professional (If applicable)"
                      name="professional"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.professional}
                      formik={formik}
                      options={
                        professional_list?.map((el) => {
                          return {
                            value: `${el?.id}`,
                            name: `${el?.first_name} ${el.last_name}`,
                          };
                        }) || []
                      }
                      readOnly={!editProfile}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomSelect
                      label="Select Team Manager (If applicable)"
                      name="team_manager"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.team_manager}
                      formik={formik}
                      options={
                        manager_list?.map((el) => {
                          return {
                            value: `${el?.id}`,
                            name: `${el?.first_name} ${el.last_name}`,
                          };
                        }) ?? []
                      }
                      readOnly={!editProfile}
                    />
                  </div>

                  <div className="col-md-6">
                    <CustomSelect
                      label="Select Doctor (If applicable)"
                      name="doctor"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.doctor}
                      formik={formik}
                      options={
                        doctor_list?.map((el) => {
                          return {
                            value: `${el?.id}`,
                            name: `${el?.first_name} ${el.last_name}`,
                          };
                        }) ?? []
                      }
                      readOnly={!editProfile}
                    />
                  </div>

                  {!editProfile && (
                    <div className="col-md-12">
                      <CustomButton
                        label="Edit"
                        disabled={isLoading}
                        onClick={() => setEditProfile(true)}
                      />
                    </div>
                  )}
                  {editProfile && (
                    <div className="col-md-12">
                      <CustomButton
                        type="submit"
                        label={isLoading ? "Submitting.." : "Submit"}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="col-md-4 order-1 order-md-2">
              <div className="usrImgWrpr">
                {editProfile && (
                  <div className="profile_actions">
                    <IconButton
                      className="profile_action_btn"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        type="file"
                        onChange={handleProfile}
                        accept="image/*"
                        hidden
                      />
                      <PhotoCamera />
                    </IconButton>
                  </div>
                )}

                <Avatar
                  src={profileUrl || assetUrlBuilder(user?.profile_image)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
