import React, { useEffect, useMemo, useState } from "react";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import {
  useGetOrderedProductsQuery,
  useLazyGetNewOrderedBySearchQuery,
} from "../../services/products/ProductsApi";
import { currencyFomatter, handleSort } from "../../Utils/Utils";
import "./product.css";
import ShipmentForm from "./ShipmentForm";
import ViewOrder from "./ViewOrder";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

import {
  LocalShippingOutlined,
  OpenInNew,
  PaidOutlined,
  Tune,
} from "@mui/icons-material";
import CustomDropdown from "../../Components/Molecules/CustomDropdown";
import TransactionForm from "./TransactionForm";
import ExportComponent from "../../Components/Molecules/ExportComponent";
import moment from "moment";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSearchValue } from "../../features/search/searchSlice";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import { Badge, TablePagination } from "@mui/material";
import Export from "../../Components/Molecules/Export";
import CustomButton from "../../Components/Atoms/CustomButton";
import SearchOrders from "./SearchOrders";
import { Link } from "react-router-dom";

const NewOrders = () => {
  const dispatch = useDispatch();
  //search handlers

  const [
    getNewOrderssBySearch,
    { data: list, isFetching, isLoading, isError },
  ] = useLazyGetNewOrderedBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { new_orders_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
        status: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
      from_date: "",
      to_date: "",
    },
    validationSchema: yup.object({
      filter: yup.object({
        date1: yup.date().max(new Date(), "Start date can't be after today"),
        date2: yup
          .date()
          .when(
            "date1",
            (start_date, schema) =>
              start_date &&
              schema.min(start_date, "End date can't be before start date")
          )
          .max(new Date(), "End date can't be after today"),
      }),
    }),
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      if (filterModal) setFilterModal(false);

      dispatch(addSearchValue({ name: "new_orders_search", values }));

      const notify = toast.loading("Getting Orders..");
      try {
        const query = await getNewOrderssBySearch(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getNewOrderssBySearch(previousFilter);
      } else {
        await getNewOrderssBySearch(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);

    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);

    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.status.length && count++;
    searchFormik.values.filter.date1.length && count++;
    searchFormik.values.filter.date2.length && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  const [addShipmentPopup, setAddShipmentPopup] = useState({
    mount: false,
    data: null,
  });

  const [addTranPopup, setAddTranPopup] = useState({
    mount: false,
    data: null,
  });

  const handlePopupClose = () => setAddPopup({ mount: false, data: null });
  const handleShipPopupClose = () => {
    return setAddShipmentPopup({ mount: false, data: null });
  };
  const handleTranPopupClose = () => {
    return setAddTranPopup({ mount: false, data: null });
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Order Id", sortable: false },
    { name: "Order By", sortable: false },

    { name: "Client Id", sortable: false },
    { name: "Ordered Items", sortable: false },
    { name: "Total Amount", key: "total_price", sortable: true },
    { name: "Date", sortable: false },

    { name: "Payment Status", key: "payment_status", sortable: true },
    { name: "Action", sortable: false },
  ];
  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    order_play
                  </span>
                  New Orders
                </h3>
                <div className="d-flex flex-fill gap-2 flex-wrap">
                  <CustomSearch
                    inputName="search"
                    query={searchFormik.values.search}
                    handleChange={searchFormik.handleChange}
                    handleSubmit={(e) => {
                      e.preventDefault();
                      searchFormik.handleSubmit();
                      searchFormik.setFieldValue("page", 1);
                    }}
                    containerClasses="ms-md-auto small"
                    disabled={isFetching || isLoading}
                  />

                  <Badge badgeContent={handelBageContent} color="secondary">
                    <CustomButton
                      size="small"
                      btnType="secondary"
                      btnClass="exprtBtn"
                      onClick={() => setFilterModal(true)}
                      label={
                        <>
                          <Tune className="me-2" />
                          Filter
                        </>
                      }
                    />
                  </Badge>
                  <Export
                    type="NewOrders"
                    size="small"
                    values={{ ...searchFormik.values, model: "orders" }}
                  />
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th
                          style={{ width: name === "Name" ? "30%" : "auto" }}
                          key={idx}
                        >
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report?.length &&
                      list?.report?.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>{data.id}</td>
                          <td>{data.ordered_by}</td>
                          <td>
                            {data.receiver_user_acctype === "sport_person" &&
                            data.receiver_user_acc_subtype ===
                              "sport_person" ? (
                              <Link
                                to={`/users/sport-persons/view/${data.rc_id}`}
                              >
                                {data.client_id}
                              </Link>
                            ) : (
                              <Link to={`/users/patients/view/${data.rc_id}`}>
                                {data.client_id}
                              </Link>
                            )}
                          </td>
                          {/* <td>{data.client_id}</td> */}
                          <td>{data.ordered_items}</td>

                          <td>{currencyFomatter(data.total)}</td>
                          <td>{data.date}</td>

                          <td>
                            <span
                              className={`status ${
                                data.payment_status === "unpaid"
                                  ? "pending"
                                  : "accepted"
                              }`}
                            >
                              {data.payment_status}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <CustomDropdown
                                menuItems={[
                                  {
                                    label: (
                                      <>
                                        <PaidOutlined />
                                        Payment Approval
                                      </>
                                    ),
                                    handleMenuClick: () => {
                                      setAddTranPopup({
                                        mount: true,
                                        data: data,
                                      });
                                    },
                                    hidden: data.payment_status === "paid",
                                  },
                                  {
                                    label: (
                                      <>
                                        <LocalShippingOutlined />
                                        Shipment
                                      </>
                                    ),
                                    handleMenuClick: () => {
                                      setAddShipmentPopup({
                                        mount: true,
                                        data: data,
                                      });
                                    },
                                    hidden: data.order_status === "closed",
                                  },
                                  {
                                    label: (
                                      <>
                                        <OpenInNew />
                                        View
                                      </>
                                    ),
                                    handleMenuClick: () => {
                                      setAddPopup({
                                        mount: true,
                                        data: data,
                                      });
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addPopup.mount && (
        <CustomDialog
          avoidBG
          open={addPopup.mount}
          handleClose={handlePopupClose}
        >
          <ViewOrder {...addPopup} handleClose={handlePopupClose} />
        </CustomDialog>
      )}
      {addShipmentPopup.mount && (
        <CustomDialog
          open={addShipmentPopup.mount}
          handleClose={handleShipPopupClose}
        >
          <ShipmentForm
            {...addShipmentPopup}
            handleClose={handleShipPopupClose}
          />
        </CustomDialog>
      )}
      {addTranPopup.mount && (
        <CustomDialog
          open={addTranPopup.mount}
          handleClose={handleTranPopupClose}
        >
          <TransactionForm
            {...addTranPopup}
            handleClose={handleTranPopupClose}
          />
        </CustomDialog>
      )}

      {filterModal && (
        <SearchOrders
          formik={searchFormik}
          open={filterModal}
          handleSubmit={() => getNewOrderssBySearch(searchFormik.values)}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default NewOrders;
