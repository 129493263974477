import { appApi } from "../api/api";

export const emailApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailList: builder.query({
      query: () => `/api/admin/email_templates`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "Email_Templates",
              ...result.map(({ id }) => ({ type: "Email_Templates", id })),
            ]
          : ["Email_Templates"],
    }),
    getEmailsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/email_templates`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Email_Templates",
              ...result?.report?.map(({ id }) => ({
                type: "Email_Templates",
                id,
              })),
            ]
          : ["Email_Templates"],
    }),
    getEmailTemplateById: builder.query({
      query: (id) => `/api/admin/email_templates/${id}`,
      providesTags: (result, error, arg) => [
        { type: "Email_Templates", id: arg },
      ],
    }),
    uploadEmailTemplate: builder.mutation({
      query: (values) => ({
        url: `/api/admin/email_templates/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Email_Templates" }],
    }),
    updateEmailTemplate: builder.mutation({
      query: (values) => ({
        url: `/api/admin/email_templates/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Email_Templates", id: arg.id },
      ],
    }),
    deleteEmailTemplate: builder.mutation({
      query: (id) => ({
        url: `/api/admin/email_templates/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Email_Templates" }],
    }),
  }),
});

export const {
  useGetEmailListQuery,
  useUploadEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetEmailTemplateByIdQuery,
  useLazyGetEmailsBySearchQuery,
} = emailApi;
