import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { currencyFomatter, assetUrlBuilder } from "../../../Utils/Utils";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import { useGetUsersOrdersQuery } from "../../../services/products/ProductsApi";
import ViewOrder from "../../Orders/ViewOrder";
import { OpenInNew } from "@mui/icons-material";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";

const SpPurchasedProducts = () => {
  const { id } = useParams();
  const { data: products } = useGetUsersOrdersQuery(id, {
    refetchOnFocus: true,
  });

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  const handlePopupClose = () => setAddPopup({ mount: false, data: null });

  return (
    <>
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Order Price</th>
                <th>Payment Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!products?.length ? (
                products.map((el, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                        }}
                        className="border rounded shadow-sm"
                        src={
                          el.orderitems[0]?.product?.productimages[0]
                            ?.product_image
                        }
                      />
                    </td>
                    <td>{el.orderitems[0]?.product?.product_name || "-"}</td>
                    <td>{currencyFomatter(el.orderitems[0]?.price || 0)}</td>
                    <td>{el.orderitems[0]?.quantity || "-"}</td>
                    <td>{currencyFomatter(el?.total_price || 0)}</td>
                    <td>
                      {el?.payment_status === "unpaid" ? (
                        <span className="status pending">
                          {el?.payment_status}
                        </span>
                      ) : (
                        <span className="status accepted">
                          {el?.payment_status}
                        </span>
                      )}
                    </td>
                    <td>
                      <CustomDropdown
                        menuItems={[
                          {
                            label: (
                              <>
                                <OpenInNew />
                                View Invoice
                              </>
                            ),

                            handleMenuClick: () => {
                              return setAddPopup({
                                mount: true,
                                data: el,
                              });
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center text-muted">
                    No Products Listed
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {addPopup.mount && (
          <CustomDialog
            avoidBG
            open={addPopup.mount}
            handleClose={handlePopupClose}
          >
            <ViewOrder {...addPopup} handleClose={handlePopupClose} />
          </CustomDialog>
        )}
      </div>
    </>
  );
};

export default SpPurchasedProducts;
