import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../Views/Dashboard";
import Login from "../Views/Login";
import TwoFactor from "../Views/Login/TwoFactor";
import ForgotPassword from "../Views/Login/ForgotPassword";
import Signup from "../Views/Signup";
import RegisterSuccess from "../Views/Signup/RegisterSuccess";
import SportPersons from "../Views/SportPersons";
import SportPersonView from "../Views/SportPersons/SportPersonView";
import Profile from "../Views/Profile";
import Invite from "../Views/Invite";
import Followkits from "../Views/Followkits";
import Grouping from "../Views/Grouping";
import Layout from "../Components/Organisms/Layout";
import AuthRequired from "./AuthRequired";
import EBooksRecipes from "../Views/EbooksRecipes";
import Products from "../Views/Products";
import { useSelector } from "react-redux";
import Unauthorized from "../Views/ErrorScreens/Unauthorized";
import NotFound from "../Views/ErrorScreens/NotFound";
import TermsAndConditions from "../Views/Signup/TermsAndConditions";
import TeamManager from "../Views/Users/TeamManager";
import TeamManagerView from "../Views/Users/TeamManager/TeamManagerView";
import Professionals from "../Views/Users/Professionals";
import ProfessionalView from "../Views/Users/Professionals/ProfessionalView";
import Doctor from "../Views/Users/Doctor";
import DoctorView from "../Views/Users/Doctor/DoctorView";
import EmailTemplate from "../Views/EmailTemplate";
import Motives from "../Views/Settings/Motives";
import ProductCategory from "../Views/Settings/ProductCategory";
import EbookCategory from "../Views/Settings/EbookCategory";
import RecipeCategory from "../Views/Settings/RecipeCategory";
import StaticPages from "../Views/StaticPages";
import NewOrders from "../Views/Orders/NewOrders";
import PastOrders from "../Views/Orders/PastOrders";
import Transactions from "../Views/Users/Transactions";
import ClosedProducts from "../Views/Products/ClosedProducts";
import UsersApproval from "../Views/Users/UsersApproval";
import SportCategory from "../Views/Settings/SportCategory";
import ReportPricing from "../Views/Settings/ReportPricing";
import Departments from "../Views/Settings/Departments";
import Employees from "../Views/Users/Employees";
import EmployeeForm from "../Views/Users/Employees/EmployeeForm";
import AccessRoles from "./AccessRoles";
import Patients from "../Views/Patients";
import DeleteReports from "../Views/DeleteReports";
import FollowCheckout from "../Views/Checkout/FollowCheckout";
import BulkFollowkitForm from "../Views/Followkits/BulkFollowkitForm";
import Stripe from "../Components/Organisms/Stripe";
import Coupon from "../Views/Users/Coupon";
import DeactivatedUsers from "../Views/Users/DeactivatedUsers";
import RegisterUser from "../Views/Signup/RegisterUser";
import IBINNumber from "../Views/Settings/IBINnumber/IbinNumber";
import SampleExcel from "../Views/Settings/SampleExcel/SampleExcel";
// import BulkFollowkitForm from "../Views/Followkits/BulkFollowkitForm";

const Router = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/twofactor" element={<TwoFactor />} />
          <Route element={<AuthRequired />}>
            <Route element={<Layout />}>
              {/* admin and lab employees Routes */}
              <Route element={<AccessRoles allowedRoles={["admin", "lab"]} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/users/sport-persons" element={<SportPersons />} />
                <Route
                  path="/users/sport-persons/view/:id"
                  element={<SportPersonView />}
                />
                <Route path="/users/patients" element={<Patients />} />
                <Route
                  path="/users/patients/view/:id"
                  element={<SportPersonView />}
                />
                <Route path="/followkits" element={<Followkits />} />
                <Route
                  path="/followkits/bulkorder"
                  element={<BulkFollowkitForm />}
                />
                <Route path="/payment/:id" element={<Stripe />} />
                <Route path="/payment/:type/:id" element={<Stripe />} />
                <Route
                  path="/followKits/checkout"
                  element={<FollowCheckout />}
                />
                <Route path="/profile" element={<Profile />} />
              </Route>

              {/* Admin Routes */}
              <Route element={<AccessRoles allowedRoles={["admin"]} />}>
                <Route path="/invite" element={<Invite />} />
                <Route path="/grouping" element={<Grouping />} />
                <Route path="/ebooks_recipes" element={<EBooksRecipes />} />
                <Route path="/products/list" element={<Products />} />
                <Route
                  path="/products/closed-produts"
                  element={<ClosedProducts />}
                />
                <Route path="/products/new-orders" element={<NewOrders />} />
                <Route path="/products/past-orders" element={<PastOrders />} />
                <Route path="/users/team-managers" element={<TeamManager />} />
                <Route
                  path="/users/deactivated_users"
                  element={<DeactivatedUsers />}
                />

                <Route
                  path="/users/team-managers/view/:id"
                  element={<TeamManagerView />}
                />
                <Route
                  path="/users/professionals"
                  element={<Professionals />}
                />
                <Route
                  path="/users/professionals/view/:id"
                  element={<ProfessionalView />}
                />
                <Route path="/users/doctors" element={<Doctor />} />
                <Route
                  path="/users/doctors/view/:id"
                  element={<DoctorView />}
                />
                <Route path="/users/approval" element={<UsersApproval />} />
                <Route path="/users/transactions" element={<Transactions />} />
                <Route path="/users/coupons" element={<Coupon />} />
                <Route path="/users/register" element={<RegisterUser />} />
                <Route
                  path="/settings/email-templates"
                  element={<EmailTemplate />}
                />
                <Route path="/settings/motives" element={<Motives />} />
                <Route
                  path="/settings/product-category"
                  element={<ProductCategory />}
                />
                <Route
                  path="/settings/ebook-category"
                  element={<EbookCategory />}
                />
                <Route
                  path="/settings/recipe-category"
                  element={<RecipeCategory />}
                />
                <Route
                  path="/settings/sport-category"
                  element={<SportCategory />}
                />
                <Route path="/settings/staticPages" element={<StaticPages />} />
                <Route
                  path="/settings/report-pricing"
                  element={<ReportPricing />}
                />
                <Route path="/settings/departments" element={<Departments />} />
                <Route path="/settings/ibannumber" element={<IBINNumber />} />
                <Route
                  path="/settings/sample-excel"
                  element={<SampleExcel />}
                />

                <Route path="/users/employees" element={<Employees />} />
                <Route
                  path="/users/employees/:type"
                  element={<EmployeeForm />}
                />
                <Route
                  path="/users/employees/:type/:id"
                  element={<EmployeeForm />}
                />
                <Route path="/delete_report" element={<DeleteReports />} />
              </Route>
            </Route>
          </Route>

          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
