import { appApi } from "../api/api";

export const departmentsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentsList: builder.query({
      query: () => `/api/admin/departments`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "Departments",
              ...result.map(({ id }) => ({ type: "Departments", id })),
            ]
          : ["Departments"],
    }),
    getDepartmentsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/departments/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Departments",
              ...result?.report?.map(({ id }) => ({
                type: "Departments",
                id,
              })),
            ]
          : ["Departments"],
    }),
    getDepartmentById: builder.query({
      query: (id) => `/api/admin/departments/${id}`,
      providesTags: (result, error, arg) => [{ type: "Departments", id: arg }],
    }),
    createDepartments: builder.mutation({
      query: (values) => ({
        url: `/api/admin/departments/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Departments"],
    }),
    updateDepartments: builder.mutation({
      query: ({ id, ...values }) => ({
        url: `/api/admin/departments/${id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Departments", id: arg.id },
      ],
    }),
    deleteDepartments: builder.mutation({
      query: (id) => ({
        url: `/api/admin/departments/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Departments"],
    }),
  }),
});

export const {
  useGetDepartmentByIdQuery,
  useGetDepartmentsListQuery,
  useCreateDepartmentsMutation,
  useDeleteDepartmentsMutation,
  useUpdateDepartmentsMutation,
  useLazyGetDepartmentsBySearchQuery,
} = departmentsApi;
