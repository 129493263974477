import { ArrowRight } from "@mui/icons-material";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { appApi } from "../api/api";

export const followkitApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getFollowKit: builder.query({
      query: () => `/api/admin/follow_up_kit_reports`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "FollowKit",
              ...result.map(({ id }) => ({ type: "FollowKit", id })),
            ]
          : ["FollowKit"],
    }),

    //followkit individual request
    followKitRequest: builder.mutation({
      query: (values) => ({
        url: `/api/req_followupkit/${values.id}`,
        method: "POST",
        body: values.requested_Kit,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "FollowKit", id: arg }],
    }),

    getFollowKitReportById: builder.query({
      query: (id) => `/api/admin/follow_up_kit_reports/${id}`,
      providesTags: (result, error, arg) => [{ type: "FollowKit", id: arg }],
    }),

    approveFollowKit: builder.mutation({
      query: (id) => ({
        url: `/api/admin/followupkit_close/${id}`,
        method: "GET",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "FollowKit", id: arg }],
    }),

    getFollowKitPricings: builder.query({
      query: () => `/api/admin/pricings`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "FollowKitPricings",
              ...result.map(({ id }) => ({ type: "FollowKitPricings", id })),
            ]
          : ["FollowKitPricings"],
    }),
    getFollowKitPricingById: builder.query({
      query: (id) => `/api/admin/pricings/${id}`,
      providesTags: (result, error, arg) => [
        { type: "FollowKitPricings", id: arg },
      ],
    }),

    getFollowKitPricingsBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/pricings/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "FollowKitPricings",
              ...result?.report?.map(({ id }) => ({
                type: "FollowKitPricings",
                id,
              })),
            ]
          : ["FollowKitPricings"],
    }),

    changeFollowitOrder: builder.mutation({
      query: (values) => ({
        url: `/api/admin/pricings/change_order`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => ["FollowKitPricings"],
    }),

    newFollowitPrice: builder.mutation({
      query: (values) => ({
        url: `/api/admin/pricings/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => ["FollowKitPricings"],
    }),

    updateFollowitPrice: builder.mutation({
      query: (values) => ({
        url: `/api/admin/pricings/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "FollowKitPricings", id: arg?.id },
      ],
    }),

    deleteFollowitPrice: builder.mutation({
      query: (id) => ({
        url: `/api/admin/pricings/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "FollowKitPricings", id: arg },
      ],
    }),

    followShipment: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/admin/fkitorders/${id}/update`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FollowKit"],
    }),

    updateFollowStatus: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/admin/fkitorders/${id}/follow_status`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["FollowKit"],
    }),

    viewFollowKitById: builder.query({
      query: (id) => `/api/fkitorders/${id}/show`,
    }),

    searchFollowkit: builder.query({
      query: (arg) => ({
        url: `/api/admin/fkit_search`,
        method: "POST",
        body: arg,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "FollowKit",
              ...result?.report?.map(({ id }) => ({ type: "FollowKit", id })),
            ]
          : ["FollowKit"],
    }),

    searchExport: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/new/export`,
        method: "POST",
        body: arg,
      }),
    }),
    newExport: builder.mutation({
      query: (arg) => ({
        url: `/api/admin/export`,
        method: "POST",
        body: arg,
      }),
    }),

    //address Index
    getAddress: builder.query({
      query: (id) => `/api/${id}/shippings`,
      invalidatesTags: ["UserAddress"],
    }),

    //Create Address
    createAddress: builder.mutation({
      query: (values) => ({
        url: `/api/${values.user_id}/shippings/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UserAddress"],
    }),

    //Create Address
    createAddress: builder.mutation({
      query: (values) => ({
        url: `/api/${values.user_id}/shippings/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UserAddress"],
    }),
    //update Address
    updateAddress: builder.mutation({
      query: (values) => ({
        url: `/api/shippings/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["UserAddress"],
    }),
    //delete Address
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/api/shippings/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserAddress"],
    }),

    //cart and checkout
    getFollowCartList: builder.query({
      query: () => `/api/admin_cart_index`,
      providesTags: ["Follow_Cart"],
    }),
    deleteFollowCartList: builder.mutation({
      query: (id) => ({
        url: `/api/fkitcarts/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Follow_Cart"],
    }),
    createFollowOrder: builder.mutation({
      query: (values) => ({
        url: `/api/fkitorders/${values.id}/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Follow_Cart"],
    }),

    createBulkFollowOrder: builder.mutation({
      query: (values) => ({
        url: `/api/admin_bulk_new/${values.id}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Follow_Cart", "FollowKit"],
    }),
    //Bulk order
    bulkFollowKitRequest: builder.mutation({
      query: (values) => ({
        url: `/api/admin_bulk_upload/${values.id}`,
        method: "POST",
        body: values.requested_bulk_Kit,
      }),
      invalidatesTags: ["FollowKit"],
    }),
  }),
});

export const {
  useGetFollowKitQuery,
  useApproveFollowKitMutation,
  useGetFollowKitReportByIdQuery,
  useGetFollowKitPricingsQuery,
  useNewFollowitPriceMutation,
  useUpdateFollowitPriceMutation,
  useDeleteFollowitPriceMutation,
  useGetFollowKitPricingByIdQuery,
  useFollowShipmentMutation,
  useUpdateFollowStatusMutation,
  useViewFollowKitByIdQuery,
  useLazySearchFollowkitQuery,
  useNewExportMutation,
  useSearchExportMutation,
  useFollowKitRequestMutation,
  useGetAddressQuery,
  useGetFollowCartListQuery,
  useDeleteFollowCartListMutation,
  useCreateBulkFollowOrderMutation,
  useCreateFollowOrderMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useBulkFollowKitRequestMutation,
  useLazyGetFollowKitPricingsBySearchQuery,
  useChangeFollowitOrderMutation,
} = followkitApi;
