import React, { useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import CustomRadio from "../../Components/Atoms/CustomRadio";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import CustomButton from "../../Components/Atoms/CustomButton";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { toast } from "react-hot-toast";
import { useLoginUserMutation } from "../../services/auth/authApi";
import { set2FACredentials } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [passVisible, setPassVisible] = useState(false);

  const validationArray = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password: Yup.string().min(2, "Too Short!").required("Required"),
    rememberme: 0,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      method: "mail",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      try {
        await loginUser(values)
          .unwrap()
          .then((res) => {
            if (res) {
              dispatch(set2FACredentials(res[0]));
              navigate("/twofactor");
            }
          });
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong");
        }
        throw new Error(error);
      }
    },
  });

  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <h2>Login</h2>
            <p>Welcome Admin! Please login to continue</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Password"
                placeholder="Enter password"
                name="password"
                type={passVisible ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                formik={formik}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>
            <div className="col-md-12 mt-3 mb-0">
              <CustomRadio
                label="OTP sent through"
                name="method"
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                value={formik.values.method}
                options={[
                  { label: "Email", value: "mail" },
                  { label: "Phone", value: "message" },
                ]}
              />
            </div>

            {/* <div className="col-md-12 chkFrgSctn d-flex align-items-center justify-content-between">
                <CustomCheckBox label="Remember Me" />
                <Link to="/forgotPassword">Forgot Password?</Link>
              </div> */}
            <div className="col-md-12 text-center">
              <CustomButton
                label={`${isLoading ? "You are logging in..." : "Login"}`}
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
