import { appApi } from "../api/api";

export const sportCategoryApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSportCategoryList: builder.query({
      query: () => `/api/sport_categeory`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportCategory",
              ...result.map(({ id }) => ({ type: "SportCategory", id })),
            ]
          : ["SportCategory"],
    }),

    getSportCategoryBySearch: builder.query({
      query: (values) => ({
        url: `/api/sport_categeory/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportCategory",
              ...result?.report?.map(({ id }) => ({
                type: "SportCategory",
                id,
              })),
            ]
          : ["SportCategory"],
    }),
    getSportCategoryById: builder.query({
      query: (id) => `/api/sport_categeory/${id}`,
      providesTags: (result, error, arg) => [
        { type: "SportCategory", id: arg },
      ],
    }),
    createSportCategory: builder.mutation({
      query: (values) => ({
        url: `/api/sport_categeory/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "SportCategory" }],
    }),
    updateSportCategory: builder.mutation({
      query: (values) => ({
        url: `/api/sport_categeory/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SportCategory", id: arg.id },
      ],
    }),
    deleteSportCategory: builder.mutation({
      query: (id) => ({
        url: `/api/sport_categeory/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "SportCategory" }],
    }),
  }),
});

export const {
  useGetSportCategoryListQuery,
  useGetSportCategoryByIdQuery,
  useCreateSportCategoryMutation,
  useDeleteSportCategoryMutation,
  useUpdateSportCategoryMutation,
  useLazyGetSportCategoryBySearchQuery,
} = sportCategoryApi;
