import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import {
  useDeleteRecipeCategoryMutation,
  useLazyGetRecipeCategoryBySearchQuery,
} from "../../../services/ebooksAndRecipes/ebooksAndRecipes";
import AddRecipeCategory from "./AddRecipeCategory";
import { addSearchValue } from "../../../features/search/searchSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import { handleSort } from "../../../Utils/Utils";

const RecipeCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Reports list with filters

  const [getReports, { data: list, isError, isLoading, isFetching }] =
    useLazyGetRecipeCategoryBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);
  const { recipe_category_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      list_per_page: 10,
      page: 1,
      sort: "",
    },

    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(
        addSearchValue({
          name: "recipe_category_search",
          values,
        })
      );
      try {
        const query = await getReports(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getReports(previousFilter);
      } else {
        await getReports(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Category", key: "rc_name", sortable: true },
    { name: "Action", sortable: false },
  ];

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
    type: "new",
  });

  const [deleteCategory, { isLoading: deleting }] =
    useDeleteRecipeCategoryMutation();

  const handleDelete = async (data) => {
    return confirmDialog("Are you sure to delete this category!", async () => {
      const notify = toast.loading("Deleting category..");
      try {
        return await deleteCategory(data.id)
          .unwrap()
          .then(() => {
            toast.success("Category deleted successfully", { id: notify });
          });
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete category!", { id: notify });
      }
    });
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="">
                    <img src="/assets/svg/ebook.svg" className="me-2" />
                  </span>
                  Recipe Category
                </h3>
                <CustomSearch
                  inputName="search"
                  query={searchFormik.values.search}
                  handleChange={searchFormik.handleChange}
                  handleSubmit={(e) => {
                    e.preventDefault();
                    searchFormik.handleSubmit();
                    searchFormik.setFieldValue("page", 1);
                  }}
                  containerClasses="ms-md-auto small"
                  disabled={isLoading}
                />
                <CustomButton
                  size="small"
                  label={
                    <>
                      <span className="material-symbols-rounded me-2">add</span>
                      Add Category
                    </>
                  }
                  onClick={() =>
                    setAddPopup({
                      mount: true,
                      data: null,
                      type: "new",
                    })
                  }
                />
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report?.length &&
                      list.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>{data.rc_name}</td>
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <EditOutlined />
                                      Edit
                                    </>
                                  ),

                                  handleMenuClick: () => {
                                    return setAddPopup({
                                      mount: true,
                                      data: data,
                                      type: "edit",
                                    });
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <DeleteOutline />
                                      Delete
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return handleDelete(data);
                                  },
                                  disabled: deleting,
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddRecipeCategory
        {...addPopup}
        handleClose={() =>
          setAddPopup({
            mount: false,
            data: null,
            type: "new",
          })
        }
      />
      <ConfirmDialogPopper />
    </>
  );
};

export default RecipeCategory;
