import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomEditor from "../../../Components/Atoms/CustomEditor";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import {
  useUpdateInnerHackReportMutation,
  useUploadInnerHackReportMutation,
} from "../../../services/reports/reportsApi";
import { toast } from "react-hot-toast";

const validationSchema = yup.object().shape({
  report_name: yup
    .string()
    .min(3, "Too short!")
    .required("Report name isr equired"),
});

const AddInnerHackReport = ({ type, data, handleClose }) => {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [fileInputErrors, setFileInputErrors] = useState({});

  const [uploadReport, { isLoading }] = useUploadInnerHackReportMutation();
  const [updateReport, { isLoading: isUpdate }] =
    useUpdateInnerHackReportMutation();

  const formik = useFormik({
    initialValues: data || {
      report_name: "",
      date: new Date().toISOString().slice(0, 10),
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new" && !report)
        return toast.error("Please Select report before submit!");

      if (fileInputErrors.report) {
        return;
      }

      if (type === "new") {
        try {
          const formData = new FormData();
          for (const key in values) {
            formData.append(key, values[key]);
          }
          formData.append("userId", id);
          const upload = await uploadReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report uploaded successfully");
                handleClose();
              }
            });

          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to upload report!");
        }
      } else if (type === "edit") {
        try {
          const formData = new FormData();
          for (const key in values) {
            if (key === "report") {
              typeof values[key] === "object" &&
                formData.append("report", values[key]);
            } else {
              formData.append(key, values[key]);
            }
          }

          const update = await updateReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report updated successfully");
                handleClose();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update report!");
        }
      }
    },
  });

  const handleReport = (e) => {
    const selectedReport = e.target.files[0];
    const { name, files } = e.target;

    if (files[0]) {
      if (files[0].type !== "application/pdf") {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid file format. Only PDF files are allowed.",
        }));
      } else if (files[0].size > 5 * 1024 * 1024) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 5MB.",
        }));
      } else {
        setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else {
      setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    formik.setFieldValue("report", selectedReport);

    return setReport(selectedReport);
  };

  return (
    <div className="inrHckForm">
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="Title"
              name="report_name"
              value={formik.values.report_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="upld_report" className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report ? report.name : "Upload your report"}</p>
              </div>
              <input
                onChange={handleReport}
                type="file"
                id="upld_report"
                accept="application/pdf"
                hidden
                multiple
                name="report"
              />
              {fileInputErrors.report && (
                <div className="errMsgs">{fileInputErrors.report}</div>
              )}
            </label>
          </div>
          {/* <div className="col-md-12 mt-2">
            <h4>Food</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.food_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("food_avoid", editor.getData());
              }}
              error={formik.errors.food_avoid && formik.errors.food_avoid}
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.food_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("food_embrace", editor.getData());
              }}
              error={formik.errors.food_embrace && formik.errors.food_embrace}
            />

            <CustomEditor
              label="Tips"
              data={formik.values.food_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("food_tip", editor.getData());
              }}
              error={formik.errors.food_tip && formik.errors.food_tip}
            />
          </div>
          <div className="col-md-12 mt-2">
            <h4>Supplement</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.supplement_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("supplement_avoid", editor.getData());
              }}
              error={
                formik.errors.supplement_avoid && formik.errors.supplement_avoid
              }
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.supplement_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("supplement_embrace", editor.getData());
              }}
              error={
                formik.errors.supplement_embrace &&
                formik.errors.supplement_embrace
              }
            />
            <CustomEditor
              label="Tips"
              data={formik.values.supplement_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("supplement_tip", editor.getData());
              }}
              error={
                formik.errors.supplement_tip && formik.errors.supplement_tip
              }
            />
          </div>
          <div className="col-md-12 mt-2">
            <h4>Exercise Recovery</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.excercise_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("excercise_avoid", editor.getData());
              }}
              error={
                formik.errors.excercise_avoid && formik.errors.excercise_avoid
              }
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.excercise_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("excercise_embrace", editor.getData());
              }}
              error={
                formik.errors.excercise_embrace &&
                formik.errors.excercise_embrace
              }
            />
            <CustomEditor
              label="Tips"
              data={formik.values.excercise_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("excercise_tip", editor.getData());
              }}
              error={formik.errors.excercise_tip && formik.errors.excercise_tip}
            />
          </div>
          <div className="col-md-12 mt-2">
            <h4>Cognitive</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.cognitive_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("cognitive_avoid", editor.getData());
              }}
              error={
                formik.errors.cognitive_avoid && formik.errors.cognitive_avoid
              }
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.cognitive_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("cognitive_embrace", editor.getData());
              }}
              error={
                formik.errors.cognitive_embrace &&
                formik.errors.cognitive_embrace
              }
            />
            <CustomEditor
              label="Tips"
              data={formik.values.cognitive_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("cognitive_tip", editor.getData());
              }}
              error={formik.errors.cognitive_tip && formik.errors.cognitive_tip}
            />
          </div>
          <div className="col-md-12 mt-2">
            <h4>Wellbeing</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.wellbeing_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("wellbeing_avoid", editor.getData());
              }}
              error={
                formik.errors.wellbeing_avoid && formik.errors.wellbeing_avoid
              }
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.wellbeing_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("wellbeing_embrace", editor.getData());
              }}
              error={
                formik.errors.wellbeing_embrace &&
                formik.errors.wellbeing_embrace
              }
            />
            <CustomEditor
              label="Tips"
              data={formik.values.wellbeing_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("wellbeing_tip", editor.getData());
              }}
              error={formik.errors.wellbeing_tip && formik.errors.wellbeing_tip}
            />
          </div>
          <div className="col-md-12 mt-2">
            <h4>Super Organisms</h4>
            <hr />
            <CustomEditor
              label="Avoid"
              data={formik.values.superorganism_avoid}
              onChange={(event, editor) => {
                formik.setFieldValue("superorganism_avoid", editor.getData());
              }}
              error={
                formik.errors.superorganism_avoid &&
                formik.errors.superorganism_avoid
              }
            />
            <CustomEditor
              label="Embrace"
              data={formik.values.superorganism_embrace}
              onChange={(event, editor) => {
                formik.setFieldValue("superorganism_embrace", editor.getData());
              }}
              error={
                formik.errors.superorganism_embrace &&
                formik.errors.superorganism_embrace
              }
            />
            <CustomEditor
              label="Tips"
              data={formik.values.superorganism_tip}
              onChange={(event, editor) => {
                formik.setFieldValue("superorganism_tip", editor.getData());
              }}
              error={
                formik.errors.superorganism_tip &&
                formik.errors.superorganism_tip
              }
            />
          </div> */}

          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr d-flex align-items-center justify-content-end gap-2">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpdate}
                type="submit"
                label={isLoading || isUpdate ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddInnerHackReport;
