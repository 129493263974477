import React from "react";
import { useGetUsersExcelMutation } from "../../../services/ExcelReport.js/index.js";
import { saveAs } from "file-saver";
import CustomButton from "../../Atoms/CustomButton/index.js";
import { toast } from "react-hot-toast";
import { useSearchExportMutation } from "../../../services/followkit/followkitApi.js";

const Export = ({ type, size, values }) => {
  const [exportReport, { isLoading }] = useSearchExportMutation();

  const handleApi = async () => {
    let date = new Date();
    let data = await exportReport(values);
    console.log(data, "data");
    if (data != undefined) {
      let file = new Blob([data?.error?.data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(file, `${type}${date.getDate()}${date.getTime()}.csv`);
      toast.success("Data exported successfully!");
    } else {
      toast.error("Something went wrong! please try after sometime");
    }
  };
  return (
    <>
      <CustomButton
        disabled={isLoading}
        onClick={() => handleApi()}
        btnType="secondary"
        btnClass="exprtBtn"
        size={size}
        label={
          <>
            <span className="material-symbols-rounded me-2">
              cloud_download
            </span>{" "}
            Export
          </>
        }
      />
    </>
  );
};

export default Export;
