import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../../services/auth/authApi";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    products_search: null,
    ebooks_search: null,
    recipe_search: null,
    doctor_search: null,
    team_manager_search: null,
    professionals_search: null,
    followkit_search: null,
  },

  reducers: {
    addSearchValue: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.values,
      };
    },
    removeSearchValue: (state, action) => {
      return {
        ...state,
        [action.payload.name]: null,
      };
    },
  },
});

export default searchSlice.reducer;

export const { addSearchValue, removeSearchValue } = searchSlice.actions;
