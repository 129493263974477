import {
  OpenInNew,
  PersonAddOutlined,
  PersonOffOutlined,
  PersonRemoveOutlined,
  Tune,
} from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../Components/Molecules/ConfirmDialog";
import CustomDropdown from "../../Components/Molecules/CustomDropdown";
import ExportComponent from "../../Components/Molecules/ExportComponent";
import { addSearchValue } from "../../features/search/searchSlice";
import { useGetSportCategoryListQuery } from "../../services/settings/sportCategoryApi";
import {
  useDeactivateUserMutation,
  useHandleUserStatusMutation,
  useLazyGetDoctorSportPersonsListSearchQuery,
  useLazyGetProSportPersonsListSearchQuery,
  useLazyGetSportPersonsListSearchQuery,
  useLazyGetTMSportPersonsListSearchQuery,
} from "../../services/Users/usersApi";
import SportPersonSearchModal from "./SportPersonSearchModal";
import { handleSort } from "../../Utils/Utils";
import SingleExport from "../../Components/Molecules/SingleExport/SingleExport";

const SportPersons = ({ type = "sport_persons", user_id }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // Reports list with filters

  const [getSportsPersons, { data: list }] =
    useLazyGetSportPersonsListSearchQuery();
  const [getTmSportsPersons, { data: team_manager_list }] =
    useLazyGetTMSportPersonsListSearchQuery();
  const [getProfSportsPersons, { data: professional_list }] =
    useLazyGetProSportPersonsListSearchQuery();
  const [getDocSportsPersons, { data: doctor_list }] =
    useLazyGetDoctorSportPersonsListSearchQuery();

  const { data: sports_List } = useGetSportCategoryListQuery();

  const [filterModal, setFilterModal] = useState(false);

  const previousFilter = useSelector(
    (state) =>
      state.search[`${type}_user_search${user_id ? `_${user_id}` : ""}`]
  );

  const searchSubmission = (values) => {
    switch (type) {
      case "sport_persons":
        return getSportsPersons(values);
      case "team_manager":
        return getTmSportsPersons(values);
      case "doctor":
        return getDocSportsPersons({ ...values, type: "sport_person" });
      case "professional":
        return getProfSportsPersons(values);
      case "dashboard":
        return getSportsPersons(values);
      default:
        break;
    }
  };

  const initialValues = {
    search: "",
    type: "sport_person",
    filter: {
      country: "",
      state: "",
      zipcode: "",
      gender: "",
      city: "",
    },
    sort: "",
    list_per_page: 10,
    page: 1,
    id: user_id,
  };

  const searchFormik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const notify = toast.loading("Getting list..");
      if (filterModal) setFilterModal(false);

      dispatch(
        addSearchValue({
          name: `${type}_user_search${user_id ? `_${user_id}` : ""}`,
          values: {
            ...values,
            type: "sport_person",
          },
        })
      );

      try {
        const query = await searchSubmission(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, [previousFilter]);

  useEffect(() => {
    if (type === "sport_persons" || type === "dashboard") {
      getSportsPersons(previousFilter ? previousFilter : initialValues);
    } else if (type === "team_manager") {
      getTmSportsPersons(
        previousFilter
          ? { ...previousFilter, id: user_id }
          : { ...initialValues, id: user_id }
      );
    } else if (type === "doctor") {
      getDocSportsPersons(
        previousFilter
          ? {
              ...previousFilter,
              id: user_id,
              type: "sport_person",
            }
          : {
              ...initialValues,
              id: user_id,
              type: "sport_person",
            }
      );
    } else if (type === "professional") {
      getProfSportsPersons(
        previousFilter
          ? { ...previousFilter, id: user_id }
          : { ...initialValues, id: user_id }
      );
    }
  }, [type]);

  const handleChangePage = async (event, newPage) => {
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.country && count++;
    searchFormik.values.filter.state && count++;
    searchFormik.values.filter.city && count++;
    searchFormik.values.filter.zipcode && count++;
    searchFormik.values.filter.gender && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    return count;
  }, [searchFormik.values]);

  const getSportsPersonsByType = useMemo(() => {
    switch (type) {
      case "sport_persons":
        return list;
      case "team_manager":
        return team_manager_list;
      case "doctor":
        return doctor_list;
      case "professional":
        return professional_list;
      case "dashboard":
        return list;
      default:
        return [];
    }
  }, [type, list, team_manager_list, doctor_list, professional_list]);

  // user's status handlers

  const [handleStatus, { isLoading: statusUpdating }] =
    useHandleUserStatusMutation();

  const [deactivateUser, { isLoading: deactivatingUser }] =
    useDeactivateUserMutation();

  const handleUserStatus = ({ id, first_name, last_name, is_active }) => {
    return confirmDialog(
      `Do you really want to ${
        is_active ? "suspend" : "activate"
      } ${first_name} ${last_name}'s  account?`,
      async () => {
        const notify = toast.loading(`updating client ID ${id} account status`);
        try {
          const res = await handleStatus(id)
            .unwrap()
            .then((res) => {
              return toast.success(res?.message, { id: notify });
            });

          return res;
        } catch (error) {
          console.log(error);
          if (error?.data?.detail) {
            toast.error(error?.data?.detail, { id: notify });
          } else {
            toast.error("Failed to update user's status!", { id: notify });
          }
        }
      }
    );
  };

  const handleDeactivateUser = async ({ id, first_name, last_name }) => {
    return confirmDialog(
      `Do you really want to deactivate ${first_name} ${last_name}'s  account permanantly?`,
      async () => {
        const notify = toast.loading(`Deactiving client ID ${id} status`);
        try {
          const res = await deactivateUser(id)
            .unwrap()
            .then((res) => {
              return toast.success(res?.message, { id: notify });
            });

          return res;
        } catch (error) {
          console.log(error);
          if (error?.data?.detail) {
            toast.error(error?.data?.detail, { id: notify });
          } else {
            toast.error("Failed to deactiving the user!", { id: notify });
          }
        }
      }
    );
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Client ID", key: "ref_no", sortable: true },
    {
      name: "First Name",
      key: "first_name",
      sortable: true,
      hidden: user?.department?.department_name === "lab",
    },
    {
      name: "Last Name",
      key: "last_name",
      sortable: true,
      hidden: user?.department?.department_name === "lab",
    },
    {
      name: "Email",
      key: "email",
      sortable: true,
      hidden: user?.department?.department_name === "lab",
    },
    {
      name: "Sport",
      key: "sport_categeory",
      sortable: true,
      hidden: user?.department?.department_name === "lab",
    },
    {
      name: "Status",
      sortable: false,
      hidden: user?.department?.department_name === "lab",
    },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="">
                    <img src="/assets/svg/sport_persons.svg" className="me-2" />
                  </span>
                  Sport Persons
                </h3>

                {type === "dashboard" ? (
                  <CustomButton
                    label="View All"
                    onClick={() => navigate("/users/sport-persons")}
                  />
                ) : (
                  <>
                    <CustomSearch
                      inputName="search"
                      query={searchFormik.values.search}
                      handleChange={searchFormik.handleChange}
                      handleSubmit={(e) => {
                        e.preventDefault();
                        searchFormik.handleSubmit();
                        searchFormik.setFieldValue("page", 1);
                      }}
                      containerClasses="ms-md-auto small"
                    />
                    <Badge badgeContent={handelBageContent} color="secondary">
                      <CustomButton
                        size="small"
                        onClick={() => setFilterModal(true)}
                        label={
                          <>
                            <Tune className="me-2" />
                            Filter
                          </>
                        }
                      />
                    </Badge>
                    {user?.department?.department_name === "lab"
                      ? ""
                      : type !== "team_manager" &&
                        type !== "professional" &&
                        type !== "doctor" && (
                          <ExportComponent
                            size="small"
                            id={id}
                            type="sport_person"
                          />
                        )}
                    {(type == "team_manager" || type == "professional") && (
                      <SingleExport size="small" id={id} />
                    )}
                    {type == "doctor" && (
                      <SingleExport size="small" id={id} type="sport_person" />
                    )}
                    {/* <ExportComponent
                      type="sport_person"
                      from_date={""}
                      to_date={""}
                      size="small"
                    /> */}
                  </>
                )}
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(
                        ({ sortable, key, name, hidden }, idx) => (
                          <th hidden={hidden} key={idx}>
                            <div
                              className={`align-items-center ${
                                sortable ? "sortable" : ""
                              } ${
                                searchFormik.values.sort.includes(key)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                sortable && handleSort(searchFormik, key || "")
                              }
                            >
                              {name}
                              {sortable && (
                                <span className="material-symbols-rounded">
                                  {searchFormik.values.sort.includes(key)
                                    ? searchFormik.values.sort.includes(
                                        `-${key}`
                                      )
                                      ? "expand_more"
                                      : "expand_less"
                                    : "unfold_more"}
                                </span>
                              )}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!!getSportsPersonsByType?.report?.length ? (
                      getSportsPersonsByType.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>
                            <Link to={`/users/sport-persons/view/${data.id}`}>
                              {data.ref_no}
                            </Link>
                          </td>
                          {user?.department?.department_name !== "lab" && (
                            <>
                              <td>{data.first_name}</td>
                              <td>{data.last_name}</td>
                              <td>{data.email}</td>
                              <td>
                                {
                                  sports_List?.find(
                                    (el) =>
                                      el.id === data?.sport_categeory && el
                                  )?.sport_name
                                }
                              </td>
                              {/* <td>04/12/2025</td>
                              <td>04/12/2025</td> */}
                              <td>
                                <span
                                  className={`status ${
                                    data.deactivate
                                      ? "error"
                                      : data.is_active
                                      ? "accepted"
                                      : "pending"
                                  }`}
                                >
                                  {data.deactivate
                                    ? "Deactivated"
                                    : data.is_active
                                    ? "Active"
                                    : "Suspended"}
                                </span>
                              </td>
                            </>
                          )}
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <OpenInNew />
                                      View
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    navigate(
                                      `/users/sport-persons/view/${data.id}`
                                    );
                                  },
                                },
                                {
                                  label: data.is_active ? (
                                    <>
                                      <PersonRemoveOutlined />
                                      Suspend
                                    </>
                                  ) : (
                                    <>
                                      <PersonAddOutlined />
                                      Activate
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    handleUserStatus(data);
                                  },
                                  disabled: statusUpdating,
                                  hidden:
                                    data.deactivate ||
                                    user?.department?.department_name === "lab",
                                },
                                {
                                  label: (
                                    <>
                                      <PersonOffOutlined />
                                      Deactivate
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    handleDeactivateUser(data);
                                  },
                                  disabled: deactivatingUser,
                                  hidden:
                                    data.deactivate ||
                                    user?.department?.department_name === "lab",
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr style={{ verticalAlign: "middle" }}>
                        <td colSpan={9} className="text-center">
                          No Records Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {type !== "dashboard" && (
                <div className="tblFtrActs">
                  <div className="ms-auto">
                    <TablePagination
                      component="div"
                      count={getSportsPersonsByType?.count || 0}
                      page={searchFormik.values.page - 1}
                      onPageChange={handleChangePage}
                      rowsPerPage={searchFormik.values.list_per_page}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialogPopper />
      {filterModal && (
        <SportPersonSearchModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default SportPersons;
