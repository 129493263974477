import { CircularProgress } from "@mui/material";
import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";

function AuthRequired() {
  const location = useLocation();
  const { auth } = useAuth();
  const { user } = useSelector((state) => state.auth);
  if (auth === undefined)
    return (
      <div
        style={{ height: "100vh" }}
        className="w-100 d-flex align-items-center justify-content-center"
      >
        <CircularProgress />
      </div>
    );

  return Boolean(user) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default AuthRequired;
