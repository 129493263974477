import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import "./Dialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={props.maxWidth ? props.maxWidth : "sm"}
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        className={`${props.className} customDialog`}
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={`dialogWrapper ${props.avoidBG && "avoid-bg"}`}>
          {props.title && <h4>{props.title}</h4>}
          <IconButton className="closeButton" onClick={props.handleClose}>
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
          <div className="dialogBody">{props.children}</div>
        </div>
      </Dialog>
    </div>
  );
}
