import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import {
  useGetProfessionalsListQuery,
  useGetTeamManagersListQuery,
} from "../../services/Users/usersApi";
import { assetUrlBuilder } from "../../Utils/Utils";
import DoctorForm from "../Signup/forms/DoctorForm";
import TeamManagerForm from "../Signup/forms/TeamManagerForm";
import ProfessionalForm from "../Signup/forms/ProfessionalForm";
import SportPersonForm from "../Signup/forms/SportPersonForm";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import moment from "moment";
import { Avatar } from "@mui/material";
import { useGetMotivesListQuery } from "../../services/settings/motivesApi";
import { useGetSportCategoryListQuery } from "../../services/settings/sportCategoryApi";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useUpdateUserMutation } from "../../services/auth/authApi";

const validationArray = Yup.object().shape({
  first_name: Yup.string().required("First Name is a required field"),
  last_name: Yup.string().required("Last Name is a required field"),
  dob: Yup.date()
    .typeError("INVALID_DATE")
    .min(moment().subtract(120, "years"), "Invalid DOB")
    .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
    .required("DOB is a required field"),
  email: Yup.string().email().required("Email is a required field"),
  mobile_no: Yup.number().required("Phone No is a required field"),
  password: Yup.number().when(["account_type"], {
    is: (account_type) =>
      account_type !== "team_manager" &&
      account_type !== "professional" &&
      account_type !== "doctor",
    then: Yup.number()
      .min(2, "Too Short!")

      .required("Weight is a required field"),
  }),
  height: Yup.number().when(["account_type"], {
    is: (account_type) => account_type === "sport_person",
    then: Yup.number()
      .positive()
      .min(10)
      .max(350)
      .required("Height is a required field"),
  }),
  weight: Yup.number().when(["account_type"], {
    is: (account_type) => account_type === "sport_person",
    then: Yup.number()
      .positive()
      .min(5)
      .max(650)
      .required("Weight is a required field"),
  }),

  conf_password: Yup.number().when(["account_type"], {
    is: (account_type) =>
      account_type !== "team_manager" &&
      account_type !== "professional" &&
      account_type !== "doctor",
    then: Yup.number()
      .min(2, "Too Short!")
      .oneOf([Yup.ref("password"), null], "Password must match")

      .required("Weight is a required field"),
  }),
  rememberme: 0,
  btw_no: Yup.string()
    .nullable()
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .matches(/^[A-Z0-9]+$/, "Invalid Format")
    .matches(
      /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+]+)$/,
      "Numbers only not allowed"
    )
    .min(3, "BTW/VAT number must be at least 3 characters")
    .max(15, "BTW/VAT number must be at most 15 characters"),
});

const UserProfile = ({ user, refetch }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const { data: motive_list } = useGetMotivesListQuery();
  const { data: professional_list } = useGetProfessionalsListQuery();
  const { data: manager_list } = useGetTeamManagersListQuery();
  const { data: sport_categeory } = useGetSportCategoryListQuery();

  const formik = useFormik({
    initialValues: user || {
      account_type: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      height: "",
      weight: "",
      sport_categeory: "",
      professional: "",
      team_manager: "",
      motive: "",
      team_name: "",
      council_name: "",
      no_of_persons: "",
      specialization: "",
      hospital_name: "",
      referer_code: "",
      conf_password: "",
      password: "",
      btw_no: 0,
    },

    validationSchema: validationArray,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const formValues = new FormData();
      for (const key in values) {
        if (key !== "uploads" && key !== "profile_image") {
          formValues.append(key, values[key]);
        }
      }
      if (selectedFile !== null) {
        formValues.append("profile_image", selectedFile);
      }
      await updateUser(formValues)
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Account has been updated successfully.");
            refetch();
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  const handleProfile = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    return setProfileUrl(blob);
  };

  const getFormByType = () => {
    switch (formik.values.account_type) {
      case "sport_person":
        return (
          <>
            <SportPersonForm
              formik={formik}
              country_list={country_api.getAllCountries()}
              state_list={state_api.getStatesOfCountry(formik.values.country)}
              city_list={city_api.getCitiesOfState(
                formik.values.country,
                formik.values.state
              )}
              professional_list={professional_list}
              manager_list={manager_list}
              motive_list={motive_list}
              county={county}
              state={state}
              city={city}
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              avoidPassword
              readOnly={true}
              sport_categeory={sport_categeory}
            />
          </>
        );
      case "professional":
        return (
          <ProfessionalForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword
            sport_categeory={sport_categeory}
          />
        );
      case "team_manager":
        return (
          <TeamManagerForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword
            sport_categeory={sport_categeory}
          />
        );
      case "doctor":
        return (
          <DoctorForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            avoidPassword
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (formik.values.country || "") {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      city_api
        .getCitiesOfState(formik.values.country, formik.values.state)
        .filter((el) => el.name === formik.values.city && setCity(el));
    }
  }, [formik.values.country, formik.values.state, formik.values.city]);

  return (
    <>
      <div className="row">
        <div className="col-md-8 order-2 order-md-1">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {getFormByType()}

              <div className="col-md-12 text-center mt-3">
                <CustomButton
                  label={isLoading ? "Updating..." : "Update"}
                  type="submit"
                  disabled={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-4 order-1 order-md-2">
          <div className="usrImgWrpr">
            <label className="edtBtn">
              <span className="material-symbols-rounded">edit</span>

              <input
                type="file"
                onChange={handleProfile}
                accept="image/*"
                hidden
              />
            </label>
            <Avatar src={profileUrl || assetUrlBuilder(user?.profile_image)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
