import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import AddIbanNum from "./AddIbanNum";
import {
  useDeleteIbanNumsMutation,
  useGetIbanNumListQuery,
} from "../../../services/settings/IbanNumApi";

const IBINNumber = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // Reports list with filters

  const { data: list, isError, isLoading } = useGetIbanNumListQuery();

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "IBAN Number", key: "name" },
    { name: "Default", key: "default" },
    { name: "Action", sortable: false },
  ];

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
    type: "new",
  });

  const [deleteDep, { isLoading: deleting }] = useDeleteIbanNumsMutation();

  const handleDelete = async (data) => {
    return confirmDialog(
      "Are you sure to delete this IBAN number!",
      async () => {
        const notify = toast.loading("Deleting IBAN number..");
        try {
          return await deleteDep(data.id)
            .unwrap()
            .then(() => {
              toast.success("IBAN number deleted successfully", { id: notify });
            });
        } catch (error) {
          console.error(error);
          toast.error("Failed to delete IBAN number!", { id: notify });
        }
      }
    );
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    account_balance
                  </span>
                  IBAN Number
                </h3>

                <CustomButton
                  size="small"
                  label={
                    <>
                      <span className="material-symbols-rounded me-2">add</span>
                      Add IBAN Number
                    </>
                  }
                  onClick={() =>
                    setAddPopup({
                      mount: true,
                      data: null,
                      type: "new",
                    })
                  }
                />
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>{name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.length &&
                      list?.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          {console.log(data)}
                          <td>{idx + 1}</td>
                          <td>{data.name}</td>
                          <td>{data.default ? "True" : "False"}</td>
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <EditOutlined />
                                      Edit
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return setAddPopup({
                                      mount: true,
                                      data: data,
                                      type: "edit",
                                    });
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <DeleteOutline />
                                      Delete
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return handleDelete(data);
                                  },
                                  disabled: deleting,
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.length
                    ? "Failed to load records!"
                    : !list?.length && "No Records Found!"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddIbanNum
        {...addPopup}
        handleClose={() =>
          setAddPopup({
            mount: false,
            data: null,
            type: "new",
          })
        }
      />
      <ConfirmDialogPopper />
    </>
  );
};

export default IBINNumber;
