import React from "react";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import CustomInput from "../../Components/Atoms/CustomInput";
import MultipleSelect from "../../Components/Atoms/MultipleSelect";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Cached, Tune } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { useGetProductCategoriesQuery } from "../../services/products/ProductsApi";

function SearchProducts({ formik, open, handleClose }) {
  const handelReset = async () => {
    formik.resetForm();
    await formik.handleSubmit();
    handleClose(false);
    formik.setFieldValue("page", 1);
  };

  const { data: categories } = useGetProductCategoriesQuery(undefined, {
    refetchOnFocus: true,
  });

  return (
    <CustomDialog avoidBG open={open} handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <h6 className="mb-4">
          <Tune className="text-muted" /> Filter Products
        </h6>
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="What you are looking for?"
              formik={formik}
              name="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="col-md-6">
            <CustomSelect
              label="Category"
              formik={formik}
              name="filter.category"
              value={formik.values.filter.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={
                categories?.map((el) => {
                  return { name: el.pc_name, value: el.id };
                }) || []
              }
            />
          </div>
          <div className="col-md-6">
            <CustomSelect
              label="Product Type"
              formik={formik}
              name="filter.product_type"
              value={formik.values.filter.product_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={[
                { name: "Personalized", value: "personalized" },
                { name: "Universal", value: "universal" },
              ]}
            />
          </div>
          <div className="col-md-12">
            <div className="customInputWrpr">
              <FormControl>
                <InputLabel>Sort</InputLabel>
                <Select
                  name="sort"
                  onChange={formik.handleChange}
                  value={formik.values.sort}
                  defaultValue=""
                  label="Sort"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>Ascending</ListSubheader>
                  <MenuItem value={"product_name"}>Product Name</MenuItem>
                  <MenuItem value={"product_type"}>Product Type</MenuItem>
                  <MenuItem value={"price"}>Price</MenuItem>
                  <MenuItem value={"available_qty"}>
                    Available Quantity
                  </MenuItem>

                  <ListSubheader>Descending</ListSubheader>
                  <MenuItem value={"-product_name"}>Product Name</MenuItem>
                  <MenuItem value={"-product_type"}>Product Type</MenuItem>
                  <MenuItem value={"-price"}>Price</MenuItem>
                  <MenuItem value={"-available_qty"}>
                    Available Quantity
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <CustomButton
              label={
                <>
                  <Cached className="me-2" /> Reset
                </>
              }
              btnType="secondary"
              btnClass="primButton"
              onClick={handelReset}
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <CustomButton type="submit" label="Submit" />
          </div>
        </div>
      </form>
    </CustomDialog>
  );
}

export default SearchProducts;
