import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import {
  useGetFollowKitPricingsQuery,
  useNewFollowitPriceMutation,
  useUpdateFollowitPriceMutation,
  useDeleteFollowitPriceMutation,
  useChangeFollowitOrderMutation,
} from "../../../services/followkit/followkitApi";
import { currencyFomatter } from "../../../Utils/Utils";
import AddReportPricing from "./AddReportPricing";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const ReportPricing = () => {
  const navigate = useNavigate();

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
    type: "new",
  });

  const {
    data: list,
    isError,
    isLoading,
    isFetching,
  } = useGetFollowKitPricingsQuery(null, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Report Name", key: "report_name", sortable: true },
    { name: "Intake Price", key: "initial_price", sortable: true },
    { name: "Followup Price", key: "price", sortable: true },
    { name: "Vat", key: "vat", sortable: true },
    { name: "Action", sortable: false },
  ];

  const [newFollowKitPrice, { isLoading: addingPrice }] =
    useNewFollowitPriceMutation();

  const [updateFollowKitPrice, { isLoading: updatingPrice }] =
    useUpdateFollowitPriceMutation();

  const [deleteCategory, { isLoading: deleting }] =
    useDeleteFollowitPriceMutation();

  const [reorder, { isLoading: reordering }] = useChangeFollowitOrderMutation();

  const handleDelete = async (data) => {
    return confirmDialog("Are you sure to delete this category!", async () => {
      const notify = toast.loading("Deleting category..");
      try {
        return await deleteCategory(data.id)
          .unwrap()
          .then(() => {
            toast.success("Report deleted successfully", { id: notify });
          });
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete Report!", { id: notify });
      }
    });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (list) {
      setItems(list);
    }
  }, [list]);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);

    return reorder(
      tempData.map((item, idx) => ({
        id: item.id,
        order_id: idx,
      }))
    );
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="material-symbols-rounded me-2">
                    payments
                  </span>
                  Report Pricing
                </h3>

                <CustomButton
                  size="small"
                  label={
                    <>
                      <span className="material-symbols-rounded me-2">add</span>
                      Add Reports
                    </>
                  }
                  onClick={() =>
                    setAddPopup({
                      mount: true,
                      data: null,
                      type: "new",
                    })
                  }
                />
              </div>

              <hr />

              <DragDropContext onDragEnd={handleDragEnd}>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div className={`d-flex align-items-center `}>
                            {name}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <Droppable droppableId="droppable-1">
                    {(provider) => (
                      <tbody
                        className="text-capitalize"
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {items?.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={data.id.toString()}
                            index={index}
                          >
                            {(provider, snapshot) => (
                              <tr
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                                {...provider.dragHandleProps}
                              >
                                <td {...provider.dragHandleProps}> = </td>
                                {!snapshot.isDragging && <td>{index + 1}</td>}
                                <td>
                                  <span
                                    className={
                                      snapshot.isDragging ? "ms-4" : ""
                                    }
                                  >
                                    {data.report_name}
                                  </span>
                                </td>
                                {!snapshot.isDragging && (
                                  <>
                                    <td>
                                      {currencyFomatter(data.initial_price)}
                                    </td>
                                    <td>{currencyFomatter(data.price)}</td>
                                    <td>
                                      {data.vat}
                                      {data.vat === 0 || data.vat ? "%" : ""}
                                    </td>
                                    <td>
                                      <CustomDropdown
                                        menuItems={[
                                          {
                                            label: (
                                              <>
                                                <EditOutlined />
                                                Edit
                                              </>
                                            ),

                                            handleMenuClick: () => {
                                              return setAddPopup({
                                                mount: true,
                                                data: data,
                                                type: "edit",
                                              });
                                            },
                                          },
                                          {
                                            label: (
                                              <>
                                                <DeleteOutline />
                                                Delete
                                              </>
                                            ),
                                            handleMenuClick: () => {
                                              return handleDelete(data);
                                            },
                                            disabled: deleting,
                                          },
                                        ]}
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provider.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>

              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.length
                    ? "Failed to load records!"
                    : !list?.length && "No Records Found!"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddReportPricing
        {...addPopup}
        handleClose={() =>
          setAddPopup({
            mount: false,
            data: null,
            type: "new",
          })
        }
      />
      <ConfirmDialogPopper />
    </>
  );
};

export default ReportPricing;
