import { useEffect, useState } from "react";
import { useLazyGetCurrentUserQuery } from "../services/auth/authApi";
import { setUserCredentials } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";

function useAuth() {
  const [auth, setAuth] = useState(undefined);
  const dispatch = useDispatch();

  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    (async () => {
      await getCurrentUser()
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(setUserCredentials(res));
            return setAuth(true);
          }
        })
        .catch((error) => setAuth(false));
    })();
  }, []);

  return { auth, setAuth };
}

export default useAuth;
