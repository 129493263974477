import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useBulkUserReassignMutation,
  useGetDoctorsListQuery,
  useGetProfessionalsListQuery,
  useGetTeamManagersListQuery,
} from "../../../services/Users/usersApi";

const BulkReassignUsers = ({ open, user, handleClose }) => {
  const { data: team_managers, isLoading: managersLoading } =
    useGetTeamManagersListQuery(undefined, {
      skip: !user || user?.account_type !== "team_manager",
      refetchOnFocus: true,
    });
  const { data: doctors, isLoading: doctorsLoading } = useGetDoctorsListQuery(
    undefined,
    {
      skip: !user || user?.account_type !== "doctor",
      refetchOnFocus: true,
    }
  );
  const { data: professionals, isLoading: professionalsLoading } =
    useGetProfessionalsListQuery(undefined, {
      skip: !user || user?.account_type !== "professional",
      refetchOnFocus: true,
    });
  const [bulkUserReassign, { isLoading }] = useBulkUserReassignMutation();

  const getListBasedOnType = () => {
    switch (user?.account_type) {
      case "professional":
        return professionals;
      case "doctor":
        return doctors;
      case "team_manager":
        return team_managers;
      default:
        return [];
    }
  };

  const filteredList = useMemo(
    () => getListBasedOnType()?.filter((el) => el.id !== user?.id),
    [open, user, getListBasedOnType]
  );

  const loadingData = () => {
    switch (user?.account_type) {
      case "professional":
        return professionalsLoading;
      case "doctor":
        return doctorsLoading;
      case "team_manager":
        return managersLoading;
      default:
        return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      team_manager: null,
      doctor: null,
      professional: null,
    },
    // validationSchema: Yup.object().shape({
    //   first_name: Yup.string().required("First Name is a required field"),
    // }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (!user || isLoading) return;

      if (!values[user.account_type])
        return toast.error(`Invalid Value: ${values[user.account_type]}`);

      const formValues = new FormData();

      for (const key in values) {
        key === user.account_type && formValues.append(key, values[key]?.id);
      }
      formValues.append("id", user.id);
      const notify = toast.loading(`Users assigning..`);
      try {
        const update = await bulkUserReassign(formValues)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Users reassigned successfully", { id: notify });
              onClose();
            }
          });
        return update;
      } catch (error) {
        console.error(error);
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, { id: notify });
        } else {
          toast.error("Failed to reassig users!", { id: notify });
        }
      }
    },
  });

  const onClose = () => {
    handleClose();
    return formik.resetForm();
  };

  return (
    <CustomDialog avoidBG open={open} handleClose={onClose}>
      {loadingData() ? (
        <div className="ldngWrpr">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <h6>
            Ressign {user?.first_name} {user?.last_name} Users
          </h6>
          <hr className="my-4" />
          <div className="row">
            <div className="col-md-12 order-2 order-md-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={filteredList ?? []}
                        getOptionLabel={(option) =>
                          option?.first_name + " " + option?.last_name || ""
                        }
                        value={formik.values[user?.account_type] ?? null}
                        onChange={(_, newValue) => {
                          formik.setFieldValue(
                            user?.account_type,
                            newValue ?? null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={"Reassign To"} />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 text-center mt-3">
                    <CustomButton
                      label={isLoading ? "Submitting..." : "Submit"}
                      type="submit"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </CustomDialog>
  );
};

export default BulkReassignUsers;
