import React from "react";
import { useSelector } from "react-redux";
import { useGetRecipeByIdQuery } from "../../../services/ebooksAndRecipes/ebooksAndRecipes";

import { assetUrlBuilder } from "../../../Utils/Utils";

const ViewRecipes = ({ handleClose, data }) => {
  const { data: selectedRecipe } = useGetRecipeByIdQuery(data?.id, {
    skip: !data?.id,
  });
  return (
    <div>
      <div className="sldrWrpr">
        {selectedRecipe?.recipe_image && (
          <>
            <div style={styles.imageWrapper}>
              <img
                style={styles.image}
                src={assetUrlBuilder(selectedRecipe?.recipe_image)}
                alt=""
              />
            </div>
            <hr />
          </>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: selectedRecipe?.recipe_blog }}
        />
      </div>
    </div>
  );
};

export default ViewRecipes;

const styles = {
  imageWrapper: {
    width: "100%",
    maxHeight: "400px",
    overflow: "hidden",
    position: "relative",
    background: "#f5f5f5",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
};
