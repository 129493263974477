import { OpenInNew } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import ReportDialog from "../../../Components/Organisms/ReportDialog";
import { useGetFollowkitReportsQuery } from "../../../services/reports/reportsApi";
import ViewFollowKitReport from "./ViewFollowKitReport";

const SpFollowkitReport = () => {
  const { id } = useParams();
  const { data: report, isFetching } = useGetFollowkitReportsQuery(id, {
    refetchOnFocus: true,
  });

  const [reportVal, setReportVal] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) setReportVal(null);
  }, [showModal]);

  const handleShowPdf = (data, status) => {
    setShowModal(status);
    setReportVal(data);
  };

  return (
    <>
      <div>
        {/* <div className="tpHdrVw d-flex align-items-center justify-content-end mb-2">
          <CustomButton
            onClick={() => setAddReportPopup(true)}
            label={
              <>
                <span className="material-symbols-rounded me-2">add</span>
                Add New Report
              </>
            }
          />
        </div> */}

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Kit Order Id</th>
                <th>
                  <div className="d-flex align-items-center">Kit Details</div>
                </th>
                <th>
                  <div className="d-flex align-items-center">Date</div>
                </th>
                <th>
                  <div className="d-flex align-items-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!!report?.length ? (
                report.map((data, idx) => (
                  <tr key={data.id} style={{ verticalAlign: "middle" }}>
                    <td>{idx + 1}</td>
                    <td>{data.fkitorder.id}</td>
                    <td className="kitDtlsVw">
                      {data.fkitorder.fkitorderitems.map((fdata, index) => (
                        <>
                          <div className="kitDtlRw" key={index}>
                            <span>{fdata.followkit.req_id}</span>
                            <span className="pe-3">:</span>
                            <span>{fdata.followkit.kit_name}</span>
                          </div>
                        </>
                      ))}
                    </td>
                    <td>{data.date}</td>
                    <td>
                      <CustomDropdown
                        menuItems={[
                          {
                            label: (
                              <>
                                <OpenInNew />
                                View
                              </>
                            ),

                            handleMenuClick: () => {
                              return handleShowPdf(data, true);
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ verticalAlign: "middle" }}>
                  <td colSpan={4} className="text-center">
                    {isFetching ? "Fetching Reports.." : "No Records Found!"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ReportDialog
        title={reportVal?.followupkit?.kit_name}
        open={showModal}
        handleClose={() => setShowModal(false)}
      >
        <div className="mt-5">
          <ViewFollowKitReport report={reportVal} />
        </div>
      </ReportDialog>
    </>
  );
};

export default SpFollowkitReport;
