import { Tune } from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import Export from "../../Components/Molecules/Export";
import ReportDialog from "../../Components/Organisms/ReportDialog";
import { addSearchValue } from "../../features/search/searchSlice";
import {
  useLazyGetClosedProductsBySearchQuery,
  useLazyGetProductsBySearchQuery,
} from "../../services/products/ProductsApi";
import { currencyFomatter, handleSort } from "../../Utils/Utils";
import AddProduct from "./AddProduct";
import "./product.css";
import ViewProduct from "./ViewProduct";
import * as yup from "yup";
import SearchProducts from "./SearchProducts";

const ClosedProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  //search handlers

  const [getProductsBySearch, { data: list, isFetching, isLoading, isError }] =
    useLazyGetClosedProductsBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { closed_products_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        product_type: "",
        category: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
      from_date: "",
      to_date: "",
    },
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      if (filterModal) setFilterModal(false);

      dispatch(addSearchValue({ name: "closed_products_search", values }));

      const notify = toast.loading("Getting Products..");
      try {
        const query = await getProductsBySearch(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getProductsBySearch(previousFilter);
      } else {
        await getProductsBySearch(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);

    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    searchFormik.values.filter.category && count++;
    searchFormik.values.filter.product_type.length && count++;

    return count;
  }, [searchFormik.values]);

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Product Image", sortable: false },
    { name: "Product Name", key: "product_name", sortable: true },
    { name: "Product Type", key: "product_type", sortable: true },
    { name: "Price", key: "price", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    inventory
                  </span>
                  Closed Products
                </h3>
                <div className="d-flex flex-fill gap-2 flex-wrap">
                  <CustomSearch
                    inputName="search"
                    query={searchFormik.values.search}
                    handleChange={searchFormik.handleChange}
                    handleSubmit={(e) => {
                      e.preventDefault();
                      searchFormik.handleSubmit();
                      searchFormik.setFieldValue("page", 1);
                    }}
                    containerClasses="ms-md-auto small"
                    disabled={isLoading}
                  />
                  <Badge badgeContent={handelBageContent} color="secondary">
                    <CustomButton
                      size="small"
                      btnType="secondary"
                      btnClass="exprtBtn"
                      onClick={() => setFilterModal(true)}
                      label={
                        <>
                          <Tune className="me-2" />
                          Filter
                        </>
                      }
                    />
                  </Badge>
                  <Export
                    type="products"
                    size="small"
                    values={{
                      ...searchFormik.values,
                      model: "closed_products",
                    }}
                  />
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report?.length &&
                      list.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>
                            <img
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "cover",
                              }}
                              src={data?.productimages[0]?.product_image}
                              className="border rounded shadow-sm"
                            />
                          </td>
                          <td>{data.product_name}</td>
                          <td>{data.product_type}</td>
                          <td>{currencyFomatter(data.price)}</td>
                          <td>
                            <CustomButton
                              label="view"
                              onClick={() =>
                                setAddPopup({ mount: true, data: data })
                              }
                              size="small"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportDialog
        title={`${addPopup.data?.product_name}`}
        open={addPopup.mount}
        handleClose={() =>
          setAddPopup({
            mount: false,
            data: null,
            type: null,
          })
        }
      >
        <ViewProduct
          {...addPopup}
          handleClose={() =>
            setAddPopup({
              mount: false,
              data: null,
            })
          }
        />
      </ReportDialog>
      {filterModal && (
        <SearchProducts
          formik={searchFormik}
          open={filterModal}
          handleSubmit={() => getProductsBySearch(searchFormik.values)}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default ClosedProducts;
