import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Autocomplete, Avatar, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../services/api/api";
import moment from "moment";

import CustomButton from "../../Components/Atoms/CustomButton";
import {
  useDeleteProfileImageMutation,
  useUpdateUserMutation,
} from "../../services/auth/authApi";
import CustomInput from "../../Components/Atoms/CustomInput";
import PhoneInput from "react-phone-input-2";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { Country as country_api, State as state_api } from "country-state-city";
import { setUserCredentials } from "../../features/auth/authSlice";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { useGetDepartmentsListQuery } from "../../services/settings/departmentsApi";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const { data: department } = useGetDepartmentsListQuery(undefined, {
    refetchOnFocus: true,
  });

  const validationArray = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is a required field")
      .min(3, "Minimum 3 characters required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    last_name: Yup.string()
      .required("Last Name is a required field")
      .min(3, "Minimum 3 characters required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces"),
    username: Yup.string()
      .required("Username is a required field")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
        "Invalid username"
      ),

    dob: Yup.date()
      .typeError("INVALID_DATE")
      .min(moment().subtract(120, "years"), "Invalid DOB")
      .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
      .required("DOB is a required field"),
    email: Yup.string().email().required("Email is a required field"),
    mobile_no: Yup.number().required("Phone No is a required field"),
    department: Yup.mixed()
      .typeError("Department is a required field")
      .required("Department is a required field"),
    zipcode: Yup.string().max(15, "max 15").required("required"),
  });

  const formik = useFormik({
    initialValues: { ...user } || {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      address_1: "",
      address_2: "",
      state: "",
      country: "",
      city: "",
      department: null,
      zipcode: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      const formValues = new FormData();
      for (const key in values) {
        if (
          key !== "uploads" &&
          key !== "profile_image" &&
          key !== "department"
        ) {
          formValues.append(key, values[key]);
        }
      }

      if (values.department) {
        formValues.append("department", values.department?.id);
      }
      if (selectedFile !== null) {
        formValues.append("profile_image", selectedFile);
      }

      await updateUser(formValues)
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(setUserCredentials(res));
            toast.success("Account has been updated successfully.");
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  const handleProfile = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    setProfileUrl(blob);
    return (e.target.value = "");
  };

  // csc handlers

  const country_list = country_api.getAllCountries();
  const state_list = state_api.getStatesOfCountry(formik.values.country);

  useEffect(() => {
    if (formik.values.country) {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  const [deleteProfile, { isLoading: profileDeleting }] =
    useDeleteProfileImageMutation();

  const handleProfileDelete = async (e) => {
    if (profileDeleting) return;
    setProfileUrl(null);
    setSelectedFile(null);

    if (user?.profile_image) {
      const notify = toast.loading("Profile image deleting..");
      try {
        await deleteProfile()
          .unwrap()
          .then((res) => {
            dispatch(setUserCredentials({ ...user, profile_image: null }));
            toast.success("Profile has been deleted!", { id: notify });
          });
      } catch (error) {
        if (error?.data?.detail) {
          console.log(error.data.detail);
          toast.error(error.data.detail, { id: notify });
        } else {
          toast.error("Something went wrong!", { id: notify });
        }
      }
    }
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <h5 className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2">
                account_circle
              </span>
              Profile
            </h5>
            <hr />
          </div>
          <div className="row">
            <div className="col-md-8 order-2 order-md-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <CustomInput
                      label="First Name"
                      placeholder="Enter first name"
                      type="text"
                      name="first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Last Name"
                      placeholder="Enter last name"
                      type="text"
                      name="last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-12">
                    <CustomInput
                      label="Username"
                      placeholder="Enter Username"
                      type="text"
                      name="username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={department ?? []}
                        getOptionLabel={(option) =>
                          option.department_name || ""
                        }
                        value={formik.values.department}
                        onChange={(_, newValue) => {
                          formik.setFieldValue("department", newValue ?? null);
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("department", true)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Department" />
                        )}
                        disabled
                      />
                      {formik?.touched?.department &&
                      formik?.errors?.department ? (
                        <p className="errMsg">{formik.errors.department}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Email"
                      placeholder="Enter email"
                      type="text"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      formik={formik}
                      // disabled={true}
                      autoComplete="username"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="customInputWrpr">
                      <PhoneInput
                        country={"be"}
                        name="mobile_no"
                        value={formik.values.mobile_no}
                        onChange={(e) => {
                          formik.values.mobile_no = e;
                        }}
                        onBlur={() => {
                          formik.setFieldTouched("mobile_no", true);
                        }}
                        containerClass="intl_container"
                        inputClass="intl_input"
                        dropdownClass="intl_dropdown"
                      />
                      {formik.touched.mobile_no && formik.errors.mobile_no ? (
                        <p className="errMsg">{formik.errors.mobile_no}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Date Of Birth"
                      placeholder="Enter d.o.b"
                      type="date"
                      name="dob"
                      shrink={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomSelect
                      label="Gender"
                      placeholder="Enter email"
                      name="gender"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                      formik={formik}
                      options={[
                        {
                          value: "male",
                          name: "Male",
                        },
                        {
                          value: "female",
                          name: "Female",
                        },
                        {
                          value: "others",
                          name: "Others",
                        },
                      ]}
                    />
                  </div>

                  <div className="col-md-12">
                    <CustomInput
                      label="Address"
                      placeholder="Enter Address"
                      name="address_1"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_1}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={country_list}
                        getOptionLabel={(option) => option.name || ""}
                        value={county}
                        onChange={(_, newValue) => {
                          setCountry(newValue);
                          formik.setFieldValue(
                            "country",
                            newValue?.isoCode ?? ""
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.isoCode === value.isoCode || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Country" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={state_list}
                        getOptionLabel={(option) => option.name || ""}
                        value={state}
                        onChange={(_, newValue) => {
                          setState(newValue);
                          formik.setFieldValue(
                            "state",
                            newValue?.isoCode ?? ""
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.isoCode === value.isoCode || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="City"
                      placeholder="Enter city"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      formik={formik}
                    />
                    {/* <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={city_list}
                        getOptionLabel={(option) => option.name || ""}
                        value={city}
                        onChange={(_, newValue) => {
                          setCity(newValue);
                          formik.setFieldValue("city", newValue?.name ?? "");
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.name === value.name || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="City" />
                        )}
                      />
                    </div> */}
                  </div>

                  <div className="col-md-6">
                    <CustomInput
                      label="Zipcode"
                      placeholder="Enter Zipcode"
                      name="zipcode"
                      // type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipcode}
                      formik={formik}
                    />
                  </div>

                  {window.location.pathname.includes("/employees/edit/") && (
                    <div className="col-md-12">
                      <h6 className="mb-4 mt-5 d-flex align-items-center">
                        <span className="material-symbols-rounded me-2">
                          lock_reset
                        </span>
                        Change Password
                      </h6>
                    </div>
                  )}

                  <div className="col-md-12 text-center mt-3">
                    <CustomButton
                      label={isLoading ? "Submitting..." : "Submit"}
                      type="submit"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4 order-1 order-md-22">
              <div className="usrImgWrpr">
                <div className="profile_actions">
                  <IconButton
                    className="profile_action_btn"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      type="file"
                      onChange={handleProfile}
                      accept="image/*"
                      hidden
                    />
                    <PhotoCamera />
                  </IconButton>
                  <IconButton
                    className="profile_action_btn"
                    onClick={handleProfileDelete}
                    aria-label="delete picture"
                  >
                    <Delete />
                  </IconButton>
                </div>
                <Avatar src={profileUrl || baseUrl + user?.profile_image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
