import { Tune } from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import Export from "../../../Components/Molecules/Export";
import ExportComponent from "../../../Components/Molecules/ExportComponent";
import {
  useGetTransactionsListQuery,
  useLazyGetTransactionsBySearchQuery,
} from "../../../services/Transactions/TransactionsApi";
import { currencyFomatter, handleSort } from "../../../Utils/Utils";
import * as yup from "yup";
import { addSearchValue } from "../../../features/search/searchSlice";
import { toast } from "react-hot-toast";
import SearchFollowkits from "../../Followkits/SearchFollowkits";
import TransactionSearch from "./TransactionSearch";
import NewExport from "../../../Components/Molecules/NewExport/NewExport";

const Transactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //search handlers

  const [
    getTransactionsBySearch,
    { data: list, isFetching, isLoading, isError },
  ] = useLazyGetTransactionsBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { transactions_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
        status: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
      from_date: "",
      to_date: "",
    },
    validationSchema: yup.object({
      filter: yup.object({
        date1: yup.date().max(new Date(), "Start date can't be after today"),
        date2: yup
          .date()
          .when(
            "date1",
            (start_date, schema) =>
              start_date &&
              schema.min(start_date, "End date can't be before start date")
          )
          .max(new Date(), "End date can't be after today"),
      }),
    }),
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      if (filterModal) setFilterModal(false);

      dispatch(addSearchValue({ name: "transactions_search", values }));

      const notify = toast.loading("Getting Followkits..");
      try {
        const query = await getTransactionsBySearch(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getTransactionsBySearch(previousFilter);
      } else {
        await getTransactionsBySearch(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);

    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);

    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1.length && count++;
    searchFormik.values.filter.date2.length && count++;
    searchFormik.values.filter.status.length && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "User/Client ID", key: "user_ref_no", sortable: true },
    { name: "Order ID", key: "date", sortable: false },
    { name: "Order Type", key: "date", sortable: false },
    { name: "Transaction ID", key: "transaction_id", sortable: true },
    { name: "Transaction Amount", key: "amount", sortable: true },
    { name: "Transaction Date", key: "transaction_date", sortable: true },
    { name: "Payment Mode", key: "payment_type", sortable: true },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    magic_exchange
                  </span>
                  Transactions
                </h3>
                <div className="d-flex flex-fill gap-2 flex-wrap">
                  <CustomSearch
                    inputName="search"
                    query={searchFormik.values.search}
                    handleChange={searchFormik.handleChange}
                    handleSubmit={(e) => {
                      e.preventDefault();
                      searchFormik.handleSubmit();
                      searchFormik.setFieldValue("page", 1);
                    }}
                    containerClasses="ms-md-auto small"
                    disabled={isFetching || isLoading}
                  />
                  <Badge badgeContent={handelBageContent} color="secondary">
                    <CustomButton
                      size="small"
                      btnType="secondary"
                      btnClass="exprtBtn"
                      onClick={() => setFilterModal(true)}
                      label={
                        <>
                          <Tune className="me-2" />
                          Filter
                        </>
                      }
                    />
                  </Badge>
                  <NewExport
                    type="transactions"
                    size="small"
                    values={{ ...searchFormik.values, model: "transactions" }}
                  />
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report?.length &&
                      list.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>
                            <Link
                              to={`${
                                data.user?.account_type === "sport_person"
                                  ? data.user?.account_subtype === "patient"
                                    ? `/users/patients/view/${data.user?.id}`
                                    : `/users/sport-persons/view/${data.user?.id}`
                                  : data.user?.account_type === "team_manager"
                                  ? `/users/team-managers/view/${data.user?.id}`
                                  : data.user?.account_type === "doctor"
                                  ? `/users/doctors/view/${data.user?.id}`
                                  : data.user?.account_type === "professional"
                                  ? `/users/professionals/view/${data.user?.id}`
                                  : "#"
                              }`}
                            >
                              {data?.user?.ref_no}
                            </Link>{" "}
                          </td>
                          <td>{data["fkitorder"] ?? data["order"]}</td>
                          <td>
                            {data["fkitorder"] != undefined
                              ? "Followkit"
                              : "Product"}
                          </td>
                          <td>{data.transaction_id}</td>
                          <td>{currencyFomatter(data.amount)}</td>
                          <td>{data.transaction_date}</td>
                          <td>
                            <span
                              className={`status ${
                                data.payment_type?.toLowerCase() === "online"
                                  ? "accepted"
                                  : "pending"
                              } `}
                            >
                              {data.payment_type}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {filterModal && (
        <TransactionSearch
          formik={searchFormik}
          open={filterModal}
          handleSubmit={() => getTransactionsBySearch(searchFormik.values)}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default Transactions;
