import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Ebooks from "./Ebooks";
import Recipes from "./Recipes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EBooksRecipes = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ebooks = [
    { title: "Diet Book", img: "/assets/images/ebook-1.jpg" },
    { title: "Stamina Increase", img: "/assets/images/ebook-2.jpg" },
    { title: "Diet Book", img: "/assets/images/ebook-3.jpg" },
    { title: "Diet Book", img: "/assets/images/ebook-4.jpg" },
    { title: "Diet Book", img: "/assets/images/ebook-5.jpg" },
  ];

  const recipe = [
    { title: "Diet Book", img: "/assets/images/recipe-1.jpg" },
    { title: "Diet Book", img: "/assets/images/recipe-2.jpg" },
    { title: "Diet Book", img: "/assets/images/recipe-3.jpg" },
    { title: "Diet Book", img: "/assets/images/recipe-4.jpg" },
    { title: "Diet Book", img: "/assets/images/recipe-5.jpg" },
  ];

  return (
    <div className="mnDashboardView">
      <div className="dshStCard">
        <div className="pt-2 pb-2">
          <h5 className="d-flex align-items-center">
            <img src="/assets/svg/ebook.svg" className="me-2" />
            E-Books & Recipes
          </h5>
          <hr />
        </div>
        <div className="mt-1">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="E-Books" {...a11yProps(0)} />
                <Tab label="Recipes" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Ebooks />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Recipes />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EBooksRecipes;
