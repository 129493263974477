import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import { Button, CircularProgress, IconButton, Input } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import {
  useGetFollowKitPricingsQuery,
  useNewFollowitPriceMutation,
  useUpdateFollowitPriceMutation,
} from "../../services/followkit/followkitApi";
import { toast } from "react-hot-toast";
import { currencyFomatter } from "../../Utils/Utils";
import { current } from "@reduxjs/toolkit";

const kits = [
  { report_name: "Saliva", price: "" },
  { report_name: "Sweat", price: "" },
  { report_name: "Urine", price: "" },
  { report_name: "Faeces", price: "" },
];

const FollowkitPricing = ({ mount, handleClose }) => {
  const [editKit, setEditKit] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      report_name: "",
      price: "",
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (!values.price)
        return toast.error(
          `Please add a valid price for ${values.report_name}`
        );
      if (values.id) {
        await handleUpdatePrice(values);
      } else {
        await handleCreateNewPrice(values);
      }
      actions.resetForm();
      return setEditKit(undefined);
    },
  });

  const { data: followKitPricings, isLoading } = useGetFollowKitPricingsQuery(
    undefined,
    { skip: !mount, refetchOnFocus: true }
  );

  const [newFollowKitPrice, { isLoading: addingPrice }] =
    useNewFollowitPriceMutation();

  const [updateFollowKitPrice, { isLoading: updatingPrice }] =
    useUpdateFollowitPriceMutation();

  const handleCreateNewPrice = async (values) => {
    const notify = toast.loading("Adding followkit price..");
    try {
      const res = await newFollowKitPrice(values)
        .unwrap()
        .then((res) => {
          if (res) {
            return toast.success("Added followkit price", { id: notify });
          }
        });
      return res;
    } catch (error) {
      toast.error("Failed to add price", { id: notify });
    }
  };

  const handleUpdatePrice = async (values) => {
    const notify = toast.loading("updating followkit price..");
    try {
      const res = await updateFollowKitPrice(values)
        .unwrap()
        .then((res) => {
          if (res) {
            return toast.success("updated followkit price", { id: notify });
          }
        });
      return res;
    } catch (error) {
      toast.error("Failed to update price", { id: notify });
    }
  };

  const comparedFollowkitPriceList = useMemo(() => {
    return followKitPricings?.reduce(
      (accumulator, current, index) => {
        const foundIndex = accumulator.findIndex((prevItem) => {
          return prevItem.report_name == current.report_name;
        });
        foundIndex >= 0 && accumulator.splice(foundIndex, 1, current);
        return accumulator;
      },
      [...kits]
    );
  }, [followKitPricings]);

  if (isLoading)
    return (
      <div className="ldngWrpr">
        <CircularProgress />
      </div>
    );

  return (
    <CustomDialog avoidBG open={mount} maxWidth="sm" handleClose={handleClose}>
      <h6 className="fw-bold mb-4">Follow Kit - Pricings</h6>

      <div className="row">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>S.No.</th>
                <th style={{ width: "40%" }}>Kit Name</th>
                <th style={{ width: "40%" }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {comparedFollowkitPriceList?.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.report_name}</td>
                  <td>
                    <div className="d-flex gap-3 justify-content-between align-items-center">
                      {data.price &&
                        editKit !== index &&
                        currencyFomatter(data.price)}
                      {editKit !== index ? (
                        <Button
                          onClick={() => {
                            formik.setValues(data);
                            setEditKit(index);
                          }}
                          variant="outlined"
                          color="primary"
                          className={`${!data.price ? "w-100" : ""}`}
                        >
                          {data?.price ? "Edit Price" : "Add new price"}
                        </Button>
                      ) : (
                        <>
                          <div>
                            <form
                              className="d-flex gap-2 align-items-center"
                              onSubmit={formik.handleSubmit}
                            >
                              <Input
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="price"
                                type="number"
                                placeholder="new Price"
                              />

                              <IconButton
                                disabled={addingPrice || updatingPrice}
                                type="submit"
                                color="primary"
                              >
                                <Done />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditKit(undefined)}
                                color="error"
                              >
                                <Close />
                              </IconButton>
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CustomDialog>
  );
};

export default FollowkitPricing;
