import { CircularProgress, Divider } from "@mui/material";
import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import { baseUrl } from "../../services/api/api";
import { useGetOrderedProductByIdQuery } from "../../services/products/ProductsApi";
import { currencyFomatter } from "../../Utils/Utils";

const addressContructor = (keys) => {
  let validKeys = new Array();
  keys.map((key) => !!key?.length && validKeys.push(key));

  return validKeys.join(", ");
};

const ViewOrder = ({ mount, handleClose, data }) => {
  const { data: order, isLoading } = useGetOrderedProductByIdQuery(data?.id, {
    skip: !data?.id,
  });

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="order_modal">
      <h5>
        Order ID: <span>{order?.id}</span>
      </h5>
      <div className="order_header">
        <div className="order_hdr_info ">
          <p>
            Order Date: <span>{order?.date}</span>
          </p>
          <Divider orientation="vertical" className="" />
          <p>
            Payment:{" "}
            <span
              className={`text-capitalize ${
                order?.payment_status === "unpaid"
                  ? "text-warning"
                  : "text-success"
              }`}
            >
              {order?.payment_status}
            </span>
          </p>
        </div>

        <CustomButton
          onClick={() =>
            window.open(`${baseUrl}/api/orders/pdf/${order?.e_id}`, "_blank")
          }
          label={
            <>
              <span className="material-symbols-rounded me-2 fs-5">
                receipt_long
              </span>
              Invoice
            </>
          }
          size={"small ms-auto"}
        />
      </div>

      <Divider />

      <div className="order_items_list">
        {order?.orderitems?.map((item, idx) => (
          <div className="order_item" key={idx}>
            <div className="order_item_img">
              <img
                src={item?.product?.productimages[0]?.product_image}
                alt=""
              />
            </div>
            <div className="order_item_name">
              <h5>{item.product.product_name}</h5>
              <p className="text-muted mb-0">
                Prcie: {currencyFomatter(item.price)} | Qty: {item.quantity}
              </p>
            </div>

            <h5 className="order_item_price">
              {currencyFomatter(item.total_price)}
            </h5>
          </div>
        ))}
        {!!order?.discount_amount && (
          <>
            <div className="order_item py-3">
              <p className="mb-0 flex-fill">Order Amount</p>{" "}
              <b>{currencyFomatter(order?.original_amount)}</b>
            </div>
            <div className="order_item py-3">
              <p className="mb-0 flex-fill">Coupon Savings</p>{" "}
              <b>-{currencyFomatter(order?.discount_amount)}</b>
            </div>
          </>
        )}
        <div className="order_item py-3">
          <p className="mb-0 flex-fill">Order Total</p>{" "}
          <b>{currencyFomatter(order?.total_price)}</b>
        </div>
      </div>
      <h6 className="border-bottom py-2">Delivery Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Name</p>
          <p className="text-capitalize">{`${order?.shipping?.first_name} ${order?.shipping?.last_name}`}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Email</p>
          <p>{order?.shipping?.email}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Mobile</p>
          <p>{order?.shipping?.phone}</p>
        </div>
        <div className="or_info">
          <p className="text-muted ">Shipping Address</p>
          <p>
            {addressContructor([
              order?.shipping?.address,
              order?.shipping?.city,
              order?.shipping?.state,
              order?.shipping?.country,
              order?.shipping?.pincode,
            ])}
          </p>
          {order?.shipping?.landmark && (
            <p>Landmark - {order?.shipping?.landmark}</p>
          )}
        </div>
      </div>
      <h6 className="border-bottom py-2">Transactions Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Payment Mode</p>
          <p>{order?.transaction?.payment_type || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction ID</p>
          <p>{order?.transaction?.transaction_id || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction Amount</p>
          <p>
            {order?.transaction?.amount
              ? currencyFomatter(order?.transaction?.amount)
              : "-"}
          </p>
        </div>
        <div className="or_info">
          <p className="text-muted">Transaction Date</p>
          <p>{order?.transaction?.transaction_date || "-"}</p>
        </div>
      </div>
      <h6 className="border-bottom py-2">Shipment Details</h6>
      <div className="or_info_wrapper">
        <div className="or_info">
          <p className="text-muted">Shipment provider</p>
          <p>{order?.shipment_provider || "-"}</p>
        </div>
        <div className="or_info">
          <p className="text-muted">Shipment code</p>
          <p>{order?.shipment_code || "-"}</p>
        </div>

        <div className="or_info">
          <p className="text-muted">Track Your Package</p>
          <a href={order?.trackingurl} target="_blank">
            {order?.trackingurl || "-"}
          </a>
        </div>
      </div>

      <CustomButton
        label={"Go Back!"}
        size={"mt-4"}
        onClick={() => handleClose()}
      />
    </div>
  );
};

export default ViewOrder;
