import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PdfViewer from "../../../Components/Molecules/PdfViewer";
import PropTypes from "prop-types";
import { assetUrlBuilder } from "../../../Utils/Utils";
import MeterGraph from "../../../Components/Molecules/Graph/MeterGraph/MeterGraph";
import { useLazyGetReportByIdQuery } from "../../../services/reports/reportsApi";
import LineBar from "../../../Components/Molecules/Graph/LineBarGraph/LineBar";
import MultiLineBarGraph from "../../../Components/Molecules/Graph/MultiLineBarGraph/MultiLineGraph";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewGutReport = ({ reports, excelId }) => {
  const [value, setValue] = React.useState(0);
  const [getReportById, { data: Excelreport, isLoading }] =
    useLazyGetReportByIdQuery(excelId, {
      skip: !excelId,
    });
  useEffect(() => {
    (async () => {
      await getReportById(excelId);
    })();
  }, []);

  //   const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          <Tab label="Summary" {...a11yProps(0)} />
          <Tab label="Food" {...a11yProps(1)} />
          <Tab label="Supplements" {...a11yProps(2)} />
          <Tab label="Exercise" {...a11yProps(3)} />
          <Tab label="Cognitive" {...a11yProps(4)} />
          <Tab label="Wellbeing" {...a11yProps(5)} />
          <Tab label="Super Organism" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.summary_report)}
            reportOnly={true}
          /> */}

          {Excelreport?.summary_phenotype ||
          Excelreport?.summary_phenotype_elements ? (
            <>
              {Excelreport?.summary_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.summary_phenotype?.heading}
                    Graph_value={Excelreport?.summary_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.summary_phenotype?.heading}
                    Graph_value={Excelreport?.summary_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.summary_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.summary_phenotype_elements?.data}
                  heading={Excelreport?.summary_phenotype_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.food_report)}
            reportOnly={true}
          /> */}

          {Excelreport?.food_phenotype ||
          Excelreport?.food_phenotype_elements ? (
            <>
              {Excelreport?.food_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.food_phenotype?.heading}
                    Graph_value={Excelreport?.food_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.food_phenotype?.heading}
                    Graph_value={Excelreport?.food_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.food_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.food_phenotype_elements?.data}
                  heading={Excelreport?.food_phenotype_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.supplement_report)}
            reportOnly={true}
          /> */}
          {Excelreport?.supplement_phenotype ||
          Excelreport?.supplement_phenotype_elements ? (
            <>
              {Excelreport?.supplement_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.supplement_phenotype?.heading}
                    Graph_value={Excelreport?.supplement_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.supplement_phenotype?.heading}
                    Graph_value={Excelreport?.supplement_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.supplement_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.supplement_phenotype_elements?.data}
                  heading={Excelreport?.supplement_phenotype_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.exercise_report)}
            reportOnly={true}
          /> */}

          {Excelreport?.exercise_recovery_phenotype ||
          Excelreport?.exercise_recovery_phenotype_elements ? (
            <>
              {Excelreport?.exercise_recovery_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.exercise_recovery_phenotype?.heading}
                    Graph_value={
                      Excelreport?.exercise_recovery_phenotype?.data[0]
                    }
                  />
                  <LineBar
                    heading={Excelreport?.exercise_recovery_phenotype?.heading}
                    Graph_value={
                      Excelreport?.exercise_recovery_phenotype?.data[1]
                    }
                  />
                </>
              )}
              {Excelreport?.exercise_recovery_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.exercise_recovery_phenotype_elements?.data}
                  heading={
                    Excelreport?.exercise_recovery_phenotype_elements?.heading
                  }
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.cognitive_report)}
            reportOnly={true}
          /> */}
          {Excelreport?.cognitive_phenotype ||
          Excelreport?.cognitive_phenotype_elements ? (
            <>
              {Excelreport?.cognitive_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.cognitive_phenotype?.heading}
                    Graph_value={Excelreport?.cognitive_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.cognitive_phenotype?.heading}
                    Graph_value={Excelreport?.cognitive_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.cognitive_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.cognitive_phenotype_elements?.data}
                  heading={Excelreport?.cognitive_phenotype_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.wellbeing_report)}
            reportOnly={true}
          /> */}

          {Excelreport?.wellbeing_phenotype ||
          Excelreport?.wellbeing_phenotype_elements ? (
            <>
              {Excelreport?.wellbeing_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.wellbeing_phenotype?.heading}
                    Graph_value={Excelreport?.wellbeing_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.wellbeing_phenotype?.heading}
                    Graph_value={Excelreport?.wellbeing_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.wellbeing_phenotype_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.wellbeing_phenotype_elements?.data}
                  heading={Excelreport?.wellbeing_phenotype_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <div>
          {/* <PdfViewer
            fileUrl={assetUrlBuilder(reports?.superorganism_report)}
            reportOnly={true}
          /> */}

          {Excelreport?.super_organism_phenotype ||
          Excelreport?.super_organism_elements ? (
            <>
              {Excelreport?.super_organism_phenotype && (
                <>
                  <MeterGraph
                    heading={Excelreport?.super_organism_phenotype?.heading}
                    Graph_value={Excelreport?.super_organism_phenotype?.data[0]}
                  />
                  <LineBar
                    heading={Excelreport?.super_organism_phenotype?.heading}
                    Graph_value={Excelreport?.super_organism_phenotype?.data[1]}
                  />
                </>
              )}
              {Excelreport?.super_organism_elements && (
                <MultiLineBarGraph
                  data={Excelreport?.super_organism_elements?.data}
                  heading={Excelreport?.super_organism_elements?.heading}
                />
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              No data found
            </div>
          )}
        </div>
      </TabPanel>
    </Box>
  );
};

export default ViewGutReport;
