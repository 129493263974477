import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  useUpdateGutbiomicsReportMutation,
  useUploadGutbiomicsReportMutation,
} from "../../../services/reports/reportsApi";

const AddGutReport = ({ data, type, handleClose }) => {
  const [report, setReport] = useState({
    // food_report: null,
    // supplement_report: null,
    // exercise_report: null,
    // cognitive_report: null,
    // supplement_report: null,
    // wellbeing_report: null,
    // superorganism_report: null,
    // summary_report: null,
    xl_report: null,
  });
  const [fileInputErrors, setFileInputErrors] = useState({});

  const [uploadReport, { isLoading }] = useUploadGutbiomicsReportMutation();
  const [updateReport, { isLoading: isUpadating }] =
    useUpdateGutbiomicsReportMutation();

  const { id } = useParams();

  const formik = useFormik({
    initialValues: data || {
      report_name: "",
      date: new Date().toISOString().substring(0, 10),
    },
    validationSchema: yup.object({
      report_name: yup
        .string()
        .min(3, "Too short!")
        .required("Report name is required"),
    }),
    onSubmit: async (values) => {
      if (type === "new" && !report.xl_report)
        return toast.error("Please Select report before submit!");

      // if (type === "new" && !report.summary_report)
      //   return toast.error("Please Select summary report before submit!");

      if (
        // fileInputErrors.summary_report ||
        // fileInputErrors.food_report ||
        // fileInputErrors.supplement_report ||
        // fileInputErrors.exercise_report ||
        // fileInputErrors.cognitive_report ||
        // fileInputErrors.wellbeing_report ||
        // fileInputErrors.superorganism_report
        fileInputErrors.xl_report
      ) {
        return;
      }

      if (type === "new") {
        const formData = new FormData();
        formData.append("report_name", values.report_name);
        formData.append("date", values.date);
        formData.append("userId", id);

        for (const key in report) {
          report[key] && formData.append(key, report[key]);
        }

        try {
          const upload = await uploadReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Reports uploaded successfully");
                handleClose();
              }
            });
          return upload;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Failed to update report!");
          }
        }
      } else if (type === "edit") {
        const formData = new FormData();
        formData.append("report_name", values.report_name);
        formData.append("id", values.id);

        for (const key in report) {
          report[key] && formData.append(key, report[key]);
        }

        try {
          const upload = await updateReport(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Report Updated successfully");
                handleClose();
              }
            });
          return upload;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error?.data?.detail);
          } else {
            toast.error("Failed to update report!");
          }
        }
      }
    },
  });

  const handleReport = (e) => {
    const { name, files } = e.target;

    // if (files[0]) {
    //   console.log(files[0].type);
    //   if (files[0].type !== "application/pdf") {
    //     setFileInputErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: "Invalid file format. Only PDF files are allowed.",
    //     }));
    //   } else if (files[0].size > 5 * 1024 * 1024) {
    //     setFileInputErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: "File size should be less than 5MB.",
    //     }));
    //   } else {
    //     setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    //   }
    // } else {
    //   setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // }

    // EXCEL UPLOAD FILE

    if (files[0]) {
      const allowedExtensions = ["xlsx", "xls"];
      const fileExtension = files[0].name.split(".").pop().toLowerCase();
      console.log(fileExtension);

      if (!allowedExtensions.includes(fileExtension)) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid file format. Only XLSX and XLS files are allowed.",
        }));
      } else if (files[0].size > 5 * 1024 * 1024) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 5MB.",
        }));
      } else {
        setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        setReport((pre) => ({ ...pre, [name]: files[0] }));
      }
    } else {
      setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    return setReport((pre) => {
      return { ...pre, [name]: files[0] };
    });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="p-4">
        <div className="row">
          <div className="col-md-12">
            <CustomInput
              label="Title"
              value={formik.values.report_name}
              name="report_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
            />
          </div>
          <div className="col-md-6 mb-4">
            <h6>Upload Reports</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.xl_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="xl_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept=".xlsx, .xls"
                hidden
                multiple
              />
              {fileInputErrors.xl_report && (
                <div className="errMsgs">{fileInputErrors.xl_report}</div>
              )}
            </label>
          </div>
          {/* <div className="col-md-6 mb-4">
            <h6>Summary</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.summary_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="summary_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.summary_report && (
                <div className="errMsgs">{fileInputErrors.summary_report}</div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Food</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.food_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="food_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.food_report && (
                <div className="errMsgs">{fileInputErrors.food_report}</div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Supplements</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.supplement_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="supplement_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.supplement_report && (
                <div className="errMsgs">
                  {fileInputErrors.supplement_report}
                </div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Exercise</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.exercise_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="exercise_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.exercise_report && (
                <div className="errMsgs">{fileInputErrors.exercise_report}</div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Cognitive</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.cognitive_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="cognitive_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.cognitive_report && (
                <div className="errMsgs">
                  {fileInputErrors.cognitive_report}
                </div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Wellbeing</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>{report.wellbeing_report?.name || "Upload your report"}</p>
              </div>
              <input
                name="wellbeing_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.wellbeing_report && (
                <div className="errMsgs">
                  {fileInputErrors.wellbeing_report}
                </div>
              )}
            </label>
          </div>
          <div className="col-md-6 mb-4">
            <h6>Super Organism</h6>
            <label className="d-flex flex-column">
              <div className="upldWrpr text-center w-100">
                <span className="material-symbols-rounded">cloud_upload</span>
                <p>
                  {report.superorganism_report?.name || "Upload your report"}
                </p>
              </div>
              <input
                name="superorganism_report"
                onChange={(e) => handleReport(e)}
                type="file"
                accept="application/pdf"
                hidden
                multiple
              />
              {fileInputErrors.superorganism_report && (
                <div className="errMsgs">
                  {fileInputErrors.superorganism_report}
                </div>
              )}
            </label>
          </div> */}
          <div className="col-md-12 mt-4">
            <div className="flscrnBtnWrpr d-flex align-items-center justify-content-end gap-2">
              <CustomButton
                label="Cancel"
                btnType="secondary"
                onClick={handleClose}
              />
              <CustomButton
                disabled={isLoading || isUpadating}
                type="submit"
                label={isLoading || isUpadating ? "submitting.." : "submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddGutReport;
