import React, { useEffect, useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import CustomCheckBox from "../../../Components/Atoms/CustomCheckBox";
import {
  useCreateExcelsMutation,
  useGetExcelsByIdQuery,
  useUpdateExcelsMutation,
} from "../../../services/settings/sampleExcelApi";

const validationSchema = yup.object().shape({
  report_name: yup
    .string()

    .matches(
      /[a-zA-Z]/,
      "Name should not contain only numbers or special characters"
    )
    .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed")

    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("Name is required"),
});

const AddSampleExcel = ({ type, data, mount, handleClose }) => {
  const [fileInputErrors, setFileInputErrors] = useState({});
  const [report, setReport] = useState(null);

  const [uploadExcel, { isLoading }] = useCreateExcelsMutation();
  const [updateExcel, { isLoading: isUpdate }] = useUpdateExcelsMutation();

  const { data: selectedExcel } = useGetExcelsByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      report_name: "",
      default: true,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (type === "new" && !report)
        return toast.error("Please Select report before submit!");
      if (fileInputErrors?.report) {
        return;
      }
      if (type === "new") {
        const formData = new FormData();
        formData.append("report_name", values.report_name);
        formData.append("default", values.default);
        for (const key in report) {
          report[key] && formData.append(key, report[key]);
        }

        try {
          const upload = await uploadExcel(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Excel uploaded successfully");
                formik.resetForm();
                setReport(null);
                handleClose();
              }
            });
          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to uploads Excel!");
        }
      } else if (type === "edit") {
        const formData = new FormData();
        formData.append("report_name", values.report_name);
        formData.append("default", values.default);
        formData.append("id", data?.id);

        for (const key in report) {
          report[key] && formData.append(key, report[key]);
        }

        try {
          const upload = await updateExcel(formData)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Updated successfully");
                handleClose();
              }
            });
          return upload;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("report_name", selectedExcel?.report_name);
      formik.setFieldValue("default", selectedExcel?.default);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedExcel]);

  const handleReport = (e) => {
    const { name, files } = e.target;

    if (files[0]) {
      const allowedExtensions = ["xlsx", "xls"];
      const fileExtension = files[0].name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid file format. Only XLSX and XLS files are allowed.",
        }));
      } else if (files[0].size > 5 * 1024 * 1024) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 5MB.",
        }));
      } else {
        setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        setReport((pre) => ({ ...pre, [name]: files[0] }));
      }
    } else {
      setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    return setReport((pre) => {
      return { ...pre, [name]: files[0] };
    });
  };

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <div className="circle_back mb-2">
          <span className="material-symbols-rounded">article</span>
        </div>
        <h4 className="mb-2">{type !== "new" ? `Edit Excel` : "Add Excel"}</h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Name"
                name="report_name"
                value={formik.values.report_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="upld_report" className="d-flex flex-column">
                <div className="upldWrpr text-center w-100">
                  <span className="material-symbols-rounded">cloud_upload</span>
                  <p>
                    {report?.report
                      ? report.report?.name
                      : "Upload your report"}
                  </p>
                </div>
                <input
                  onChange={handleReport}
                  type="file"
                  id="upld_report"
                  accept=".xlsx, .xls"
                  hidden
                  multiple
                  name="report"
                />
                {fileInputErrors.report && (
                  <div className="errMsgs">{fileInputErrors.report}</div>
                )}
              </label>
            </div>
            <div className="col-md-12 mb-3">
              <CustomCheckBox
                name="default"
                label="Default"
                onChange={formik.handleChange}
                checked={formik.values.default === true}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddSampleExcel;
