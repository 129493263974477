import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const CustomDropdown = ({ idx, menuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(undefined);
  const open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(undefined);
  };
  return (
    <>
      <IconButton
        className="tblIconBtn"
        id="demo-customized-button"
        aria-controls={open ? `demo-customized-menu-${idx}` : undefined}
        aria-haspopup="true"
        aria-expanded={
          open && menuIndex === `demo-customized-menu-${idx}`
            ? "true"
            : undefined
        }
        onClick={(e) => handleClick(e, `demo-customized-menu-${idx}`)}
      >
        <MoreVert />
      </IconButton>

      <StyledMenu
        id={`demo-customized-menu-${idx}`}
        MenuListProps={{
          "aria-labelledby": `demo-customized-menu-${idx}`,
        }}
        anchorEl={anchorEl}
        open={menuIndex === `demo-customized-menu-${idx}`}
        onClose={handleClose}
      >
        {menuItems?.map(
          (menu, idx) =>
            !menu.hidden && (
              <div key={idx} className="tblMenuWrapper">
                <CstmMenuItem
                  handleClose={handleClose}
                  label={menu.label}
                  handleMenuClick={menu.handleMenuClick}
                  disabled={menu.disabled}
                />
              </div>
            )
        )}
      </StyledMenu>
    </>
  );
};

export default CustomDropdown;

const CstmMenuItem = ({ handleClose, label, handleMenuClick, disabled }) => {
  const handleClick = () => {
    handleMenuClick();
    handleClose();
  };
  return (
    <>
      <MenuItem
        disabled={disabled}
        disableTouchRipple
        onClick={handleClick}
        disableRipple
        className="tblMenuItem"
      >
        {label}
      </MenuItem>
    </>
  );
};
