import { appApi } from "../api/api";

export const ExcelReport = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersExcel: builder.mutation({
      query: (value) => ({
        url: `/api/admin/${value.apiType}`,
        method: "POST",
        body: value,
      }),
    }),
  }),
});

export const { useGetUsersExcelMutation } = ExcelReport;
