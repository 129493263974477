import { appApi } from "../api/api";

export const sampleExcelApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getExcelsList: builder.query({
      query: () => `api/admin/samplexl`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SampleExcel",
              ...result.map(({ id }) => ({ type: "SampleExcel", id })),
            ]
          : ["SampleExcel"],
    }),
    getExcelList: builder.query({
      query: () => `api/admin/samplexl`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "SampleExcel",
              ...result.map(({ id }) => ({ type: "SampleExcel", id })),
            ]
          : ["SampleExcel"],
    }),
    getExcelsBySearch: builder.query({
      query: (values) => ({
        url: `api/ admin/samplexl/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SampleExcel",
              ...result?.report?.map(({ id }) => ({
                type: "SampleExcel",
                id,
              })),
            ]
          : ["SampleExcel"],
    }),
    getExcelsById: builder.query({
      query: (id) => `/api/admin/samplexl/${id}`,
      providesTags: (result, error, arg) => [{ type: "SampleExcel", id: arg }],
    }),
    createExcels: builder.mutation({
      query: (values) => ({
        url: `/api/admin/samplexl/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["SampleExcel"],
    }),

    updateExcels: builder.mutation({
      query: (values) => ({
        url: `/api/admin/samplexl/${values.get("id")}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["SampleExcel"],
    }),

    deleteExcels: builder.mutation({
      query: (id) => ({
        url: `/api/admin/samplexl/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SampleExcel"],
    }),
  }),
});

export const {
  useGetExcelsByIdQuery,
  useGetExcelsListQuery,
  useGetExcelListQuery,
  useCreateExcelsMutation,
  useDeleteExcelsMutation,
  useUpdateExcelsMutation,
  useLazyGetExcelsBySearchQuery,
} = sampleExcelApi;
