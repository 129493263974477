import React from "react";
import { useGetUsersExcelMutation } from "../../../services/ExcelReport.js";
import { saveAs } from "file-saver";
import CustomButton from "../../Atoms/CustomButton/index.js";
import { toast } from "react-hot-toast";
import { useGetUsersSpExcelMutation } from "../../../services/SpExcelReport/SpExcelReport.js";

const SingleExport = ({ type, from_date, to_date, size, apiType, id }) => {
  const [exportReport, { isLoading }] = useGetUsersSpExcelMutation();

  const handleApi = async () => {
    let values = {
      id,
      apiType:
        type === "employee" ||
        type === "professional" ||
        type === "doctor" ||
        type === "team_manager" ||
        type === "sport_person" ||
        type === "patient"
          ? "export_sp"
          : apiType,
      type: type,
      from_date: from_date ?? "",
      to_date: to_date ?? "",
    };
    let date = new Date();
    let { data } = await exportReport(values);
    if (data != undefined) {
      let file = new Blob([data?.data], {
        type: "text/csv;charset=utf-8",
      });
      console.log(type, "type");
      saveAs(file, `${id}${date.getDate()}${date.getTime()}.csv`);
      toast.success("Data exported successfully!");
    } else {
      toast.error("Something went wrong! please try after sometime");
    }
  };
  return (
    <>
      <CustomButton
        disabled={isLoading}
        onClick={() => handleApi()}
        btnType="secondary"
        btnClass="exprtBtn"
        size={size}
        label={
          <>
            <span className="material-symbols-rounded me-2">
              cloud_download
            </span>{" "}
            Export
          </>
        }
      />
    </>
  );
};

export default SingleExport;
