import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Button, IconButton } from "@mui/material";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import MultipleSelect from "../../Components/Atoms/MultipleSelect";

const Grouping = () => {
  const navigate = useNavigate();

  const [inviteDialog, setInviteDialog] = useState(false);

  const validationArray = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    uname: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    pass: Yup.string().min(2, "Too Short!"),
    rememberme: 0,
  });

  const formik = useFormik({
    initialValues: {
      userType: "doctor",
      first_name: "",
      last_name: "",
      uname: "",
      pass: "",
      rememberme: "",
      team_group: [],
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      navigate("/registerSuccess");
    },
  });

  console.log("formik value", formik);

  const groupData = [
    {
      name: "Athelete",
      group_count: "12",
    },
    {
      name: "Male",
      group_count: "12",
    },
    {
      name: "Female",
      group_count: "12",
    },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                <span className="material-symbols-rounded me-2">groups</span>
                Grouping
              </h5>
              <Button className="brdrBtn" onClick={() => setInviteDialog(true)}>
                <span className="material-symbols-rounded">group_add</span>
                Add New Group
              </Button>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Group Name</th>
                    <th>No. of People</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {groupData.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.group_count}</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <CustomButton
                            label={
                              <>
                                <span className="material-symbols-rounded me-2">
                                  open_in_new
                                </span>
                                View
                              </>
                            }
                            btnType="secondary"
                            size="small"
                            btnclassName="w-100 me-2"
                          />
                          <CustomButton
                            label={
                              <>
                                <span className="material-symbols-rounded me-2">
                                  edit
                                </span>
                                Edit
                              </>
                            }
                            btnType="secondary"
                            size="small"
                            btnclassName="w-100"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={inviteDialog}
        handleClose={() => setInviteDialog(false)}
      >
        <div className="text-center">
          <img src="/assets/svg/invite.svg" className="mb-3" />
          <h3 className="mb-2">Grouping</h3>
          <p className="">Group your team</p>
          <form className="mt-5">
            <div className="row text-start">
              <div className="col-md-12">
                <CustomInput
                  label="Group Name"
                  placeholder="Enter group name"
                  type="text"
                  name="group_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.group_name}
                  formik={formik}
                />
              </div>
              <div className="col-md-12">
                <MultipleSelect
                  label="Team Members"
                  name="team_group"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.team_group}
                  formik={formik}
                  options={[
                    {
                      value: "Sport Person",
                      name: "Sport Person",
                    },
                    {
                      value: "Professional",
                      name: "Professional",
                    },
                    {
                      value: "Team Manager",
                      name: "Team Manager",
                    },
                    {
                      value: "Doctor",
                      name: "Doctor",
                    },
                  ]}
                />
              </div>
              <div className="col-md-12">
                <CustomButton
                  label="Create Group"
                  onClick={() => setInviteDialog(false)}
                />
              </div>
            </div>
          </form>
        </div>
      </CustomDialog>
    </>
  );
};

export default Grouping;
