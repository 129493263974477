import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import {
  DeleteOutline,
  Download,
  EditOutlined,
  OpenInNew,
} from "@mui/icons-material";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import AddSampleExcel from "./AddSampleExcel";
import {
  useDeleteExcelsMutation,
  useGetExcelListQuery,
} from "../../../services/settings/sampleExcelApi";
import AddIbanNum from "../IBINnumber/AddIbanNum";
import { baseUrl } from "../../../services/api/api";

const SampleExcel = () => {
  const { data: list, isError, isLoading } = useGetExcelListQuery();

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Name", key: "report_name" },
    { name: "Default", key: "default" },
    { name: "Action", sortable: false },
  ];

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
    type: "new",
  });

  const [deleteExcel, { isLoading: deleting }] = useDeleteExcelsMutation();

  const handleDelete = async (data) => {
    return confirmDialog("Are you sure to delete this Excel!", async () => {
      const notify = toast.loading("Deleting Excel..");
      try {
        return await deleteExcel(data.id)
          .unwrap()
          .then(() => {
            toast.success("Excel deleted successfully", { id: notify });
          });
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete Excel!", { id: notify });
      }
    });
  };

  const handleShowPdf = (data) => {
    const fileUrl = `${baseUrl}${data?.report}`;

    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "developedxl.xlsx";

    downloadLink.click();
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="material-symbols-rounded text-muted me-2">
                    article
                  </span>
                  Sample Excel
                </h3>

                <CustomButton
                  size="small"
                  label={
                    <>
                      <span className="material-symbols-rounded me-2">add</span>
                      Add Excel
                    </>
                  }
                  onClick={() =>
                    setAddPopup({
                      mount: true,
                      data: null,
                      type: "new",
                    })
                  }
                />
              </div>

              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>{name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.length &&
                      list?.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>{data.report_name}</td>
                          <td>{data.default ? "True" : "False"}</td>
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <Download />
                                      DownLoad Excel
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return handleShowPdf(data, true);
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <EditOutlined />
                                      Edit
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return setAddPopup({
                                      mount: true,
                                      data: data,
                                      type: "edit",
                                    });
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <DeleteOutline />
                                      Delete
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return handleDelete(data);
                                  },
                                  disabled: deleting,
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.length
                    ? "Failed to load records!"
                    : !list?.length && "No Records Found!"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddSampleExcel
        {...addPopup}
        handleClose={() =>
          setAddPopup({
            mount: false,
            data: null,
            type: "new",
          })
        }
      />
      <ConfirmDialogPopper />
    </>
  );
};

export default SampleExcel;
