import React, { useEffect } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  useLazyResendOtpQuery,
  useTwoFactorAuthMutation,
} from "../../services/auth/authApi";
import {
  set2FACredentials,
  setAccessToken,
} from "../../features/auth/authSlice";
import Unauthorized from "../ErrorScreens/Unauthorized";

const TwoFactor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tempCredentials = useSelector(
    (state) => state.auth.TwoFactorCredentials
  );

  const [twoFactorAuth, { isLoading }] = useTwoFactorAuthMutation();
  const [otpResend, otpResult] = useLazyResendOtpQuery();

  // useEffect(() => {
  //   if (tempCredentials === null) navigate("/login");
  // }, [tempCredentials]);

  const maskedInfo = (info) => `****${info?.slice(4, info?.length)}`;

  const validationArray = Yup.object().shape({
    enter_otp: Yup.string().min(6, "Too Short!").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      enter_otp: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (tempCredentials === null) return toast.error("Please login before ");
      try {
        const response = await twoFactorAuth({
          user: tempCredentials?.id,
          ...values,
        })
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("You have successfully logged in!");
              dispatch(setAccessToken(res?.jwt));
              dispatch(set2FACredentials(null));
              navigate("/");
            }
          });

        return response;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail);
        } else {
          toast.error("Sorry, your login has failed.");
        }
        throw new Error(error);
      }
    },
  });

  const handleResend = (e) => {
    e.preventDefault();
    otpResend(tempCredentials?.id)
      .unwrap()
      .then((res) => {
        if (res) {
          console.log("otp response", res);
          toast.success("OTP resent successfully!");
        }
      });
  };

  if (!tempCredentials) return <Unauthorized />;

  return (
    <AuthLayout showButton={false}>
      <div className="lgnFormContainer authWrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <h2>Two Factor Authentication</h2>
            <p>
              Please enter verification code sent to your email address &nbsp;
              <b>{maskedInfo(tempCredentials?.email)}</b> and to your mobile
              number ending with&nbsp;
              <b>{maskedInfo(tempCredentials?.mobile_no)}</b>
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="OTP"
                placeholder="Enter OTP Here"
                type="text"
                name="enter_otp"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.enter_otp}
                formik={formik}
              />
            </div>
            <div className="col-md-12 text-center">
              <CustomButton
                label={isLoading ? "Verifying otp..." : "Verify"}
                type="submit"
                disabled={isLoading}
              />
              <p className="mt-3 rdrTxt">
                Didn't receive your OTP yet?{" "}
                <Link
                  to="#"
                  onClick={(e) => handleResend(e)}
                  disabled={otpResult}
                >
                  {" "}
                  Resend
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default TwoFactor;
