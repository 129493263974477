import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  alpha,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import {
  useApproveFollowKitMutation,
  useGetFollowKitsQuery,
  useLazySearchFollowkitQuery,
  useUpdateFollowStatusMutation,
} from "../../services/followkit/followkitApi";
import { toast } from "react-hot-toast";
import CustomButton from "../../Components/Atoms/CustomButton";
import FollowkitForm from "./FollowkitForm";
import { baseUrl } from "../../services/api/api";
import FollowkitPricing from "./FollowkitPricing";
import CustomDropdown from "../../Components/Molecules/CustomDropdown";
import {
  DownloadOutlined,
  KeyboardArrowDown,
  LocalShippingOutlined,
  OpenInNew,
  PaidOutlined,
  ShoppingCart,
  SummarizeOutlined,
  Tune,
} from "@mui/icons-material";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import ShipmentForm from "../Orders/ShipmentForm";
import TransactionForm from "../Orders/TransactionForm";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { followStatus, handleSort } from "../../Utils/Utils";
import { useFormik } from "formik";
import ViewFollowOrder from "./ViewFollowOrder";
import { useDispatch, useSelector } from "react-redux";
import { addSearchValue } from "../../features/search/searchSlice";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import Export from "../../Components/Molecules/Export";
import SearchFollowkits from "./SearchFollowkits";
import * as yup from "yup";
import styled from "@emotion/styled";
import SingleFollowkitForm from "./SingleFollowkitForm";
import NewExport from "../../Components/Molecules/NewExport/NewExport";

const Followkits = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [dialog, setDialog] = useState({ mount: false, id: null });
  const [pricingDialog, setPricingDialog] = useState(false);
  const [updateFollowStatus, { isLoading: statusLoading }] =
    useUpdateFollowStatusMutation();
  const navigate = useNavigate();
  //search handlers

  const [
    getfollowkitBySearch,
    { data: followkit, isFetching, isLoading, isError },
  ] = useLazySearchFollowkitQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { followkit_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
        status: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
      from_date: "",
      to_date: "",
    },
    validationSchema: yup.object({
      filter: yup.object({
        date1: yup.date().max(new Date(), "Start date can't be after today"),
        date2: yup
          .date()
          .when(
            "date1",
            (start_date, schema) =>
              start_date &&
              schema.min(start_date, "End date can't be before start date")
          )
          .max(new Date(), "End date can't be after today"),
      }),
    }),
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      if (filterModal) setFilterModal(false);

      dispatch(addSearchValue({ name: "followkit_search", values }));

      const notify = toast.loading("Getting Followkits..");
      try {
        const query = await getfollowkitBySearch(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getfollowkitBySearch(previousFilter);
      } else {
        await getfollowkitBySearch(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);

    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1.length && count++;
    searchFormik.values.filter.date2.length && count++;
    searchFormik.values.filter.status.length && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  const [addShipmentPopup, setAddShipmentPopup] = useState({
    mount: false,
    data: null,
    type: "followKit",
  });

  const [addTranPopup, setAddTranPopup] = useState({
    mount: false,
    data: null,
    type: "followKit",
  });

  const handlePopupClose = () => setAddPopup({ mount: false, data: null });
  const handleShipPopupClose = () => {
    return setAddShipmentPopup({ mount: false, data: null });
  };
  const handleTranPopupClose = () => {
    return setAddTranPopup({ mount: false, data: null });
  };

  const handleFllowkitReportView = ({ id }) => {
    return setDialog({ mount: true, id: id });
  };

  const handleFllowkitReportClose = () => {
    return setDialog({ mount: false, id: null });
  };

  const handleChangeStatus = async (body) => {
    if (
      followkit?.report[followkit.report.findIndex((ele) => ele.id == body.id)]
        .shipment_code === "" &&
      body.follow_status == "2"
    ) {
      toast.error(`Please update shipment details!`);
    } else {
      try {
        await updateFollowStatus(body)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success(`Status updated successfully!`);
            }
          });
      } catch (error) {
        toast.error(`Failed to update status!`);
        console.log(error);
      }
    }
  };
  const [followKitDialog, setFollowKitDialog] = useState(false);

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Order ID", key: "id", sortable: true },
    { name: "Ordered By", sortable: false },
    { name: "Client Id", sortable: false },
    { name: "Kit Details", sortable: false },
    { name: "Requested Date", key: "date", sortable: true },
    { name: "Payment Status", key: "payment_status", sortable: true },
    { name: "Status", key: "follow_status", sortable: true },
    { name: "Action", sortable: false },
  ];

  console.log(user);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
              <h3>
                <span className="material-symbols-rounded me-2">
                  medical_services
                </span>
                Follow kits
              </h3>
              <div className="d-flex flex-fill gap-2 flex-wrap">
                <CustomSearch
                  inputName="search"
                  query={searchFormik.values.search}
                  handleChange={searchFormik.handleChange}
                  handleSubmit={(e) => {
                    e.preventDefault();
                    searchFormik.handleSubmit();
                    searchFormik.setFieldValue("page", 1);
                  }}
                  containerClasses="ms-md-auto small"
                  disabled={isFetching || isLoading}
                />

                <Badge badgeContent={handelBageContent} color="secondary">
                  <CustomButton
                    size="small"
                    btnType="secondary"
                    btnClass="exprtBtn"
                    onClick={() => setFilterModal(true)}
                    label={
                      <>
                        <Tune className="me-2" />
                        Filter
                      </>
                    }
                  />
                </Badge>

                {user?.department?.department_name === "lab" ? (
                  ""
                ) : (
                  <NewExport
                    type="followkit"
                    size="small"
                    values={{ ...searchFormik.values, model: "fkitorders" }}
                  />
                )}
                <Button
                  className="brdrBtn"
                  onClick={() => setFollowKitDialog(true)}
                >
                  <span className="material-symbols-rounded">
                    shopping_cart
                  </span>
                  Order kits
                </Button>
              </div>
            </div>
            <hr />
          </div>

          <div className="row">
            <div className="table-responsive">
              <table className="table flwKitTbl">
                <thead>
                  <tr>
                    {tableHeads.map(({ sortable, key, name }, idx) => (
                      <th key={idx}>
                        <div
                          className={`d-flex align-items-center ${
                            sortable ? "sortable" : ""
                          } ${
                            searchFormik.values.sort.includes(key)
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            if (sortable && !isLoading && !isFetching) {
                              return handleSort(searchFormik, key || "");
                            }
                          }}
                        >
                          {name}
                          {sortable && (
                            <span className="material-symbols-rounded">
                              {searchFormik.values.sort.includes(key)
                                ? searchFormik.values.sort.includes(`-${key}`)
                                  ? "expand_more"
                                  : "expand_less"
                                : "unfold_more"}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!!followkit?.report?.length &&
                    followkit.report.map((data, index) => (
                      <tr key={index} style={{ verticalAlign: "top" }}>
                        <td>{index + 1}</td>
                        <td>{data.id}</td>
                        <td>{data.fkitorderitems[0]?.followkit?.ordered_by}</td>
                        {console.log(data)}
                        <td>
                          {data.fkitorderitems[0]?.followkit
                            ?.account_subtype === "patient" ? (
                            <Link
                              to={`/users/patients/view/${data?.fkitorderitems[0]?.followkit?.user}`}
                            >
                              {data.fkitorderitems[0].followkit.user_ref_no}
                            </Link>
                          ) : (
                            <Link
                              to={`/users/sport-persons/view/${data?.fkitorderitems[0]?.followkit?.user}`}
                            >
                              {data?.fkitorderitems[0]?.followkit?.user_ref_no}
                            </Link>
                          )}
                        </td>
                        <td className="kitDtlsVw">
                          {data.fkitorderitems.map((fdata, index) => (
                            <>
                              <div className="kitDtlRw" key={index}>
                                <span>{fdata.followkit.req_id}</span>
                                <span className="pe-3">:</span>
                                <span>{fdata.followkit.kit_name}</span>
                              </div>
                            </>
                          ))}
                        </td>
                        <td>{data.date}</td>
                        <td>
                          <span
                            className={`status ${
                              data.payment_status === "unpaid"
                                ? "pending"
                                : "accepted"
                            }`}
                          >
                            {data.payment_status}
                          </span>
                        </td>
                        <td>
                          <CustomSelect
                            size="small"
                            options={followStatus}
                            name={`status_${data.index}`}
                            onChange={(e) =>
                              handleChangeStatus({
                                id: data.id,
                                follow_status: e.target.value,
                              })
                            }
                            value={data.follow_status}
                          />
                          {/* {data?.status === "close" ? (
                            <span className={"status accepted"}>Sent</span>
                          ) : (
                            <CustomButton
                              disabled={approving}
                              onClick={() => handleApproveFollowkit(data)}
                              label={"Send"}
                              size="small"
                            />
                          )} */}
                        </td>
                        <td>
                          {/* <div className="d-flex align-items-center">
                            <IconButton
                              onClick={() =>
                                window.open(
                                  `${baseUrl}/api/qr_make/${data.id}`,
                                  "_blank"
                                )
                              }
                              className="ms-2 tblIconBtn"
                            >
                              <span className="material-symbols-rounded">
                                download
                              </span>
                            </IconButton>
                            {data.submitfollowupkit && (
                              <IconButton
                                onClick={() => handleFllowkitReportView(data)}
                                className="ms-2 tblIconBtn"
                              >
                                <span className="material-symbols-rounded">
                                  lab_profile
                                </span>
                              </IconButton>
                            )}
                          </div> */}
                          <div className="d-flex align-items-center">
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <PaidOutlined />
                                      Payment Approval
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    setAddTranPopup({
                                      mount: true,
                                      data: data,
                                      type: "followKit",
                                    });
                                  },
                                  hidden:
                                    data.payment_status === "paid" ||
                                    user?.department?.department_name === "lab",
                                },
                                {
                                  label: (
                                    <>
                                      <DownloadOutlined />
                                      Download Label
                                    </>
                                  ),
                                  handleMenuClick: () =>
                                    window.open(
                                      `${baseUrl}/api/qr_make/${data.id}`,
                                      "_blank"
                                    ),
                                  // hidden: data.payment_status === "paid",
                                },
                                {
                                  label: (
                                    <>
                                      <LocalShippingOutlined />
                                      Shipment
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    setAddShipmentPopup({
                                      mount: true,
                                      data: data,
                                      type: "followKit",
                                    });
                                  },
                                  hidden: data.order_status === "closed",
                                },
                                {
                                  label: (
                                    <>
                                      <SummarizeOutlined />
                                      View Report
                                    </>
                                  ),
                                  handleMenuClick: () =>
                                    handleFllowkitReportView(data),
                                  hidden: !data.submitfollowupkit,
                                },
                                {
                                  label: (
                                    <>
                                      <OpenInNew />
                                      View
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    setAddPopup({
                                      mount: true,
                                      data: data,
                                      type: "followKit",
                                    });
                                  },
                                },
                              ]}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="tblFtrActs">
              <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                {isLoading
                  ? "Fetching records.."
                  : isError && !followkit?.report?.length
                  ? "Failed to load records!"
                  : !followkit?.report?.length && "No Records Found!"}
              </p>
              <div>
                <TablePagination
                  component="div"
                  count={followkit?.count || 0}
                  page={searchFormik.values.page - 1}
                  onPageChange={handleChangePage}
                  rowsPerPage={searchFormik.values.list_per_page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {dialog.mount && (
        <FollowkitForm {...dialog} handleClose={handleFllowkitReportClose} />
      )}
      {pricingDialog && (
        <FollowkitPricing
          mount={pricingDialog}
          handleClose={() => setPricingDialog(false)}
        />
      )}
      {addPopup.mount && (
        <CustomDialog
          avoidBG
          open={addPopup.mount}
          handleClose={handlePopupClose}
        >
          <ViewFollowOrder {...addPopup} handleClose={handlePopupClose} />
        </CustomDialog>
      )}
      {addShipmentPopup.mount && (
        <CustomDialog
          open={addShipmentPopup.mount}
          handleClose={handleShipPopupClose}
        >
          <ShipmentForm
            {...addShipmentPopup}
            handleClose={handleShipPopupClose}
          />
        </CustomDialog>
      )}
      {addTranPopup.mount && (
        <CustomDialog
          open={addTranPopup.mount}
          handleClose={handleTranPopupClose}
        >
          <TransactionForm
            {...addTranPopup}
            handleClose={handleTranPopupClose}
          />
        </CustomDialog>
      )}

      {filterModal && (
        <SearchFollowkits
          formik={searchFormik}
          open={filterModal}
          handleSubmit={() => getfollowkitBySearch(searchFormik.values)}
          handleClose={() => setFilterModal(false)}
        />
      )}
      <SingleFollowkitForm
        dialog={followKitDialog}
        setDialog={setFollowKitDialog}
      />
    </>
  );
};

export default Followkits;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
