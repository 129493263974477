import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useGetPaymentUsersListQuery,
  useGetUsersListQuery,
  usePaymentApprovalMutation,
} from "../../../services/Users/usersApi";
import { getTransformedRoleLabel } from "../../../Utils/Utils";
import ViewUserApproval from "./ViewUserApproval";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toast } from "react-hot-toast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsersApproval = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const { data, isLoading: usersLoading } = useGetUsersListQuery(null, {
    skip: value != 0,
    refetchOnFocus: true,
  });

  const { data: paymentPending, isLoading: pendingPayLoad } =
    useGetPaymentUsersListQuery(null, {
      skip: value != 1,
      refetchOnFocus: true,
    });

  const [paymentApproval, { isLoading }] = usePaymentApprovalMutation();

  const list = data?.filter((user) => user.status === "pending");

  const [userInfoDialog, setUserInfoDialog] = useState({
    mount: false,
    data: null,
  });

  const handleApproveDialogOpen = (data) => {
    return setUserInfoDialog({
      mount: true,
      data: data,
    });
  };

  const handleApproveDialogClose = () => {
    return setUserInfoDialog({
      mount: false,
      data: null,
    });
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handlePaymentApproval = async (data) => {
    console.log("data", data.id);
    await paymentApproval(data.id)
      .unwrap()
      .then((res) => {
        if (res.success == true) {
          toast.success("User payment status approved successfully!");
        } else {
          toast.error("Something went wrong! please try after sometime");
        }
      });
  };

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
                <h3>
                  <span className="material-symbols-rounded me-2 text-muted">
                    productivity
                  </span>
                  Users Approval
                </h3>
                {/* <div className="searchWrapper">
                  <input placeholder="Search" />
                  <span className="material-symbols-rounded">search</span>
                </div> */}
              </div>
              <hr />
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                  >
                    <Tab label="Account Approval" {...a11yProps(0)} />
                    <Tab label="Initial Payment Approval" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>
                              <div className="d-flex align-items-center">
                                Name{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Email{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Mobile{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Account Type{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Status
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!list?.length ? (
                            list?.map((data, idx) => (
                              <tr key={idx} style={{ verticalAlign: "middle" }}>
                                <td>{idx + 1}</td>
                                <td>
                                  {data.first_name} {data.last_name}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.mobile_no}</td>
                                <td className="text-capitalize">
                                  {getTransformedRoleLabel(
                                    data?.account_type,
                                    data?.account_subtype
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`status ${
                                      data.status === "Approved"
                                        ? "accepted"
                                        : "pending"
                                    }`}
                                  >
                                    {data.status === "Approved"
                                      ? "Approved"
                                      : "Pending"}
                                  </span>
                                </td>
                                <td>
                                  <CustomButton
                                    onClick={() =>
                                      handleApproveDialogOpen(data)
                                    }
                                    label={"View"}
                                    size="small"
                                    btnType="secondary"
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr style={{ verticalAlign: "middle" }}>
                              <td colSpan={7} className="text-center">
                                {usersLoading
                                  ? "Loading.."
                                  : "No Records Found!"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>
                              <div className="d-flex align-items-center">
                                Name{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Email{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Mobile{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Account Type{" "}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Account Status
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!paymentPending?.length ? (
                            paymentPending?.map((data, idx) => (
                              <tr key={idx} style={{ verticalAlign: "middle" }}>
                                <td>{idx + 1}</td>
                                <td>
                                  {data.first_name} {data.last_name}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.mobile_no}</td>
                                <td className="text-capitalize">
                                  {getTransformedRoleLabel(
                                    data?.account_type,
                                    data?.account_subtype
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`status ${
                                      data.status === "Approved"
                                        ? "accepted"
                                        : "pending"
                                    }`}
                                  >
                                    {data.status === "Approved"
                                      ? "Approved"
                                      : "Pending"}
                                  </span>
                                </td>
                                <td>
                                  {/* <CustomButton
                                    onClick={() =>
                                      handleApproveDialogOpen(data)
                                    }
                                    label={"View"}
                                    size="small"
                                    btnType="secondary"
                                  /> */}
                                  <CustomButton
                                    onClick={() => handlePaymentApproval(data)}
                                    label={"Approve Payment"}
                                    size="small"
                                    btnType="secondary"
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr style={{ verticalAlign: "middle" }}>
                              <td colSpan={7} className="text-center">
                                {pendingPayLoad
                                  ? "Loading..."
                                  : "No Records Found!"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={userInfoDialog.mount}
        handleClose={handleApproveDialogClose}
      >
        <ViewUserApproval
          data={userInfoDialog.data}
          handleClose={handleApproveDialogClose}
        />
      </CustomDialog>
    </>
  );
};

export default UsersApproval;
