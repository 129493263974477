import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import {
  useGetFollowKitReportByIdQuery,
  useViewFollowKitByIdQuery,
} from "../../services/followkit/followkitApi";
import CustomInput from "../../Components/Atoms/CustomInput";
import PhoneInput from "react-phone-input-2";

const FollowkitForm = ({ mount, id, handleClose }) => {
  const { data: fkReport } = useViewFollowKitByIdQuery(id, {
    skip: !id,
  });

  console.log("fkReport", fkReport);

  const formik = useFormik({
    initialValues: fkReport?.submitfollowupkit || {
      name: "",
      dob: "",
      gender: "",
      ethinicity: "",
      specific_sport: "",
      avr_weight: "",
      length: "",
      email: "",
      phone: "",
      test_sample: "",
      compliants: "",
      allergies: "",
      medications: "",
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      console.log("values", values);
      actions.resetForm();
    },
  });

  return (
    <CustomDialog avoidBG open={mount} maxWidth="sm" handleClose={handleClose}>
      <p className="">Follow Kit Report - Multiomics Analysis</p>
      <div className="flwKtWrpr">
        <form className="">
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label="Name"
                placeholder="Enter Name"
                type="text"
                name="name"
                value={formik.values.name}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="D.O.B"
                placeholder="Enter ethinicity"
                type="text"
                name="dob"
                value={formik.values.dob}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Gender"
                placeholder="Enter ethinicity"
                type="text"
                name="gender"
                value={formik.values.gender}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Ethinicity"
                placeholder="Enter ethinicity"
                type="text"
                name="ethinicity"
                value={formik.values.ethinicity}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Do you practice specific sport"
                placeholder="Enter practice specific sport"
                type="text"
                name="specific_sport"
                value={formik.values.specific_sport}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Average Weight"
                placeholder="Enter Average Weight"
                type="text"
                name="avr_weight"
                value={formik.values.avr_weight}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Length"
                placeholder="Enter Length"
                type="text"
                name="length"
                value={formik.values.length}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="email"
                value={formik.values.email}
                formik={formik}
                readOnly={true}
              />
            </div>

            <div className="col-md-12">
              <div className="customInputWrpr">
                <PhoneInput
                  country={"be"}
                  name="phone"
                  value={formik.values.phone}
                  onChange={(e) => {
                    formik.values.phone = e;
                  }}
                  onBlur={() => {
                    formik.setFieldTouched("phone", true);
                  }}
                  containerClass="intl_container"
                  inputClass="intl_input"
                  dropdownClass="intl_dropdown"
                  disabled={true}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p className="errMsg">{formik.errors.phone}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-12">
              <CustomInput
                label="What are your complaints"
                placeholder="Enter complaints"
                type="text"
                name="compliants"
                value={formik.values.compliants}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Do you have known allergies?"
                placeholder="Enter allergies"
                type="text"
                name="allergies"
                value={formik.values.allergies}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="What medications and supplement are you taking"
                placeholder="Enter medications and supplement"
                type="text"
                name="medications"
                value={formik.values.medications}
                formik={formik}
                readOnly={true}
              />
            </div>
            <div className="col-md-12 text-end mt-4">
              <CustomButton label="Go Back" onClick={handleClose} />
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default FollowkitForm;
