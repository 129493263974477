import React from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import Button from "@mui/material/Button";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { CloudDownloadOutlined } from "@mui/icons-material";

const PdfViewer = ({ fileUrl, reportOnly = false }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, CurrentScale } = zoomPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="pdf_viewer_wrapper">
        {fileUrl && (
          <div className="pdf_viewer_header">
            <ZoomOutButton />
            <CurrentScale />
            <ZoomInButton />

            <Download>
              {(props) => (
                <Button
                  onClick={props.onClick}
                  size="small"
                  variant="contained"
                  color="secondary"
                  className="text-light"
                >
                  <CloudDownloadOutlined className="me-2" /> Download
                </Button>
              )}
            </Download>
          </div>
        )}
        {fileUrl ? (
          <Viewer
            fileUrl={fileUrl}
            plugins={[zoomPluginInstance, getFilePluginInstance]}
            withCredentials={true}
          />
        ) : (
          <h6 className="text-center text-muted">No Document</h6>
        )}
      </div>
    </Worker>
  );
};

export default PdfViewer;
