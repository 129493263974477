import React from "react";
import { useGetEbookByIdQuery } from "../../../services/ebooksAndRecipes/ebooksAndRecipes";
import { assetUrlBuilder } from "../../../Utils/Utils";

const ViewEbook = ({ handleClose, data }) => {
  const { data: ebook } = useGetEbookByIdQuery(data?.id, {
    skip: !data?.id,
  });

  return (
    <div>
      <div className="sldrWrpr">
        {ebook?.ebook_image && (
          <>
            <div style={styles.imageWrapper}>
              <img
                style={styles.image}
                src={assetUrlBuilder(ebook?.ebook_image)}
                alt=""
              />
            </div>
            <hr />
          </>
        )}
        <div dangerouslySetInnerHTML={{ __html: ebook?.ebook_blog }} />
      </div>
    </div>
  );
};

export default ViewEbook;

const styles = {
  imageWrapper: {
    width: "100%",
    maxHeight: "400px",
    overflow: "hidden",
    position: "relative",
    background: "#f5f5f5",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
};
