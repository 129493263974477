import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function AccessRoles({ allowedRoles }) {
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  return user?.is_superuser ? (
    <Outlet />
  ) : allowedRoles.find((role) =>
      user?.department?.department_name?.includes(role)
    ) || allowedRoles.find((role) => user?.acount_type?.includes(role)) ? (
    <Outlet />
  ) : user !== null ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default AccessRoles;
