import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useCreateDepartmentsMutation,
  useGetDepartmentByIdQuery,
  useUpdateDepartmentsMutation,
} from "../../../services/settings/departmentsApi";

const validationSchema = yup.object().shape({
  department_name: yup
    .string()
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Department is required"),
});

const AddDepartment = ({ type, data, mount, handleClose }) => {
  const [createDepartment, { isLoading }] = useCreateDepartmentsMutation();
  const [updateDepartment, { isLoading: isUpdate }] =
    useUpdateDepartmentsMutation();

  const { data: selectedDepartment } = useGetDepartmentByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      department_name: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await createDepartment(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Department created successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.error(error);
          toast.error("Failed to create department!");
        }
      } else if (type === "edit") {
        try {
          const update = await updateDepartment(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Department updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update department!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue(
        "department_name",
        selectedDepartment?.department_name
      );
      formik.setFieldValue("id", selectedDepartment?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedDepartment]);

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <div className="circle_back mb-2">
          <span className="material-symbols-rounded">domain</span>
        </div>
        <h4 className="mb-2">
          {type !== "new"
            ? `Update: ${data?.department_name}`
            : "Add Department"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Department Name"
                name="department_name"
                value={formik.values.department_name}
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddDepartment;
