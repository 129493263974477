import React from "react";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  useGetDoctorsListQuery,
  useGetPatientListQuery,
  useGetProfessionalsListQuery,
  useGetSportsPersonsQuery,
  useGetTeamManagersListQuery,
  useGetUsersListQuery,
  useUserApprovalCountQuery,
} from "../../services/Users/usersApi";

import {
  useGetEbooksIndexQuery,
  useGetRecipeIndexQuery,
} from "../../services/ebooksAndRecipes/ebooksAndRecipes";
import {
  useGetOrderedProductsQuery,
  useGetProductsQuery,
  useNewOrderCountQuery,
} from "../../services/products/ProductsApi";
import SportPersons from "../SportPersons";
import { useGetEmployeesListQuery } from "../../services/settings/employeesApi";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { data: sports_persons_list } = useGetSportsPersonsQuery();
  const { data: professional_list } = useGetProfessionalsListQuery();
  const { data: manager_list } = useGetTeamManagersListQuery();
  const { data: doctor_list } = useGetDoctorsListQuery();
  const { data: book_list } = useGetEbooksIndexQuery();
  const { data: recipe_list } = useGetRecipeIndexQuery();
  const { data: product_list } = useGetProductsQuery();
  const { data: user_approvals } = useUserApprovalCountQuery();
  const { data: new_orders } = useNewOrderCountQuery();
  const { data: users_data } = useGetUsersListQuery(null, {
    refetchOnFocus: true,
  });
  const sport_persons = sports_persons_list?.filter(
    (el) => el.account_subtype === "sport_person"
  );

  const patients = sports_persons_list?.filter(
    (el) => el.account_subtype === "patient"
  );
  const { data: employees } = useGetEmployeesListQuery(undefined, {
    refetchOnFocus: true,
  });

  const product_count = product_list?.filter(
    (product) => product.status === "open"
  );

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12">
            <div
              className="dshStCard d-flex mb-2"
              style={{ minHeight: "unset" }}
            >
              {/* <div className="usrImgWrpr">
                <Avatar
                  src={assetUrlBuilder(user?.profile_image)}
                  variant="rounded"
                />
              </div> */}
              <div className="rtTxtIfo d-flex flex-column justify-content-between ms-0">
                <div className="rtTpClmn">
                  <p>
                    Welcome!{" "}
                    <Link to={"/profile"}>
                      <span className="usrNm text-capitalize">
                        <b>{user?.username} </b>
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {user?.department?.department_name !== "lab" && (
            <div className="col-md-12 mb-2">
              <div className="dshStCard statsGrid">
                <h3>Statistics</h3>
                <hr />
                <div className="adminStatsWrpr d-flex align-items-start justify-content-between">
                  <div className="staCrd">
                    <img src="/assets/svg/sport_persons.svg" />
                    <h4>
                      {!!sport_persons?.length ? sport_persons?.length : 0}
                    </h4>
                    <p>Sport Persons</p>
                  </div>
                  <div className="staCrd">
                    <span class="material-symbols-rounded">ward</span>
                    <h4>{!!patients?.length ? patients?.length : 0}</h4>
                    <p>Patients</p>
                  </div>

                  <div className="staCrd">
                    <img src="/assets/svg/professional.svg" />
                    <h4>
                      {!!professional_list?.length
                        ? professional_list?.length
                        : 0}
                    </h4>
                    <p>Professional</p>
                  </div>
                  <div className="staCrd">
                    <img src="/assets/svg/manager.svg" />
                    <h4>{!!manager_list?.length ? manager_list?.length : 0}</h4>
                    <p>Team Manager</p>
                  </div>
                  <div className="staCrd">
                    <img src="/assets/svg/doctor.svg" />
                    <h4>{!!doctor_list?.length ? doctor_list?.length : 0}</h4>
                    <p>Doctors</p>
                  </div>
                  <div className="staCrd">
                    <span class="material-symbols-rounded">badge</span>
                    <h4>{!!employees?.length ? employees?.length : 0}</h4>
                    <p>Employees</p>
                  </div>
                  <div className="staCrd">
                    <img src="/assets/svg/ebook.svg" />
                    <h4>{!!book_list?.length ? book_list?.length : 0}</h4>
                    <p>E-Books</p>
                  </div>
                  <div className="staCrd">
                    <img src="/assets/svg/ebook.svg" />
                    <h4>{!!recipe_list?.length ? recipe_list?.length : 0}</h4>
                    <p>Recipes</p>
                  </div>
                  <div className="staCrd">
                    <img src="/assets/svg/products.svg" />
                    <h4>
                      {!!product_count?.length ? product_count?.length : 0}
                    </h4>
                    <p>Products</p>
                  </div>
                  <div className="staCrd">
                    <span className="material-symbols-rounded">
                      productivity
                    </span>
                    <h4>{user_approvals?.count || 0}</h4>
                    <p>User Approvals</p>
                  </div>
                  <div className="staCrd">
                    <span className="material-symbols-rounded">
                      order_approve
                    </span>
                    <h4>{new_orders?.count || 0}</h4>
                    <p>New Orders</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-md-12 ">
            <SportPersons type="dashboard" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
