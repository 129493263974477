import { OpenInNew, Tune } from "@mui/icons-material";
import { Badge, IconButton, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import PdfViewer, {
  PdfViewerFSModal,
} from "../../../Components/Molecules/PdfViewer";
import ReportDialog from "../../../Components/Organisms/ReportDialog";
import { addSearchValue } from "../../../features/search/searchSlice";
import { baseUrl } from "../../../services/api/api";
import { useLazyGetUserUploadedReportsQuery } from "../../../services/reports/reportsApi";
import SearchReportModal, { reportSearchSchema } from "../SearchReportModal";
import { handleSort } from "../../../Utils/Utils";

const SpKpiReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Reports list with filters

  const [getReports, { data: list, isError, isLoading, isFetching }] =
    useLazyGetUserUploadedReportsQuery();

  const [filterModal, setFilterModal] = useState(false);
  const previousFilter = useSelector(
    (state) => state.search[`user_report_search_${id}`]
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
    },
    validationSchema: reportSearchSchema,
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: `user_report_search_${id}`, values }));
      try {
        const query = await getReports({ id, ...values })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getReports({ id, ...previousFilter });
      } else {
        await getReports({ id, ...searchFormik.values });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1 && count++;
    searchFormik.values.filter.date2 && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const [pdf, setPdf] = useState({ title: "", url: null });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) setPdf({ title: "", url: null });
  }, [showModal]);

  const handleShowPdf = (data, status) => {
    setShowModal(status);
    setPdf({
      title: data?.report_name,
      url: !!data?.report?.length ? `${baseUrl}${data?.report}` : null,
    });
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Report Name", key: "report_name", sortable: true },
    { name: "Date", key: "date", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div>
        <div className="tpHdrVw d-flex flex-wrap align-items-center justify-content-end mb-2 gap-2">
          <CustomSearch
            inputName="search"
            query={searchFormik.values.search}
            handleChange={searchFormik.handleChange}
            handleSubmit={(e) => {
              e.preventDefault();
              searchFormik.handleSubmit();
              searchFormik.setFieldValue("page", 1);
            }}
            containerClasses="ms-md-auto small"
            disabled={isFetching || isLoading}
          />

          <Badge badgeContent={handelBageContent} color="secondary">
            <CustomButton
              size="small"
              onClick={() => setFilterModal(true)}
              label={
                <>
                  <Tune className="me-2" />
                  Filter
                </>
              }
            />
          </Badge>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {tableHeads.map(({ sortable, key, name }, idx) => (
                  <th key={idx}>
                    <div
                      className={`d-flex align-items-center ${
                        sortable ? "sortable" : ""
                      } ${
                        searchFormik.values.sort.includes(key) ? "active" : ""
                      }`}
                      onClick={() => {
                        if (sortable && !isLoading && !isFetching) {
                          return handleSort(searchFormik, key || "");
                        }
                      }}
                    >
                      {name}
                      {sortable && (
                        <span className="material-symbols-rounded">
                          {searchFormik.values.sort.includes(key)
                            ? searchFormik.values.sort.includes(`-${key}`)
                              ? "expand_more"
                              : "expand_less"
                            : "unfold_more"}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!!list?.report?.length &&
                list.report.map((data, idx) => (
                  <tr key={data.id} style={{ verticalAlign: "middle" }}>
                    <td>{idx + 1}</td>
                    <td>{data.report_name}</td>
                    <td>{data.date}</td>
                    <td>
                      <CustomDropdown
                        menuItems={[
                          {
                            label: (
                              <>
                                <OpenInNew />
                                View
                              </>
                            ),

                            handleMenuClick: () => {
                              return handleShowPdf(data, true);
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="tblFtrActs">
          <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
            {isLoading
              ? "Fetching records.."
              : isError && !list?.report?.length
              ? "Failed to load records!"
              : !list?.report?.length && "No Records Found!"}
          </p>
          <div>
            <TablePagination
              component="div"
              count={list?.count || 0}
              page={searchFormik.values.page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={searchFormik.values.list_per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      <ReportDialog
        title={pdf.title}
        open={showModal}
        handleClose={() => setShowModal(false)}
      >
        <PdfViewer fileUrl={pdf.url} reportOnly={true} />
      </ReportDialog>
      {filterModal && (
        <SearchReportModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default SpKpiReport;
