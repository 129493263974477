import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AuthLayout.css";
import { Button } from "@mui/material";

const AuthLayout = (props) => {
  const navigate = useNavigate();

  return (
    <div className="loginWrapper">
      <div className="lftMdWrpr">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="logoWrapper">
            <Link to="/login">
              <img src="/assets/images/logo_white.png" />
            </Link>
          </div>
        </div>
      </div>
      <div className="rtFrmWrpr">{props.children}</div>
    </div>
  );
};

export default AuthLayout;
