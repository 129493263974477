import { appApi } from "../api/api";

export const motivesApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getMotivesList: builder.query({
      query: () => `/api/admin/motives`,
      providesTags: (result, error, arg) =>
        result
          ? ["Motives", ...result.map(({ id }) => ({ type: "Motives", id }))]
          : ["Motives"],
    }),

    getMotivesBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/motives/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Motives",
              ...result?.report?.map(({ id }) => ({ type: "Motives", id })),
            ]
          : ["Motives"],
    }),
    getMotiveById: builder.query({
      query: (id) => `/api/admin/motives/${id}`,
      providesTags: (result, error, arg) => [{ type: "Motives", id: arg }],
    }),
    createMotives: builder.mutation({
      query: (values) => ({
        url: `/api/admin/motives/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Motives" }],
    }),
    updateMotives: builder.mutation({
      query: (values) => ({
        url: `/api/admin/motives/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Motives", id: arg.id },
      ],
    }),
    deleteMotives: builder.mutation({
      query: (id) => ({
        url: `/api/admin/motives/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Motives" }],
    }),
  }),
});

export const {
  useGetMotivesListQuery,
  useDeleteMotivesMutation,
  useUpdateMotivesMutation,
  useCreateMotivesMutation,
  useGetMotiveByIdQuery,
  useLazyGetMotivesBySearchQuery,
} = motivesApi;
