import React, { useState, useEffect } from "react";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import PhoneInput from "react-phone-input-2";
import { sportlist } from "../../../Utils/Data";

const ProfessionalForm = ({
  formik,
  city_list,
  state_list,
  country_list,
  includesRefferal,
  county,
  state,
  city,
  setCountry,
  setState,
  setCity,
  readOnly,
  avoidPassword,
  sport_categeory,
}) => {
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  useEffect(() => {
    if (formik.values.state) {
      let index = state_list.findIndex(
        (ele) => ele.isoCode === formik.values.state
      );
      if (index < 0) {
        setState("");
        formik.values.state = "";
      }
    }
  }, [formik.values.state, state_list]);
  console.log(formik.values);
  return (
    <>
      <div className="col-md-6">
        <CustomInput
          label="First Name*"
          placeholder="Enter first name"
          type="text"
          name="first_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.first_name}
          formik={formik}
          readOnly={readOnly}
        />
      </div>
      <div className="col-md-6">
        <CustomInput
          label="Last Name*"
          placeholder="Enter last name"
          type="text"
          name="last_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.last_name}
          formik={formik}
          readOnly={readOnly}
        />
      </div>
      <div className="col-md-6">
        <CustomInput
          label="Email*"
          placeholder="Enter email"
          type="text"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          formik={formik}
          autoComplete="username"
          // readOnly={formik.initialValues.email == "" ? false : true}
          // disabled={
          //   window.location.pathname.includes("/profile") ? true : false
          // }
        />
      </div>
      <div className="col-md-6">
        <div className="customInputWrpr">
          <PhoneInput
            country={"be"}
            name="mobile_no"
            value={formik.values.mobile_no}
            onChange={(e) => {
              formik.setFieldValue("mobile_no", e);
            }}
            onBlur={() => {
              formik.setFieldTouched("mobile_no", true);
            }}
            containerClass="intl_container"
            inputClass="intl_input"
            dropdownClass="intl_dropdown"
            // disabled={
            //   window.location.pathname.includes("/profile") ? true : false
            // }
          />
          {formik.touched.mobile_no && formik.errors.mobile_no ? (
            <p className="errMsg">{formik.errors.mobile_no}</p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-md-6">
        <CustomInput
          label="Date Of Birth*"
          placeholder="Enter d.o.b"
          type="date"
          name="dob"
          shrink={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dob}
          formik={formik}
          readOnly={readOnly}
        />
      </div>
      <div className="col-md-6">
        <CustomSelect
          label="Gender*"
          placeholder="Enter email"
          name="gender"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.gender}
          formik={formik}
          readOnly={readOnly}
          options={[
            {
              value: "male",
              name: "Male",
            },
            {
              value: "female",
              name: "Female",
            },
            {
              value: "others",
              name: "Others",
            },
          ]}
        />
      </div>
      <div className="col-md-6">
        <div className="customInputWrpr">
          <CustomSelect
            label="Enter sport category"
            name="sport_categeory"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sport_categeory}
            formik={formik}
            options={
              sport_categeory?.map((el) => {
                return {
                  value: el.id,
                  name: el.sport_name,
                };
              }) ?? []
            }
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="col-md-6" />
      <div className="col-md-12">
        <CustomInput
          label="Address"
          placeholder="Enter Address"
          name="address_1"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address_1}
          formik={formik}
          readOnly={readOnly}
        />
      </div>

      <div className="col-md-6">
        <div className="customInputWrpr">
          <Autocomplete
            disablePortal
            options={country_list}
            getOptionLabel={(option) => option.name || ""}
            value={county}
            onChange={(_, newValue) => {
              setCountry(newValue);
              formik.setFieldValue("country", newValue?.isoCode ?? "");
            }}
            isOptionEqualToValue={(option, value) =>
              option?.isoCode === value.isoCode || ""
            }
            renderInput={(params) => <TextField {...params} label="Country" />}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="customInputWrpr">
          <Autocomplete
            disablePortal
            options={state_list}
            getOptionLabel={(option) => option.name || ""}
            value={state}
            onChange={(_, newValue) => {
              setState(newValue);
              formik.setFieldValue("state", newValue?.isoCode ?? "");
            }}
            isOptionEqualToValue={(option, value) =>
              option?.isoCode === value.isoCode || ""
            }
            renderInput={(params) => <TextField {...params} label="State" />}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="col-md-6">
        <CustomInput
          label="City"
          placeholder="Enter city"
          name="city"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
          formik={formik}
          readOnly={readOnly}
        />
        {/* <div className="customInputWrpr">
          <Autocomplete
            disablePortal
            options={city_list}
            getOptionLabel={(option) => option.name || ""}
            value={city}
            onChange={(_, newValue) => {
              setCity(newValue);
              formik.setFieldValue("city", newValue?.name ?? "");
            }}
            isOptionEqualToValue={(option, value) =>
              option?.name === value.name || ""
            }
            renderInput={(params) => <TextField {...params} label="City" />}
            readOnly={readOnly}
          />
        </div> */}
      </div>
      <div className={"col-md-6"}>
        <CustomInput
          label="Zipcode"
          placeholder="Enter zipcode"
          name="zipcode"
          // type="number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.zipcode}
          formik={formik}
          readOnly={readOnly}
        />
      </div>
      {window.location.pathname.includes("/profile") && (
        <div className="col-md-12">
          <h6 className="mb-4 mt-5 d-flex align-items-center">
            <span className="material-symbols-rounded me-2">lock_reset</span>
            Change Password
          </h6>
        </div>
      )}
      {!avoidPassword && (
        <>
          <div className="col-md-6">
            <CustomInput
              label="Password*"
              placeholder="Enter password"
              name="password"
              type={passVisible ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              formik={formik}
              autoComplete="new-password"
              endAdornment={
                <IconButton
                  className="material-symbols-rounded"
                  onClick={() => setPassVisible(!passVisible)}
                >
                  {passVisible ? "visibility_off" : "visibility"}
                </IconButton>
              }
              readOnly={readOnly}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              label="Confirm Password*"
              placeholder="Re-enter your password"
              name="conf_password"
              type={confirmPass ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.conf_password}
              formik={formik}
              endAdornment={
                <IconButton
                  className="material-symbols-rounded"
                  onClick={() => setConfirmPass(!confirmPass)}
                >
                  {confirmPass ? "visibility_off" : "visibility"}
                </IconButton>
              }
              autoComplete="new-password"
              readOnly={readOnly}
            />
          </div>
        </>
      )}
      {includesRefferal && (
        <div className="col-md-12">
          <CustomInput
            label="Referral code"
            placeholder="Enter Referral code"
            name="referer_code"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referer_code}
            formik={formik}
            readOnly={readOnly}
            helperText={
              "The Referal code should have been sent to your email, If your are invited by another user"
            }
          />
        </div>
      )}
      <div className="col-md-12">
        <CustomInput
          label="BTW/VAT Number"
          placeholder="Enter BTW/VAT Number"
          name="btw_no"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.btw_no}
          formik={formik}
          readOnly={readOnly}
        />
      </div>
    </>
  );
};

export default ProfessionalForm;
