import { appApi } from "../api/api";

export const ebooksAndRecipesApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getEbooksReports: builder.query({
      query: (values) => ({
        url: `/api/ebooks_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Ebooks",
              ...result?.report?.map(({ id }) => ({ type: "Ebooks", id })),
            ]
          : ["Ebooks"],
    }),

    getEbookById: builder.query({
      query: (id) => `/api/admin/ebooks/${id}`,
      providesTags: (result, error, arg) => [{ type: "Ebooks", id: arg }],
    }),

    uploadEbooksReport: builder.mutation({
      query: (values) => ({
        url: `api/admin/ebooks/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Ebooks" }],
    }),

    updateEbooksReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/ebooks/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Ebooks", id: arg.get("id") },
      ],
    }),

    deleteEbooksReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/ebooks/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Ebooks" }],
    }),
    // Ebooks Report Ends

    getRecipesReports: builder.query({
      query: (values) => ({
        url: `/api/receipe_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Recipes",
              ...result?.report?.map(({ id }) => ({ type: "Recipes", id })),
            ]
          : ["Recipes"],
    }),

    getRecipeById: builder.query({
      query: (id) => `/api/admin/recipes/${id}`,
      providesTags: (result, error, arg) => [{ type: "Recipes", id: arg }],
    }),

    uploadRecipesReport: builder.mutation({
      query: (values) => ({
        url: `api/admin/recipes/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Recipes" }],
    }),

    updateRecipesReport: builder.mutation({
      query: (values) => ({
        url: `/api/admin/recipes/${values.get("id")}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Recipes", id: arg.get("id") },
      ],
    }),

    deleteRecipesReport: builder.mutation({
      query: (id) => ({
        url: `/api/admin/recipes/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Recipes" }],
    }),
    // Recipes Report Ends

    //E-Book Category
    getEbookCategory: builder.query({
      query: () => `api/admin/ebook_categories`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "E-Book-Category",
              ...result.map(({ id }) => ({ type: "E-Book-Category", id })),
            ]
          : ["E-Book-Category"],
    }),

    getEbookCategoryBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/ebook_categories/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "E-Book-Category",
              ...result?.report?.map(({ id }) => ({
                type: "E-Book-Category",
                id,
              })),
            ]
          : ["E-Book-Category"],
    }),

    getEbookCategoryById: builder.query({
      query: (id) => `/api/admin/ebook_categories/${id}`,
      providesTags: (result, error, arg) => [
        { type: "E-Book-Category", id: arg },
      ],
    }),

    uploadEbookCategory: builder.mutation({
      query: (values) => ({
        url: `api/admin/ebook_categories/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "E-Book-Category" }],
    }),

    updateEbookCategory: builder.mutation({
      query: (values) => ({
        url: `/api/admin/ebook_categories/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "E-Book-Category", id: arg.id },
      ],
    }),

    deleteEbookCategory: builder.mutation({
      query: (id) => ({
        url: `/api/admin/ebook_categories/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "E-Book-Category" }],
    }),

    //Recipe Category
    getRecipeCategory: builder.query({
      query: () => `api/admin/recipe_categories`,

      providesTags: (result, error, arg) =>
        result
          ? [
              "Recipe-Category",
              ...result.map(({ id }) => ({ type: "Recipe-Category", id })),
            ]
          : ["Recipe-Category"],
    }),

    getRecipeCategoryBySearch: builder.query({
      query: (values) => ({
        url: `/api/admin/recipe_categories/search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Recipe-Category",
              ...result?.report?.map(({ id }) => ({
                type: "Recipe-Category",
                id,
              })),
            ]
          : ["Recipe-Category"],
    }),

    getRecipeCategoryById: builder.query({
      query: (id) => `/api/admin/recipe_categories/${id}`,
      providesTags: (result, error, arg) => [
        { type: "Recipe-Category", id: arg },
      ],
    }),

    uploadRecipeCategory: builder.mutation({
      query: (values) => ({
        url: `api/admin/recipe_categories/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Recipe-Category" }],
    }),

    updateRecipeCategory: builder.mutation({
      query: (values) => ({
        url: `/api/admin/recipe_categories/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Recipe-Category", id: arg.id },
      ],
    }),

    deleteRecipeCategory: builder.mutation({
      query: (id) => ({
        url: `/api/admin/recipe_categories/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Recipe-Category" }],
    }),

    getEbooksIndex: builder.query({
      query: () => `/api/admin/ebooks`,
      providesTags: (result, error, arg) =>
        result
          ? ["Ebooks", ...result?.map(({ id }) => ({ type: "Ebooks", id }))]
          : ["Ebooks"],
    }),
    getRecipeIndex: builder.query({
      query: () => `/api/admin/recipes`,
      providesTags: (result, error, arg) =>
        result
          ? ["Recipes", ...result?.map(({ id }) => ({ type: "Recipes", id }))]
          : ["Recipes"],
    }),
  }),
});
export const {
  useDeleteEbooksReportMutation,
  useGetEbooksReportsQuery,
  useLazyGetEbooksReportsQuery,
  useGetEbookByIdQuery,
  useUpdateEbooksReportMutation,
  useUploadEbooksReportMutation,

  useDeleteRecipesReportMutation,
  useUpdateRecipesReportMutation,
  useUploadRecipesReportMutation,
  useGetRecipesReportsQuery,
  useGetRecipeByIdQuery,
  useLazyGetRecipesReportsQuery,

  useGetEbookCategoryQuery,
  useGetEbookCategoryByIdQuery,
  useUpdateEbookCategoryMutation,
  useUploadEbookCategoryMutation,
  useDeleteEbookCategoryMutation,

  useGetRecipeCategoryQuery,
  useGetRecipeCategoryByIdQuery,
  useUploadRecipeCategoryMutation,
  useUpdateRecipeCategoryMutation,
  useDeleteRecipeCategoryMutation,

  useGetEbooksIndexQuery,
  useGetRecipeIndexQuery,

  useLazyGetEbookCategoryBySearchQuery,
  useLazyGetRecipeCategoryBySearchQuery,
} = ebooksAndRecipesApi;
