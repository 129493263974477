import {
  MoveUp,
  OpenInNew,
  PersonAddOutlined,
  PersonOffOutlined,
  PersonRemoveOutlined,
  Tune,
} from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import ConfirmDialogPopper, {
  confirmDialog,
} from "../../../Components/Molecules/ConfirmDialog";
import CustomDropdown from "../../../Components/Molecules/CustomDropdown";
import ExportComponent from "../../../Components/Molecules/ExportComponent";
import { addSearchValue } from "../../../features/search/searchSlice";
import { useGetSportCategoryListQuery } from "../../../services/settings/sportCategoryApi";
import {
  useDeactivateUserMutation,
  useGetProfessionalsListQuery,
  useHandleUserStatusMutation,
  useLazyGetProfessionalsListSearchQuery,
} from "../../../services/Users/usersApi";
import BulkReassignUsers from "../BulkReassignUsers";
import ProfessionalSearchModal from "./ProfessionalSearchModal";
import { handleSort } from "../../../Utils/Utils";

const Professionals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Reports list with filters

  const [getUsers, { data: list, isError, isLoading, isFetching }] =
    useLazyGetProfessionalsListSearchQuery();

  const { data: sports_List } = useGetSportCategoryListQuery();

  const [filterModal, setFilterModal] = useState(false);

  const { professionals_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        state: "",
        country: "",
        zipcode: "",
        gender: "",
        city: "",
      },
      list_per_page: 10,
      page: 1,
      sort: "",
    },
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "professionals_search", values }));
      try {
        const query = await getUsers(values)
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getUsers(previousFilter);
      } else {
        await getUsers(searchFormik.values);
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.country && count++;
    searchFormik.values.filter.state && count++;
    searchFormik.values.filter.city && count++;
    searchFormik.values.filter.zipcode && count++;
    searchFormik.values.filter.gender && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    return count;
  }, [searchFormik.values]);

  // user's status handlers

  const [handleStatus, { isLoading: statusUpdating }] =
    useHandleUserStatusMutation();

  const [deactivateUser, { isLoading: deactivatingUser }] =
    useDeactivateUserMutation();

  const handleUserStatus = ({ id, first_name, last_name, is_active }) => {
    return confirmDialog(
      `Do you really want to ${
        is_active ? "suspend" : "activate"
      } ${first_name} ${last_name}'s  account?`,
      async () => {
        const notify = toast.loading(`updating client ID ${id} account status`);
        try {
          const res = await handleStatus(id)
            .unwrap()
            .then((res) => {
              return toast.dismiss(res?.message, { id: notify });
            });

          return res;
        } catch (error) {
          console.log(error);
          if (error?.data?.detail) {
            toast.error(error?.data?.detail, { id: notify });
          } else {
            toast.error("Failed to update user's status!", { id: notify });
          }
        }
      }
    );
  };

  const handleDeactivateUser = async ({ id, first_name, last_name }) => {
    return confirmDialog(
      `Do you really want to deactivate ${first_name} ${last_name}'s  account permanantly?`,
      async () => {
        const notify = toast.loading(`Deactiving client ID ${id} status`);
        try {
          const res = await deactivateUser(id)
            .unwrap()
            .then((res) => {
              return toast.success(res?.message, { id: notify });
            });

          return res;
        } catch (error) {
          console.log(error);
          if (error?.data?.detail) {
            toast.error(error?.data?.detail, { id: notify });
          } else {
            toast.error("Failed to deactiving the user!", { id: notify });
          }
        }
      }
    );
  };

  //reassign users

  const [reassignModal, setReassignModal] = useState({
    open: false,
    user: null,
  });

  const reassignModalHandler = (data) => {
    if (data) {
      return setReassignModal({
        open: true,
        user: data,
      });
    } else {
      setReassignModal({
        open: false,
        user: null,
      });
    }
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "User/Client ID", key: "ref_no", sortable: true },
    { name: "First Name", key: "first_name", sortable: true },
    { name: "Last Name", key: "last_name", sortable: true },
    { name: "Email", key: "email", sortable: true },
    { name: "Sport", key: "sport_categeory", sortable: true },
    { name: "Status", sortable: false },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                <h3>
                  <span className="">
                    <img
                      src="/assets/svg/professional-gray.svg"
                      className="me-2"
                    />
                  </span>
                  Professionals
                </h3>
                <CustomSearch
                  inputName="search"
                  query={searchFormik.values.search}
                  handleChange={searchFormik.handleChange}
                  handleSubmit={(e) => {
                    e.preventDefault();
                    searchFormik.handleSubmit();
                    searchFormik.setFieldValue("page", 1);
                  }}
                  containerClasses="ms-md-auto small"
                  disabled={isFetching || isLoading}
                />

                <Badge badgeContent={handelBageContent} color="secondary">
                  <CustomButton
                    size="small"
                    onClick={() => setFilterModal(true)}
                    label={
                      <>
                        <Tune className="me-2" />
                        Filter
                      </>
                    }
                  />
                </Badge>

                <ExportComponent type="professional" size="small" />
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(({ sortable, key, name }, idx) => (
                        <th key={idx}>
                          <div
                            className={`d-flex align-items-center ${
                              sortable ? "sortable" : ""
                            } ${
                              searchFormik.values.sort.includes(key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (sortable && !isLoading && !isFetching) {
                                return handleSort(searchFormik, key || "");
                              }
                            }}
                          >
                            {name}
                            {sortable && (
                              <span className="material-symbols-rounded">
                                {searchFormik.values.sort.includes(key)
                                  ? searchFormik.values.sort.includes(`-${key}`)
                                    ? "expand_more"
                                    : "expand_less"
                                  : "unfold_more"}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.report?.length &&
                      list.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>
                            <Link to={`/users/professionals/view/${data.id}`}>
                              {data.ref_no}
                            </Link>
                          </td>
                          <td>{data.first_name}</td>
                          <td>{data.last_name}</td>
                          <td>{data.email}</td>
                          <td>
                            {
                              sports_List?.find(
                                (el) => el.id === data.sport_categeory && el
                              )?.sport_name
                            }
                          </td>
                          <td>
                            <span
                              className={`status ${
                                data.deactivate
                                  ? "error"
                                  : data.is_active
                                  ? "accepted"
                                  : "pending"
                              }`}
                            >
                              {data.deactivate
                                ? "Deactivated"
                                : data.is_active
                                ? "Active"
                                : "Suspended"}
                            </span>
                          </td>
                          <td>
                            <CustomDropdown
                              menuItems={[
                                {
                                  label: (
                                    <>
                                      <OpenInNew />
                                      View
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return navigate(
                                      `/users/professionals/view/${data.id}`
                                    );
                                  },
                                },
                                {
                                  label: data.is_active ? (
                                    <>
                                      <PersonRemoveOutlined />
                                      Suspend
                                    </>
                                  ) : (
                                    <>
                                      <PersonAddOutlined />
                                      Activate
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    handleUserStatus(data);
                                  },
                                  disabled: statusUpdating,
                                  hidden: data.deactivate,
                                },
                                {
                                  label: (
                                    <>
                                      <PersonOffOutlined />
                                      Deactivate
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    handleDeactivateUser(data);
                                  },
                                  disabled: deactivatingUser,
                                  hidden: data.deactivate,
                                },
                                {
                                  label: (
                                    <>
                                      <MoveUp />
                                      Reassign Users
                                    </>
                                  ),
                                  handleMenuClick: () => {
                                    return reassignModalHandler(data);
                                  },
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="tblFtrActs">
                <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                  {isLoading
                    ? "Fetching records.."
                    : isError && !list?.report?.length
                    ? "Failed to load records!"
                    : !list?.report?.length && "No Records Found!"}
                </p>
                <div>
                  <TablePagination
                    component="div"
                    count={list?.count || 0}
                    page={searchFormik.values.page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={searchFormik.values.list_per_page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialogPopper />

      {filterModal && (
        <ProfessionalSearchModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
      {reassignModal && (
        <BulkReassignUsers
          {...reassignModal}
          handleClose={() => reassignModalHandler()}
        />
      )}
    </>
  );
};

export default Professionals;
