import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useCreateSportCategoryMutation,
  useGetSportCategoryByIdQuery,
  useUpdateSportCategoryMutation,
} from "../../../services/settings/sportCategoryApi";

const validationSchema = yup.object().shape({
  sport_name: yup
    .string()
    .min(3, "Minimum 3 characters required")
    .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
    .required("Category is required"),
});

const AddSportCategory = ({ type, data, mount, handleClose }) => {
  const [createCategory, { isLoading }] = useCreateSportCategoryMutation();
  const [updateCategory, { isLoading: isUpdate }] =
    useUpdateSportCategoryMutation();

  const { data: selectedCategory } = useGetSportCategoryByIdQuery(data?.id, {
    skip: !data?.id,
  });

  const formik = useFormik({
    initialValues: {
      sport_name: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (type === "new") {
        try {
          const create = await createCategory(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Sport  Category added successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return create;
        } catch (error) {
          console.log(error);
          if (error?.data?.sport_name) {
            toast.error(error?.data?.sport_name);
          } else {
            toast.error("Failed to add Sport Category!");
          }
        }
      } else if (type === "edit") {
        try {
          const update = await updateCategory(values)
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Sport Category updated successfully");
                handleClose();
                actions.resetForm();
              }
            });

          return update;
        } catch (error) {
          console.error(error);
          toast.error("Failed to update Sport Category!");
        }
      }
    },
  });

  useEffect(() => {
    if (type === "edit") {
      formik.setFieldValue("sport_name", selectedCategory?.sport_name);
      formik.setFieldValue("id", selectedCategory?.id);
    } else if (type === "new") {
      formik.resetForm();
    }
  }, [type, selectedCategory]);

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <img src="/assets/svg/sport_persons_circle.svg" className="mb-3" />
        <h4 className="mb-2">
          {type !== "new" ? `Update: ${data?.sport_name}` : "Add New Category"}
        </h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Sport Category"
                name="sport_name"
                value={formik.values.sport_name}
                onChange={formik.handleChange}
                formik={formik}
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading || isUpdate}
                  type="submit"
                  label={isLoading || isUpdate ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default AddSportCategory;
