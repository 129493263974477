import React, { useMemo, useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { Button, Pagination } from "@mui/material";
import { useGetInvitationsQuery } from "../../services/InvitePeople/InvitePeople";
import InviteForm from "./InviteForm";
import { useSelector } from "react-redux";

const Invite = () => {
  const navigate = useNavigate();
  const [inviteDialog, setInviteDialog] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const {
    data: invitations,
    isFetching,
    isLoading,
  } = useGetInvitationsQuery(user?.id, { refetchOnFocus: true });

  //pagination
  const [page, setPage] = React.useState(0);
  const [listPerPage, setListPerPage] = useState(10);

  const count = !!invitations?.length
    ? Math.ceil(invitations?.length / listPerPage)
    : 0;

  const handleChange = (_, value) => {
    setPage(value);
  };

  const getCurrentList = useMemo(() => {
    let lastIdxItem = page * listPerPage;
    let firstIdxItem = lastIdxItem - listPerPage;

    !!invitations?.length && page === 0 && setPage(1);

    return (
      !!invitations?.length && invitations?.slice(firstIdxItem, lastIdxItem)
    );
  }, [page, invitations]);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                <img src="/assets/svg/invitegray.svg" className="me-2" />
                Invite People
              </h5>
              <Button className="brdrBtn" onClick={() => setInviteDialog(true)}>
                <span className="material-symbols-rounded">group_add</span>
                Invite People
              </Button>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>User Role</th>
                    <th>Invite Sent on</th>
                    <th>Joined on</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentList?.length > 0 ? (
                    getCurrentList?.map((data, index) => (
                      <tr key={data?.id || index}>
                        <td>{data?.id || "-"}</td>
                        <td>{data?.name || "-"}</td>
                        <td>{data?.person_type || "-"}</td>
                        <td>{data?.invite_sent_date || "-"}</td>
                        <td>{data?.invite_accepted_date || "-"}</td>
                        <td>
                          <span
                            className={`status ${
                              data?.status ? "accepted" : "pending"
                            }`}
                          >
                            {data?.status ? "Accepted" : "Pending"}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {isLoading || isFetching
                          ? "Fetching Details..."
                          : "No Invitations Found."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <Pagination
                size="medium"
                variant="outlined"
                shape="rounded"
                color="primary"
                count={count}
                page={page}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <InviteForm
        inviteDialog={inviteDialog}
        setInviteDialog={setInviteDialog}
      />
    </>
  );
};

export default Invite;
