import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ViewInnerHackReport = ({ handleClose, report }) => {
  return (
    <div>
      <div className="sldrWrpr">
        <Swiper
          className="mySwiper"
          spaceBetween={10}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {report !== null ? (
            <>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/food.svg" />
                    <h4 className="w-100">Food</h4>
                  </div>

                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{ __html: report?.food_avoid }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.food_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{ __html: report?.food_tip }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/supplement.svg" />
                    <h4 className="w-100">Supplement</h4>
                  </div>

                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.supplement_avoid,
                        }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.supplement_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.supplement_tip,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/exercise.svg" />
                    <h4 className="w-100">Exercise</h4>
                  </div>

                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.excercise_avoid,
                        }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.excercise_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.excercise_tip,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/cognitive.svg" />
                    <h4 className="w-100">Cognitive</h4>
                  </div>

                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.cognitive_avoid,
                        }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.cognitive_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.cognitive_tip,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/wellbeing.svg" />
                    <h4 className="w-100">Wellbeing</h4>
                  </div>
                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.wellbeing_avoid,
                        }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.wellbeing_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.wellbeing_tip,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="innerHackCard">
                  <div className="tpHckHd text-center">
                    <img src="/assets/svg/superorganism.svg" />
                    <h4 className="w-100">Superorganism</h4>
                  </div>

                  <div className="btmIfnoCntnr">
                    <h5>AVOID</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.superorganism_avoid,
                        }}
                      />
                    </ul>
                    <h5>EMBRACE</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.superorganism_embrace,
                        }}
                      />
                    </ul>
                    <h5>Tips</h5>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: report?.superorganism_tip,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            </>
          ) : (
            <h6 className="text-center text-muted">Innerhacks Not Found!</h6>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default ViewInnerHackReport;
